import React from 'react'
import { FormSection, reduxForm } from 'redux-form'

import { Button, FooterActions, Form } from 'components'

import i18n from 'translations'

import NurseryForm from 'module/Nurseries/components/NurseryForm'

import { NURSERIES_ADD_FORM } from '../../constants'

const NurseryStage = ({
  handleSubmit,
  initialValues,
  isAdministrationContext,
  isLoading,
  isOrganizationContext,
  onNameChange,
  prefix,
  suffix,
}) => {
  const renderForm = () => (
    <FormSection name="nursery">
      <NurseryForm
        initialValues={initialValues}
        isAdministrationContext={isAdministrationContext}
        isOrganizationContext={isOrganizationContext}
        prefix={prefix}
        suffix={suffix}
        onNameChange={onNameChange}
      />
    </FormSection>
  )

  const renderFooter = () => (
    <FooterActions>
      <FooterActions.Flex />
      <FooterActions.Item>
        <Button
          isLoading={isLoading}
          label={i18n.t('global:Next')}
          negativeMargins
          submit
        />
      </FooterActions.Item>
    </FooterActions>
  )

  return (
    <Form onSubmit={handleSubmit}>
      {renderForm()}
      {renderFooter()}
    </Form>
  )
}

export default reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
  form: NURSERIES_ADD_FORM,
})(NurseryStage)
