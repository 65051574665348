import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { FEATURE_FLAGS } from 'constants/security'

import { withHomeObservationsService } from 'services/legacy/homeObservations'
import { withRouter } from 'services/router'

import HomeObservationsDetailsView from './HomeObservationsDetailsView'

const GROUPS = {
  read: [
    'child',
    'carer',
    'medium',
    'homeObservation.media',
    'homeObservation.carer',
    'homeObservation.child',
    'homeObservation.observations',
  ],
}

class HomeObservationsDetailsContainer extends Component {
  componentDidMount() {
    const { homeObservationsActions, params: { id } } = this.props

    homeObservationsActions.getHomeObservation({
      params: [id, {
        groups: GROUPS,
      }],
    })
  }

  componentWillUnmount() {
    const { homeObservationsActions } = this.props

    homeObservationsActions.clearHomeObservation()
  }

  render() {
    const { homeObservation, location } = this.props

    return (
      <HomeObservationsDetailsView
        homeObservation={homeObservation}
        location={location}
      />
    )
  }
}

HomeObservationsDetailsContainer.authParams = {
  flags: [FEATURE_FLAGS.HAS_PARENT_APP_ACCESS],
}

const mapState = (state, { homeObservationsSelectors }) => ({
  homeObservation: homeObservationsSelectors.getHomeObservationsSingleDataState(state),
})

const enhance = compose(
  withHomeObservationsService,
  withRouter,
  connect(mapState),
)

export default enhance(HomeObservationsDetailsContainer)
