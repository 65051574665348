import _ from 'lodash'

import moment from 'moment'

import React, { useMemo, useState } from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import {
  Allocation,
  AllocationDefault,
  ChildProductFunding,
  ChildProductRegularSession,
  ExcludedFundingReasons,
} from 'services/booking/childBooking/constants'
import { DAYS_OF_WEEK_LIST, DaysOfWeek } from 'constants/date'
import { NurseryFundingProductType } from 'services/product/nurseryFundingV3/constants'
import { FundingSummary as FundingSummaryModel } from 'services/child/fundingSummaryCalculator/constants'
import { convertDecimalToInteger } from 'utils/data'

import { isRequired } from 'utils/fieldValidation'
import { formatTime, isSameDay, removeTimes } from 'utils/date'

import {
  Banner,
  Box,
  Button,
  Callout,
  DropdownMenu,
  FooterActions,
  Form,
  Grid,
  ModalBox,
  Space,
  Typography,
} from 'components'
import AllocationBar from 'module/Children/Child/ChildBookingPattern/RegularBookings/components/AllocationBar'
import FundingSummary from 'module/Children/Child/ChildBookingPattern/RegularBookings/components/FundingSummary'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

import i18n from 'translations'

import { StyledDatePickerWrapper, StyledFormWrapper } from './AddFundingToRegularBookingsModalFormStyled'
import { OpenedAllocation } from '../AddFundingToRegularBookingsModalContainer'

export const ADD_FUNDING_TO_REGULAR_BOOKINGS_MODAL_FORM = 'AddFundingToRegularBookingsModalForm'

export interface AddFundingToRegularBookingsModalFormValues {
  product?: any
  settings?: {
    customLocalAuthority: boolean
    hourlyRate?: number
    hoursPerDay?: number
    hoursPerWeek?: number
  }
  week?: moment.Moment[]
}

export interface AddFundingToRegularBookingsModalFormProps {
  allocateHoursWithinSessionTimesError?: boolean
  alternate: number
  defaultFundingAllocations: AllocationDefault[]
  endDate: moment.Moment
  errorMessages?: string[]
  excludedFundingReasons: ExcludedFundingReasons[]
  formValues?: AddFundingToRegularBookingsModalFormValues
  fundingAllocations: Allocation[]
  isFetching?: boolean
  isFetchingSummaryFunding?: boolean
  onApplyToCheapestHours?: () => void
  onChangeHourlyRateType?: (value: boolean) => void
  onChangeNurseryFunding?: (value: any) => void
  onChangeShowRecalculateButton?: (value: boolean) => void
  onCloseAllocationPopup?: () => void
  onCloseClick?: () => void
  onCreateAllocation?: (date: moment.Moment) => (session: ChildProductRegularSession, timeRange: any) => void
  onCreateDefaultAllocation?: (daysOfWeek: DaysOfWeek) => (session: ChildProductRegularSession, timeRange: any) => void
  onEditAllocation?: (defaultAllocation: boolean) => (
    id: string,
    fieldName: string,
    fieldValue: any,
    timeIndex: number
  ) => void
  onOpenAllocation?: (openedAllocation: OpenedAllocation) => void
  onRemoveAllFundedHours?: (isDefault: boolean) => void
  onRemoveAllocation?: (isDefault: boolean) => (id: string) => void
  onSubmit: (values: any) => void
  openedAllocation: OpenedAllocation | null
  restFundings?: ChildProductFunding[]
  sessionAllocations: AllocationDefault[]
  showRecalculateButton?: boolean
  startDate: moment.Moment
  summaryFunding: FundingSummaryModel
  totalAlternates: number
  usedNurseryFundings: number[]
}

type AddFundingToRegularBookingsModalFormFullProps = InjectedFormProps<
    {},
    AddFundingToRegularBookingsModalFormProps
  > & AddFundingToRegularBookingsModalFormProps

const AddFundingToRegularBookingsModalForm: React.FC<AddFundingToRegularBookingsModalFormFullProps> = ({
  allocateHoursWithinSessionTimesError,
  alternate,
  defaultFundingAllocations,
  endDate,
  errorMessages,
  excludedFundingReasons,
  formValues,
  fundingAllocations,
  handleSubmit,
  isFetching,
  isFetchingSummaryFunding,
  onApplyToCheapestHours,
  onChangeHourlyRateType,
  onChangeNurseryFunding,
  onChangeShowRecalculateButton,
  onCloseAllocationPopup,
  onCloseClick,
  onCreateAllocation,
  onCreateDefaultAllocation,
  onEditAllocation,
  onOpenAllocation,
  onRemoveAllFundedHours,
  onRemoveAllocation,
  onSubmit,
  openedAllocation,
  restFundings,
  sessionAllocations,
  showRecalculateButton,
  startDate,
  summaryFunding,
  totalAlternates,
  usedNurseryFundings,
}) => {
  const [isOpenActualAllocationPerWeek, setIsOpenActualAllocationPerWeek] = useState<boolean>(false)

  const maxHoursPerWeek = +(formValues?.settings?.hoursPerWeek || 0)
  const minStartDateOfAllocations = startDate
  const maxStartDateOfAllocations = endDate

  const checkIfWeekOfDayIsDisabled = useMemo(() => (iterationDate) => {
    const finalStartDate = moment(minStartDateOfAllocations).isBefore(startDate, 'days')
      ? moment(minStartDateOfAllocations)
      : startDate
    const finalEndDate = moment(maxStartDateOfAllocations).isAfter(endDate, 'days')
      ? moment(maxStartDateOfAllocations)
      : endDate

    if (moment(iterationDate).isBefore(moment(finalStartDate).startOf('week'))) {
      return true
    }

    if (finalEndDate && moment(iterationDate).isAfter(moment(finalEndDate).endOf('week'))) {
      return true
    }

    const firstDayInWeekForStartDate = removeTimes(moment(finalStartDate).startOf('week'))
    const firstDayInWeekForIterationDate = removeTimes(moment(iterationDate).startOf('week'))

    if (firstDayInWeekForIterationDate.format('x') === firstDayInWeekForStartDate.format('x')) {
      return 1 !== alternate
    }

    if (firstDayInWeekForIterationDate.format('x') > firstDayInWeekForStartDate.format('x')) {
      const durationInWeeks = Math.ceil(
        moment.duration(firstDayInWeekForIterationDate.diff(firstDayInWeekForStartDate)).asWeeks(),
      )

      const iterationDateAlternate = durationInWeeks % totalAlternates

      if (0 === iterationDateAlternate && alternate - 1 === totalAlternates) {
        return false
      }

      if (iterationDateAlternate === alternate - 1) {
        return false
      }
    }

    return true
  }, [startDate, endDate, fundingAllocations, alternate, totalAlternates])

  const renderDropdown = (isDefault: boolean) => (
    <DropdownMenu
      button={(
        <Button
          hierarchy="tertiary"
          icon="dots-vertical"
          size="small"
          negativeMargins
        />
      )}
      minWidth={260}
    >
      <DropdownMenu.Item
        label={isDefault
          ? i18n.t('modals:AddFundingToRegularBookings:removeAllFundedHours')
          : i18n.t('modals:AddFundingToRegularBookings:removeFundedHoursFromThisWeek')}
        onClick={() => onRemoveAllFundedHours(isDefault)}
      />
    </DropdownMenu>
  )

  const renderDefaultAllocationDay = (weekDayIndex) => {
    const dayOfWeekName = DAYS_OF_WEEK_LIST[weekDayIndex] as DaysOfWeek
    const filteredSessions = _.filter(sessionAllocations, ({ dayOfWeek }) => (
      dayOfWeekName === dayOfWeek
    ))
    const filteredFundings = _.filter(defaultFundingAllocations, ({ dayOfWeek }) => (
      dayOfWeekName === dayOfWeek
    ))
    const finalFundings = _.map(filteredFundings, (item) => ({
      ...item,
      product: {
        ...item.product,
        name: formValues.product?.label,
      },
    }))

    const summary = _.sumBy(finalFundings, ({ times }) => (
      _.sumBy(times, ({ endTime, startTime }) => endTime - startTime)
    ))

    const restFundingsDayDefaultAllocations = []

    _.each(restFundings, ({ product, settings }) => {
      _.each(settings?.defaultAllocations, (defaultAllocation) => {
        if (dayOfWeekName === defaultAllocation.dayOfWeek) {
          restFundingsDayDefaultAllocations.push({
            ...defaultAllocation,
            product,
          })
        }
      })
    })

    return (
      <AllocationBar
        allocateHoursWithinSessionTimesError={allocateHoursWithinSessionTimesError}
        allocations={finalFundings}
        date={moment().startOf('week').add(weekDayIndex, 'days')}
        isLoading={isFetching}
        openedAllocation={openedAllocation}
        restFundingsDayAllocations={restFundingsDayDefaultAllocations}
        sessions={filteredSessions}
        summary={summary ? formatTime(summary) : i18n.t('global:Hours', { value: 0 })}
        isDefault
        onCloseAllocationPopup={onCloseAllocationPopup}
        onCreateAllocation={onCreateDefaultAllocation(dayOfWeekName)}
        onEditAllocation={onEditAllocation(true)}
        onOpenAllocation={onOpenAllocation}
        onRemoveAllocation={onRemoveAllocation(true)}
      />
    )
  }

  const renderDefaultAllocations = () => {
    const summary = _.sumBy(defaultFundingAllocations, ({ times }) => (
      _.sumBy(times, ({ endTime, startTime }) => endTime - startTime)
    ))

    let finalMaxHoursPerWeek = i18n.t('global:Hours', { value: Math.floor(maxHoursPerWeek) })
    const fraction = convertDecimalToInteger(maxHoursPerWeek) - (Math.floor(maxHoursPerWeek) * 100)

    if (fraction) {
      finalMaxHoursPerWeek += ` ${i18n.t('global:Minutes', { value: Math.floor(fraction * 0.6) })}`
    }

    if (100 === fraction) {
      finalMaxHoursPerWeek = i18n.t('global:Hours', { value: Math.floor(maxHoursPerWeek) + 1 })
    }

    return (
      <div>
        <Space space="15px" />
        <Grid>
          <Grid.Item alignItems={{ desktop: 'center' }} flex={{ desktop: 1 }}>
            <Typography fontSize={20} bold>
              {i18n.t('modals:AddFundingToRegularBookings:allocateWeeklyFundedHoursToATypicalWeek')}
            </Typography>
          </Grid.Item>
        </Grid>
        <Grid>
          <Grid.Item alignItems={{ desktop: 'center' }} flex={{ desktop: 1 }}>
            <Typography>
              <Typography variant="span" bold>
                {summary ? formatTime(summary) : i18n.t('global:Hours', { value: 0 })}
                {' / '}
                {finalMaxHoursPerWeek}
              </Typography>
              {' '}
              {i18n.t('modals:AddFundingToRegularBookings:weeklyHoursAllocated')}
            </Typography>
          </Grid.Item>
          <Grid.Item alignItems={{ desktop: 'center' }}>
            {showRecalculateButton ? (
              <Button
                hierarchy="tertiary"
                isLoading={isFetching}
                label={i18n.t('modals:AddFundingToRegularBookings:reapplyToCheapestHours')}
                size="small"
                onClick={onApplyToCheapestHours}
              />
            ) : (
              <Space padding="48px 1px 0" />
            )}
          </Grid.Item>
          <Grid.Item alignItems={{ desktop: 'center' }}>
            {renderDropdown(true)}
          </Grid.Item>
        </Grid>
        <Space space="5px" />
        {_.times(7, renderDefaultAllocationDay)}
        <Space space="25px" />
      </div>
    )
  }

  const handleArrowClick = (direction, newDate, onChange) => {
    const finalNewDate = moment(newDate).add(7 * direction * totalAlternates, 'days')

    if (!checkIfWeekOfDayIsDisabled(finalNewDate)) {
      onChange([finalNewDate, moment(finalNewDate).endOf('week')])
    }

    return true
  }

  const renderFoundedDay = (i) => {
    const iterationDate = moment(formValues?.week?.[0]).startOf('week').add(i, 'days')
    const finalStartDate = moment(minStartDateOfAllocations).isBefore(startDate, 'days')
      ? moment(minStartDateOfAllocations)
      : startDate
    const finalEndDate = moment(maxStartDateOfAllocations).isAfter(endDate, 'days')
      ? moment(maxStartDateOfAllocations)
      : endDate

    const isDisabled = (
      moment(iterationDate).isBefore(finalStartDate, 'days')
      || (finalEndDate && moment(iterationDate).isAfter(finalEndDate, 'days'))
    )

    const allocationsForWeekDay = _.filter(fundingAllocations, ({ date }) => isSameDay(iterationDate, date))

    const finalFundings = _.map(allocationsForWeekDay, (item) => ({
      ...item,
      product: {
        ...item.product,
        name: formValues.product?.label,
      },
    }))

    const summary = _.sumBy(finalFundings, ({ times }) => (
      _.sumBy(times, ({ endTime, startTime }) => endTime - startTime)
    ))

    const filteredSessions = _.filter(sessionAllocations, (allocation) => (
      _.findIndex(DAYS_OF_WEEK_LIST, (value) => value === allocation.dayOfWeek) === i
    ))

    const restFundingsDayAllocations = []

    _.each(restFundings, ({ product, settings }) => {
      _.each(settings?.allocations, (allocation) => {
        if (isSameDay(iterationDate, allocation.date)) {
          restFundingsDayAllocations.push({
            ...allocation,
            product,
          })
        }
      })
    })

    return (
      <AllocationBar
        allocateHoursWithinSessionTimesError={allocateHoursWithinSessionTimesError}
        allocations={finalFundings}
        date={iterationDate}
        disabled={isDisabled}
        excludedFundingReasons={excludedFundingReasons}
        isLoading={isFetching}
        openedAllocation={openedAllocation}
        restFundingsDayAllocations={restFundingsDayAllocations}
        sessions={filteredSessions}
        summary={summary ? formatTime(summary) : i18n.t('global:Hours', { value: 0 })}
        onCloseAllocationPopup={onCloseAllocationPopup}
        onCreateAllocation={onCreateAllocation(iterationDate)}
        onEditAllocation={onEditAllocation(false)}
        onOpenAllocation={onOpenAllocation}
        onRemoveAllocation={onRemoveAllocation(false)}
      />
    )
  }

  const renderActualAllocationPerWeek = () => {
    const weekDay = moment(formValues?.week?.[0])
    const allocationsForWeekDay = _.filter(fundingAllocations, ({ date }) => (
      moment(date).isSameOrBefore(weekDay.endOf('week'))
      && moment(date).isSameOrAfter(weekDay.startOf('week'))
    ))
    const summary = _.sumBy(allocationsForWeekDay, ({ times }) => (
      _.sumBy(times, ({ endTime, startTime }) => endTime - startTime)
    ))

    let finalMaxHoursPerWeek = i18n.t('global:Hours', { value: Math.floor(maxHoursPerWeek) })
    const fraction = convertDecimalToInteger(maxHoursPerWeek) - (Math.floor(maxHoursPerWeek) * 100)

    if (fraction) {
      finalMaxHoursPerWeek += ` ${i18n.t('global:Minutes', { value: Math.floor(fraction * 0.6) })}`
    }

    if (100 === fraction) {
      finalMaxHoursPerWeek = i18n.t('global:Hours', { value: Math.floor(maxHoursPerWeek) + 1 })
    }

    return (
      <div>
        <Button
          hierarchy="tertiary"
          icon={isOpenActualAllocationPerWeek ? 'chevron-up' : 'chevron-down'}
          label={i18n.t('modals:AddFundingToRegularBookings:showActualAllocationPerWeek')}
          size="small"
          onClick={() => setIsOpenActualAllocationPerWeek(!isOpenActualAllocationPerWeek)}
        />
        {isOpenActualAllocationPerWeek && (
          <Box withPadding>
            <Form.Row
              label={_.upperFirst(i18n.t('global:week'))}
              margin="-10px 0 0"
              verticalLabel
            >
              <StyledDatePickerWrapper>
                <Field
                  component={Form.DatePicker}
                  disabledDays={checkIfWeekOfDayIsDisabled}
                  name="week"
                  type="week"
                  range
                  onArrowClick={handleArrowClick}
                />
              </StyledDatePickerWrapper>
            </Form.Row>
            <Space space="15px" />
            <Grid>
              <Grid.Item alignItems={{ desktop: 'center' }} flex={{ desktop: 1 }}>
                <Typography>
                  <Typography variant="span" bold>
                    {summary ? formatTime(summary) : i18n.t('global:Hours', { value: 0 })}
                    {' / '}
                    {finalMaxHoursPerWeek}
                  </Typography>
                  {' '}
                  {i18n.t('modals:AddFundingToRegularBookings:weeklyHoursAllocated')}
                </Typography>
              </Grid.Item>
              <Grid.Item alignItems={{ desktop: 'center' }}>
                {renderDropdown(false)}
              </Grid.Item>
            </Grid>
            <Space space="5px" />
            {_.times(7, renderFoundedDay)}
          </Box>
        )}
      </div>
    )
  }

  const renderFooter = () => (
    <FooterActions spaceBetween>
      <FooterActions.Group>
        <FooterActions.Item>
          <Button
            hierarchy="tertiary"
            label={i18n.t('global:cancel')}
            negativeMargins
            onClick={onCloseClick}
          />
        </FooterActions.Item>
      </FooterActions.Group>
      <FooterActions.Group>
        <FooterActions.Item>
          <Button
            disabled={allocateHoursWithinSessionTimesError || isFetching}
            label={i18n.t('modals:AddFundingToRegularBookings:saveAllocation')}
            negativeMargins
            submit
          />
        </FooterActions.Item>
      </FooterActions.Group>
    </FooterActions>
  )

  const renderIsProductSelected = () => formValues?.product && !errorMessages && (
    <React.Fragment>
      <Grid>
        <Grid.Item width={{ desktop: '200px', mobile: '100%' }}>
          <Form.Row
            label={i18n.t('modals:AddFundingToRegularBookings:localAuthorityHourlyRate')}
            margin="0 0 10px 0"
            width={{ field: '100%' }}
            verticalLabel
          >
            <SubdomainCurrencyProvider>
              {({ currencySymbol }) => (
                <Field
                  component={Form.TextField}
                  disabled={!formValues?.settings?.customLocalAuthority}
                  name="settings.hourlyRate"
                  placeholder="0.00"
                  prefix={currencySymbol}
                  prefixWidth="30px"
                  type="number"
                />
              )}
            </SubdomainCurrencyProvider>
            <Typography
              fontSize={14}
              margin="5px 0 0 0"
              bold
              primary
              onClick={() => onChangeHourlyRateType(!formValues?.settings?.customLocalAuthority)}
            >
              {!formValues?.settings?.customLocalAuthority && (
                i18n.t('modals:AddFundingToRegularBookings:useCustomHourlyRate')
              )}
              {formValues?.settings?.customLocalAuthority && (
                i18n.t('modals:AddFundingToRegularBookings:useDefaultHourlyRate')
              )}
            </Typography>
          </Form.Row>
        </Grid.Item>
        <Grid.Item width={{ desktop: '120px', mobile: '100%' }}>
          <Form.Row
            label={i18n.t('modals:AddFundingToRegularBookings:hoursPerWeek')}
            margin="0 0 10px 0"
            width={{ field: '100%' }}
            verticalLabel
          >
            <Field
              component={Form.TextField}
              name="settings.hoursPerWeek"
              placeholder="0"
              type="number"
              onChange={() => onChangeShowRecalculateButton(true)}
            />
          </Form.Row>
        </Grid.Item>
        {formValues.product.type === NurseryFundingProductType.NURSERY_ALLOCATED_FUNDING && (
          <Grid.Item width={{ desktop: '120px', mobile: '100%' }}>
            <Form.Row
              label={i18n.t('modals:AddFundingToRegularBookings:maxHoursPerDay')}
              margin="0 0 10px 0"
              width={{ field: '100%' }}
              verticalLabel
            >
              <Field
                component={Form.TextField}
                name="settings.hoursPerDay"
                placeholder="0"
                type="number"
                onChange={() => onChangeShowRecalculateButton(true)}
              />
            </Form.Row>
          </Grid.Item>
        )}
      </Grid>
      {formValues?.settings?.customLocalAuthority && (
        <Banner.Info>
          {i18n.t('modals:AddFundingToRegularBookings:customHourlyRateInfo')}
        </Banner.Info>
      )}
      {formValues.product.type === NurseryFundingProductType.NURSERY_ALLOCATED_FUNDING && (
        <React.Fragment>
          {renderDefaultAllocations()}
          {renderActualAllocationPerWeek()}
        </React.Fragment>
      )}
      {formValues.product.type === NurseryFundingProductType.NURSERY_SUBTRACT_FUNDING && (
        <Banner.Info>
          {i18n.t('modals:AddFundingToRegularBookings:infoToSubtractFunding')}
        </Banner.Info>
      )}
      <FundingSummary
        data={summaryFunding ? [summaryFunding] : null}
        isFetching={isFetchingSummaryFunding}
      />
    </React.Fragment>
  )

  return (
    <StyledFormWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBox.ScrollContent>
          <Callout content={errorMessages} error />
          <Grid>
            <Grid.Item width={{ desktop: '300px', mobile: '100%' }}>
              <Form.Row
                label={i18n.t('modals:AddFundingToRegularBookings:fundingType')}
                margin="-10px 0 10px 0"
                width={{ field: '100%' }}
                required
                verticalLabel
              >
                <Field
                  clearable={false}
                  component={Form.InfiniteDropdowns.NurseryFundingsV3}
                  criteria={[{
                    field: 'type',
                    value: [
                      NurseryFundingProductType.NURSERY_REGULAR_FUNDING,
                    ],
                  }]}
                  disabledOptions={usedNurseryFundings}
                  extraFields={['type', 'priceChanges', 'settings']}
                  name="product"
                  placeholder={i18n.t('modals:AddFundingToRegularBookings:fundingType')}
                  validate={isRequired}
                  bodyTarget
                  onChange={onChangeNurseryFunding}
                />
              </Form.Row>
            </Grid.Item>
          </Grid>
          {renderIsProductSelected()}
        </ModalBox.ScrollContent>
        {renderFooter()}
      </Form>
    </StyledFormWrapper>
  )
}

export default reduxForm<{}, AddFundingToRegularBookingsModalFormProps>({
  form: ADD_FUNDING_TO_REGULAR_BOOKINGS_MODAL_FORM,
})(AddFundingToRegularBookingsModalForm)
