import _ from 'lodash'

import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'

import { SUPPORTED_FILE_TYPES } from 'constants/mimetypes'
import { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'

import { getOptimizedImage, isBlobUrl } from 'utils/image'

import { Avatar, FieldError, Icon, Typography } from 'components'

import i18n from 'translations'

import {
  StyledBadge,
  StyledButtonContainer,
  StyledInputFile,
  StyledTextPlaceholder,
  StyledUploadButton,
} from './PhotoUploadStyled'

export interface PhotoUploadProps {
  disabled?: boolean
  error?: string
  imageSize?: {
    height: number
    max: boolean
    width: number
  }
  onChange: (e: {
    file: File
    isUploaded: boolean
    value: string
  }) => {} | void
  value: string
}

const PhotoUpload: React.FC<PropsWithChildren<PhotoUploadProps>> = ({
  disabled,
  error,
  imageSize,
  onChange,
  value,
}) => {
  const selectedFileRef = useRef(null)

  const [previewImage, setPreviewImage] = useState<{
    file: File
    isUploaded: boolean
    value: string
  }>({
    file: null,
    isUploaded: true,
    value,
  })

  useEffect(() => setPreviewImage((e) => ({
    ...e,
    value,
  })), [value])

  const onFilesAdded = (e) => {
    const file = e.target.files?.[0]

    if (!file) {
      return
    }

    const normalizedFile = file
    normalizedFile.mimeType = file.type
    normalizedFile.url = URL.createObjectURL(file)

    setPreviewImage({
      file: normalizedFile,
      isUploaded: false,
      value: normalizedFile.url,
    })

    onChange({
      file: normalizedFile,
      isUploaded: false,
      value: normalizedFile.url,
    })
  }

  return (
    <StyledUploadButton $disabled={disabled}>
      {!disabled && (
        <StyledInputFile
          accept={_.flatMap(_.map(SUPPORTED_FILE_TYPES.IMAGES, ({ mimeTypes }) => mimeTypes)).join(', ')}
          ref={selectedFileRef}
          type="file"
          onChange={onFilesAdded}
          onClick={() => {
            selectedFileRef.current.value = null
          }}
        />
      )}
      <StyledButtonContainer>
        <StyledTextPlaceholder>
          <Typography color={NEUTRAL_COLOURS.WHITE} fontSize="12" bold>
            {i18n.t('components:PhotoUpload:changePhoto')}
          </Typography>
        </StyledTextPlaceholder>
        <Avatar
          avatarSize="big"
          borderColor={error ? FLAG_COLOURS.ERROR : undefined}
          borderWidth={error && 1}
          showPlaceholder={!previewImage.value}
          src={imageSize && !isBlobUrl(previewImage.value)
            ? getOptimizedImage(previewImage.value, imageSize)
            : previewImage.value}
          withoutOptimization
        />
        <StyledBadge>
          <Icon
            color={NEUTRAL_COLOURS.WHITE}
            height={22}
            icon="plus"
          />
        </StyledBadge>
      </StyledButtonContainer>
      <FieldError error={error} />
    </StyledUploadButton>
  )
}

export default PhotoUpload
