import _ from 'lodash'
import { v1 } from 'uuid'

import i18n from 'translations'

export const toInt = (value) => (Number.isNaN(parseInt(value)) ? null : parseInt(value))

export const toFloat = (value, decimalPlace = 2) => (
  Number.isNaN(parseFloat(value)) ? null : parseFloat(parseFloat(value).toFixed(decimalPlace))
)

export const nFormatter = (num, digits) => {
  const lookup = [
    { symbol: '', value: 1 },
    { symbol: 'k', value: 1e3 },
    { symbol: 'm', value: 1e6 },
    { symbol: 'g', value: 1e9 },
    { symbol: 't', value: 1e12 },
    { symbol: 'p', value: 1e15 },
    { symbol: 'e', value: 1e18 },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  const item = lookup.slice().reverse().find(({ value }) => num >= value)

  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0'
}

export const mergeData = (stateData, responseData, mergeResult) => {
  if (!mergeResult || !stateData) {
    return responseData
  }

  return [...stateData, ...responseData]
}

export const formatBytes = (bytes: number, decimals: number = 2) => {
  if (-1 === bytes) {
    return `${i18n.t('global:unknown')}`
  }

  if (0 === bytes) {
    return `0 ${i18n.t('global:bytes')}`
  }

  const k = 1000
  const dm = 0 > decimals ? 0 : decimals
  const units = [i18n.t('global:bytes'), 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${units[i]}`
}

export const toNestedGroups = (seq, keys) => {
  if (!keys.length) { return seq }

  const first = keys[0]
  const rest = keys.slice(1)

  return _.mapValues(_.groupBy(seq, first), (value) => toNestedGroups(value, rest))
}

export const convertToArray = (data) => {
  if (!data) {
    return []
  }

  if (Array.isArray(data)) {
    return data
  }

  return [data]
}

export const sortByKey = (object, orderList) => {
  const keys = Object.keys(object)
  const sortedKeys = _.sortBy(keys, (key) => orderList[key])

  return _.fromPairs(
    _.map(sortedKeys, (key) => [key, object[key]]),
  )
}

export const isPlainObject = (object) => '[object Object]' === Object.prototype.toString.call(object)

export const convertToPercent = (total, value) => (total && value ? ((value / total) * 100).toFixed(2) : 0)

export const addNumberSuffix = (day: number): string => {
  let suffix = i18n.t('utils:Data:NumberSuffix:th')

  if (0 === day) suffix = ''
  if (1 === day % 10 && 11 !== day % 100) suffix = i18n.t('utils:Data:NumberSuffix:st')
  if (2 === day % 10 && 12 !== day % 100) suffix = i18n.t('utils:Data:NumberSuffix:nd')
  if (3 === day % 10 && 13 !== day % 100) suffix = i18n.t('utils:Data:NumberSuffix:rd')

  return day + suffix
}

export const mapValuesAsync = async (object, asyncFn) => (
  object && Object.fromEntries(
    await Promise.all(
      Object.entries(object).map(async ([key, value]) => [
        key,
        await asyncFn(value, key, object),
      ]),
    ),
  )
)

export const getPercentage = (total, value) => (
  total && value ? parseInt(((value / total) * 100).toFixed(2)) : 0
)

export const convertPayloadToDisplayValue = (value) => {
  if (!value) {
    return null
  }

  return +value / 100
}

export const convertDisplayToPayloadValue = (value) => {
  if (!value) {
    return null
  }

  return +value * 100
}

export const uuidV6 = () => {
  const raw = v1()

  const prefix = `${raw.substring(15, 18)}${raw.substring(9, 13)}${raw.substring(0, 5)}6${raw.substring(5, 8)}`
  const prefixFormatted = `${prefix.substr(0, 8)}-${prefix.substr(8, 4)}-${prefix.substr(12)}`

  return `${prefixFormatted}${raw.substr(18)}`
}

export const convertDecimalToInteger = (value: number, fractionDigits: number = 2) => (
  Math.round(
    (Math.trunc(Math.round(value * (10 ** fractionDigits))) / (10 ** fractionDigits)) * (10 ** fractionDigits),
  )
)

