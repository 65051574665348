import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

const getFinanceReports = (state) => state.financeCreditNotesReport

export const getFinanceCreditNotesReportChildren = createSelector(
  [getFinanceReports],
  (state) => state.children,
)

export const getFinanceCreditNotesReportChildrenData = createSelector(
  [getFinanceCreditNotesReportChildren],
  (state) => state.data,
)

export const getCriteria = ({ author, dateRange }) => {
  const criteria = []

  if (dateRange?.after && dateRange?.before) {
    criteria.push({
      field: 'startDate',
      value: moment(dateRange.after).format(DEFAULT_DATE_FORMAT),
    })

    criteria.push({
      field: 'endDate',
      value: moment(dateRange.before).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (author) {
    criteria.push({
      field: 'author',
      value: author,
    })
  }

  return criteria
}

export const getExportCriteria = ({ dateRange }) => {
  const criteria = []

  if (dateRange?.after && dateRange?.before) {
    criteria.push({
      comparator: 'after',
      field: 'createdAt',
      value: moment(dateRange.after).format(DEFAULT_DATE_FORMAT),
    })

    criteria.push({
      comparator: 'before',
      field: 'createdAt',
      value: moment(dateRange.before).format(DEFAULT_DATE_FORMAT),
    })
  }

  return criteria
}

