import _ from 'lodash'
import moment, { unitOfTime } from 'moment'

import { Option } from 'constants/models'
import { DEFAULT_DATE_FORMAT, FULL_MONTH_NAME_FORMAT } from 'constants/date'

import { addNumberSuffix, toFloat } from 'utils/data'

import i18n from 'translations'

export const convertTimeDuration = (
  time,
  unit: unitOfTime.DurationConstructor = 'minutes',
  outputUnit: unitOfTime.Base = 'milliseconds',
) => (
  moment.duration(time, unit).as(outputUnit)
)

export const getHourDiffBetweenEpochTime = (startTime, endTime) => {
  const startTimeWithDate = moment(startTime)
  const endTimeWithDate = moment(endTime)
  const timeDurationHours = convertTimeDuration(endTimeWithDate.diff(startTimeWithDate), null, 'hours')

  return toFloat(timeDurationHours, 2)
}

export const floatToHoursAndMinutes = (value) => {
  const hours = convertTimeDuration(Math.floor(value), 'h', 'hours')
  const minutes = Math.round(convertTimeDuration(value - Math.floor(value), 'h', 'minutes'))

  return {
    hours,
    minutes,
  }
}

export const hoursAndMinutesToFloat = (hours = 0, minutes = 0) => (
  toFloat(convertTimeDuration(`${hours}:${minutes}`, null, 'hours')) || 0
)

export const millisecondsFromMidnight = (dateTime) => moment(dateTime).valueOf() - moment(dateTime)
  .startOf('day').valueOf()

export const addMillisecondsFromMidnight = (ms, date) => moment(date).startOf('day').add(ms, 'milliseconds')

export const isToday = (date) => {
  if (!date) {
    return false
  }

  return moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
}

export const isTimeBetweenStartAndEndTime = (startTime, endTime, compareDate = new Date()) => {
  const currentTime = +moment(compareDate).format('HHmm')
  const momentStartTime = +moment(startTime).utc().format('HHmm')
  const momentEndTime = +moment(endTime).utc().format('HHmm')

  return currentTime >= momentStartTime && currentTime <= momentEndTime
}

export const millisecondsToHoursAndMinutes = (milliseconds) => {
  const duration = moment.duration(milliseconds, 'milliseconds')

  const hours = Math.floor(duration.asHours())
  const minutes = Math.floor(duration.asMinutes()) - hours * 60

  return { hours, minutes }
}

export const millisecondsToHoursAndMinutesString = (milliseconds, fullLabel?, disableSeconds?) => {
  let formattedMilliseconds = milliseconds
  let isNegative = false

  if (0 > formattedMilliseconds) {
    isNegative = true
    formattedMilliseconds *= -1
  }

  if (formattedMilliseconds && 60000 > Math.abs(formattedMilliseconds) && !disableSeconds) {
    return '< 1min'
  }

  const { hours, minutes } = millisecondsToHoursAndMinutes(formattedMilliseconds)

  const times = []

  if (hours) {
    times.push(`${hours}${fullLabel ? ` ${i18n.t('global:hours')}` : 'h'}`)
  }

  if (minutes) {
    times.push(`${minutes}${fullLabel ? ` ${i18n.t('global:min')}` : 'm'}`)
  }

  return `${isNegative ? '-' : ''}${times.join(' ')}`
}

export const hoursAndMinutesToMilliseconds = (time) => moment(time).valueOf() - moment(time).startOf('day').valueOf()

export const isSameDay = (startDate, endDate) => (
  moment(startDate).format(DEFAULT_DATE_FORMAT) === moment(endDate).format(DEFAULT_DATE_FORMAT)
)

export const roundMilliseconds = (milliseconds: number) => {
  const hours = milliseconds / 1000 / 60 / 60
  const roundedHoursInMilliseconds = hours * 1000 * 60 * 60

  const minutes = (milliseconds - roundedHoursInMilliseconds) / 1000 / 60
  const roundedMinutesInMilliseconds = minutes * 1000 * 60

  return roundedHoursInMilliseconds + roundedMinutesInMilliseconds
}

export const removeDate = (date, options?) => {
  const { utc } = options || {}

  if (utc) {
    return moment(0).utc()
      .set('hours', +moment(date).format('HH'))
      .set('minutes', +moment(date).format('mm'))
      .set('seconds', +moment(date).format('ss'))
  }

  return moment(0)
    .set('hours', +moment(date).format('HH'))
    .set('minutes', +moment(date).format('mm'))
    .set('seconds', +moment(date).format('ss'))
}

export const removeTimes = (date) => moment(date)
  .set('hours', 0)
  .set('minutes', 0)
  .set('seconds', 0)
  .set('milliseconds', 0)

export const removeSeconds = (date) => moment(date).set('seconds', 0)

export const isPastDay = (date) => (
  0 < moment().startOf('day').diff(moment(date, DEFAULT_DATE_FORMAT), 'days')
)

export const isFutureDay = (date) => (
  0 < moment(date, DEFAULT_DATE_FORMAT).diff(moment().startOf('day'), 'days')
)

export const getMonthList = (startDate, endDate) => {
  const monthList = []
  const startDateClone = moment(startDate).clone()
  const endDateClone = moment(endDate).clone()

  while (endDateClone > startDateClone || startDateClone.format('M') === endDateClone.format('M')) {
    monthList.push(startDateClone.format('YYYY-MM-DD'))
    startDateClone.add(1, 'month')
  }

  return monthList
}

export const populateYears = (fromMonth, toMonth) => {
  const years = []
  for (let year = fromMonth.getFullYear(); year <= toMonth.getFullYear(); year += 1) {
    years.push(year)
  }

  return years
}

export const getYearOptions = (numberOfPastYears = 0, options = { hideFutureYears: false }) => {
  const startDate = moment().subtract(numberOfPastYears, 'year').startOf('year')
  const endDate = options?.hideFutureYears ? moment().endOf('year') : moment().add(2, 'year').endOf('year')

  const yearOptions = []
  const currentDate = startDate

  while (currentDate.format('YYYY') <= endDate.format('YYYY')) {
    const after = moment(currentDate).startOf('year')
    const before = moment(currentDate).endOf('year')
    const monthList = getMonthList(after, before)

    yearOptions.push({
      dateRange: {
        after: after.format(DEFAULT_DATE_FORMAT),
        before: before.format(DEFAULT_DATE_FORMAT),
      },
      label: currentDate.format('YYYY'),
      monthList,
      value: currentDate.format('YYYY'),
    })

    currentDate.add(1, 'year')
  }

  return yearOptions
}

export const getTimeDifference = (startTime, endTime) => (
  startTime && endTime ? moment(endTime).valueOf() - moment(startTime).valueOf() : 0
)

export const getDateString = (date, format = DEFAULT_DATE_FORMAT) => (date ? moment(date).format(format) : null)

export const changeHours = (date, dateToReplace) => (
  moment(date).set({
    hour: moment(dateToReplace).get('hour'),
    minute: moment(dateToReplace).get('minute'),
    second: moment(dateToReplace).get('second'),
  })
)

export const convertFromMinutesToSeconds = (minutes = 0) => +minutes * 60
export const convertFromSecondsToMinutes = (seconds = 0) => (seconds ? seconds / 60 : 0)

export const getCalendarDayOptions = (): Option[] => {
  const dayOptions: Option[] = []
  let day = 1

  while (31 >= day) {
    dayOptions.push({
      label: addNumberSuffix(day),
      value: day,
    })

    day += 1
  }

  return dayOptions
}

export const checkTimeRangeOverlap = (firstRange, secondRange) => {
  const start1 = moment(firstRange[0], 'x')
  const end1 = moment(firstRange[1], 'x')
  const start2 = moment(secondRange[0], 'x')
  const end2 = moment(secondRange[1], 'x')

  if (start2.isSame(end1)) {
    return false
  }

  if (start1.isSame(end2)) {
    return false
  }

  const startFirst = start1.isBetween(start2, end2, 'minutes', '[]')
  const endFirst = end1.isBetween(start2, end2, 'minutes', '[]')

  const startLast = start2.isBetween(start1, end1, 'minutes', '[]')
  const endLast = end2.isBetween(start1, end1, 'minutes', '[]')

  return startFirst || endFirst || startLast || endLast
}

export const getMonthOptions = () => _.range(12).map((value) => ({
  label: moment().set('month', value).format(FULL_MONTH_NAME_FORMAT),
  value: value + 1,
}))

export const formatTime = (value: number = 0) => {
  const hours = moment(value, 'x').utc().diff(moment(0, 'x').utc(), 'hours')
  let result = `${i18n.t('global:Hours', { value: hours })}`

  const minutes = moment(value - (hours * 60 * 60 * 1000), 'x').utc().diff(moment(0, 'x').utc(), 'minutes', true)

  if (minutes) {
    result += ` ${i18n.t('global:Minutes', { value: toFloat(minutes, 0) })}`
  }

  return result
}
