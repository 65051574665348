import { ChildProduct } from 'services/booking/childBooking/constants'

import { BasicListInitialState, BasicSingleInitialState } from 'utils/reducer'

import i18n from 'translations'

export enum OneOffBookingTypes {
  EXTRA_ITEM = 'extra_item',
  EXTRA_SESSION = 'extra_session',
  ONE_OFF_DISCOUNT = 'one_off_discount',
  ONE_OFF_FUNDING = 'one_off_funding'
}

export enum ChildProductTypes {
  ABSTRACT_ONE_OFF_FUNDING = 'abstract_one_off_funding',
  EXTRA_ITEM = 'extra_item',
  EXTRA_SESSION = 'extra_session',
  ONE_OFF = 'one_off',
  ONE_OFF_AMOUNT_DISCOUNT = 'one_off_amount_discount',
  ONE_OFF_DISCOUNT = 'one_off_discount',
  ONE_OFF_FUNDING = 'one_off_funding',
  ONE_OFF_LINK_DISCOUNT = 'one_off_link_discount',
  ONE_OFF_NO_DEDUCT_FUNDING = 'one_off_no_deduct_funding',
  ONE_OFF_PERCENTAGE_DISCOUNT = 'one_off_percentage_discount',
  REGULAR = 'regular',
  REGULAR_ALLOCATED_FUNDING = 'regular_allocated_funding',
  REGULAR_AMOUNT_DISCOUNT = 'regular_amount_discount',
  REGULAR_DISCOUNT = 'regular_discount',
  REGULAR_FUNDING = 'regular_funding',
  REGULAR_ITEM = 'regular_item',
  REGULAR_LINK_DISCOUNT = 'regular_link_discount',
  REGULAR_NO_DEDUCT_FUNDING = 'regular_no_deduct_funding',
  REGULAR_PERCENTAGE_DISCOUNT = 'regular_percentage_discount',
  REGULAR_SESSION = 'regular_session',
  REGULAR_SUBTRACT_FUNDING = 'regular_subtract_funding'
}

export enum OneOffCustomDiscountTypes {
  ONE_OFF_AMOUNT_DISCOUNT = 'one_off_amount_discount',
  ONE_OFF_LINK_DISCOUNT = 'one_off_link_discount',
  ONE_OFF_PERCENTAGE_DISCOUNT = 'one_off_percentage_discount'
}

export const ONE_OFF_BOOKING_LABELS = {
  [ChildProductTypes.EXTRA_SESSION]: i18n.t('module:Finance:OneOffBookings:BookingTypes:extraSession'),
  [ChildProductTypes.EXTRA_ITEM]: i18n.t('module:Finance:OneOffBookings:BookingTypes:extraItem'),
  [ChildProductTypes.ONE_OFF_DISCOUNT]: i18n.t('module:Finance:OneOffBookings:BookingTypes:oneOffDiscount'),
  [ChildProductTypes.ONE_OFF_FUNDING]: i18n.t('module:Finance:OneOffBookings:BookingTypes:oneOffFunding'),
  [ChildProductTypes.ONE_OFF_NO_DEDUCT_FUNDING]: i18n.t('module:Finance:OneOffBookings:BookingTypes:oneOffFunding'),
}

export const ONE_OFF_BOOKING_TYPE_OPTIONS = [
  {
    label: ONE_OFF_BOOKING_LABELS[ChildProductTypes.EXTRA_SESSION],
    value: ChildProductTypes.EXTRA_SESSION,
  },
  {
    label: ONE_OFF_BOOKING_LABELS[ChildProductTypes.EXTRA_ITEM],
    value: ChildProductTypes.EXTRA_ITEM,
  },
  {
    label: ONE_OFF_BOOKING_LABELS[ChildProductTypes.ONE_OFF_DISCOUNT],
    value: ChildProductTypes.ONE_OFF_DISCOUNT,
  },
  {
    label: ONE_OFF_BOOKING_LABELS[ChildProductTypes.ONE_OFF_FUNDING],
    value: ChildProductTypes.ONE_OFF_FUNDING,
  },
]

export interface ChildProductsSingleState extends BasicSingleInitialState {
  data: ChildProduct
}

export interface ChildProductsListState extends BasicListInitialState {
  data: ChildProduct[]
}

export interface ChildProductsState {
  list: ChildProductsListState
  single: ChildProductsSingleState
}

export interface ChildProductsRootState {
  childProducts: ChildProductsState
}
