import { createGlobalStyle } from 'styled-components'

import colors, { NEUTRAL_COLOURS } from 'constants/colors'
import layout, { ZINDEX_ORDER } from 'constants/layout'

import { getBrandingColor } from 'utils/branding'
import { contrastingColor } from 'utils/colors'

export const GlobalStyled = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  html,
  body,
  #app {
    color: #575756;
  }
  
  html {
    font-size: 16px;
    background: #FFF;
  }

  #root {
    --app-height: 100vh;
  }
  
  html, body, #root {
    min-height: 100vh;
  }
  
  body {
    color: ${NEUTRAL_COLOURS.BASIC};
    font-family: ${layout.fontFamily};
    min-width: 320px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    &.ReactModal__Body--open {
      overflow: hidden !important;
    }
  }
  
  &::selection {
    color: ${contrastingColor(getBrandingColor('primary-color'))};
    background: ${getBrandingColor('primary-color')};
  }
  
  a,
  a:hover {
    text-decoration: none;
    color: ${getBrandingColor('primary-color')};
  }
  
  strong {
    font-weight: 600;
  }

  .fullscreen {
    z-index: ${ZINDEX_ORDER.GALLERY} !important;
    position: absolute;
  }
  
  .tox-tinymce-aux {
    z-index: ${ZINDEX_ORDER.BASIC} !important;
  }
  
  /* stylelint-disable */
  input,
  textarea {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px ${NEUTRAL_COLOURS.WHITE} inset;
    }
  }
  /* stylelint-enable */
  
  #layers {
    z-index: ${ZINDEX_ORDER.POPOVER};
  }

  .highcharts-root {
    font-family: ${layout.fontFamily} !important;
  }
  
  .DateInput--disabled {
    cursor: not-allowed;
    background: ${colors.disabled} !important;
  }
  
  select {
    background: transparent;
  }

  .DateInput__display-text:not(.DateInput__display-text--has-input) {
    color: #747474 !important;
  }
  
  input,
  textarea,
  select,
  button {
    appearance: none;
    font-family: inherit;
    font-size: inherit;
    border-radius: 0;
    border: none;
  }

  th {
    font-weight: 600;
  }

  .rte-autocomplete > li:not(:first-child) {
    border-top: 1px dashed #808080;
  }

  .rte-autocomplete > li .name {
      font-size: 16px;
  }
  
  .rte-autocomplete > li .placeholder {
    font-size: 14px;
    opacity: .8;
  }
  
  .lazy-load-image-background.opacity {
    background-image: none !important;
    opacity: 0;
  }
  
  .lazy-load-image-background.opacity.lazy-load-image-loaded {
    opacity: 1;
    transition: opacity .3s;
  }
  
  .ReactModal__Overlay {
    position: fixed !important;
    left: 0 !important;
    top: 0 !important;
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5) !important;
    z-index: ${ZINDEX_ORDER.MODAL};
    padding: 18px;
    overflow: auto;
    
    @media(max-width: 480px) {
      padding: 5px;
    }
    
    &--hidden {
      background: transparent !important;
    }
  }
    
  .react-images__positioner {
    transition: none !important;
    
    .react-images__view-wrapper {
      text-align: center;
    }

    * {
      transition: none !important;
    }
    
    & > div {
      max-width: 100%;
    }
  }

  .MuiTooltip-popper {
    z-index: ${ZINDEX_ORDER.TOOLTIP} !important;
  }

  .MuiTooltip-tooltip {
    font-family: ${layout.fontFamily} !important;
    font-size: 14px !important;
    padding: 8px 10px !important;
    line-height: normal !important;
    transform: translateX(5px) !important;
  }
  
  .MuiTooltip-popperArrow[x-placement*="bottom"] .MuiTooltip-arrow {
    transform: translateX(-5px);
  }

  .MuiSnackbar-anchorOriginTopRight {
    margin-top: 50px;
  }
  
  .TimePickerPopup {
    z-index: ${ZINDEX_ORDER.BASIC} !important;
    
    .rc-time-picker-panel-inner {
      margin-top: 45px;
      box-shadow: 0 0 0 1px hsla(0,0%,0%,.1), 0 4px 11px hsla(0,0%,0%,.1);
      border-radius: ${layout.borderRadius};
      border: none;
    }
    
    &.rc-time-picker-panel-placement-topLeft {
      .rc-time-picker-panel-inner {
        margin-bottom: 45px;
        margin-top: 0;
      }
    }
    
    .rc-time-picker-panel-input-wrap {
      display: none;
    }

    .rc-time-picker-panel-select li {
      font-size: 14px;
      font-family: ${layout.fontFamily};
      height: 30px;
      line-height: 30px;
      padding: 0 0 0 13px;
      
      &.rc-time-picker-panel-select-option-selected {
        font-weight: 600;
      }
    }
  }
`
