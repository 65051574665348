import _ from 'lodash'

import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'
// eslint-disable-next-line
import { FundingAddFormValues } from 'module/Management/ManagementFinanceSettings/ManagementFunding/v3/ManagementFundingAdd/components/FundingAddForm/FundingAddForm'
import {
  FundingAllocationValues,
  FundingPeriodValues,
  FundingTypeValues,
  NurseryFundingProduct,
  NurseryFundingProductType,
} from 'services/product/nurseryFundingV3/constants'

import { convertDecimalToInteger } from 'utils/data'

import { getPriceChangesBody, getPriceChangesInitialValues } from '../../selectors'

export const getNurseryFundingV3SingleState = (state: RootState) => state.nurseryFundingV3.single

export const getNurseryFundingV3SingleDataState = createSelector(
  [getNurseryFundingV3SingleState],
  (state) => state.data,
)

export const getBody = (params: FundingAddFormValues) => {
  const body = {} as NurseryFundingProduct
  const {
    deductFromParentInvoice,
    fundingAllocation,
    fundingType,
    name,
    priceChanges,
    settings,
  } = params || {}
  const {
    bookingPlanEndsMidWeek,
    closureDaysSessionChargeable,
    closureDaysSessionNotChargeable,
    deficitCharged,
    deficitLineItemName,
    fundingPeriod,
    hoursPerWeek,
    invoiceLineItemDeduction,
    invoiceLineItemDisplay,
    maxHours,
    maxMinutes,
    minutesPerWeek,
    showItemOnInvoice,
    stretchedWeeks,
    termTimeHolidays,
    total,
  } = settings || {}

  if (name) {
    body.name = name
  }

  body.type = NurseryFundingProductType.NURSERY_FUNDING

  if (fundingType === FundingTypeValues.REGULAR) {
    if (fundingAllocation === FundingAllocationValues.ALLOCATE_TO_SESSIONS) {
      body.type = NurseryFundingProductType.NURSERY_ALLOCATED_FUNDING
    }

    if (fundingAllocation === FundingAllocationValues.SUBTRACT_FUNDING_RATE) {
      body.type = NurseryFundingProductType.NURSERY_SUBTRACT_FUNDING
    }
  }

  if (fundingType === FundingTypeValues.ONE_OFF) {
    body.type = NurseryFundingProductType.NURSERY_ONE_OFF_FUNDING

    if (!deductFromParentInvoice) {
      body.type = NurseryFundingProductType.NURSERY_NO_DEDUCT_ONE_OFF_FUNDING
    }
  }

  if (_.keys(settings).length) {
    body.settings = {}

    if (fundingType === FundingTypeValues.REGULAR) {
      if (bookingPlanEndsMidWeek) {
        body.settings.bookingPlanEndsMidWeek = bookingPlanEndsMidWeek
      }

      if (closureDaysSessionChargeable) {
        body.settings.closureDaysSessionChargeable = closureDaysSessionChargeable
      }

      if (closureDaysSessionNotChargeable) {
        body.settings.closureDaysSessionNotChargeable = closureDaysSessionNotChargeable
      }

      if (termTimeHolidays) {
        body.settings.termTimeHolidays = termTimeHolidays
      }

      if (minutesPerWeek) {
        body.settings.minutesPerWeek = +minutesPerWeek
      }

      if (hoursPerWeek) {
        body.settings.hoursPerWeek = +hoursPerWeek
      }

      if (maxHours) {
        body.settings.maxHours = +maxHours
      }

      if (maxMinutes) {
        body.settings.maxMinutes = +maxMinutes
      }

      if (fundingAllocation === FundingAllocationValues.ALLOCATE_TO_SESSIONS) {
        if (deficitCharged) {
          body.settings.deficitCharged = deficitCharged
        }

        if (deficitLineItemName) {
          body.settings.deficitLineItemName = deficitLineItemName
        }

        if (invoiceLineItemDeduction) {
          body.settings.invoiceLineItemDeduction = invoiceLineItemDeduction
        }
      }

      if (invoiceLineItemDisplay) {
        body.settings.invoiceLineItemDisplay = invoiceLineItemDisplay
      }

      body.settings.stretched = fundingPeriod === FundingPeriodValues.STRETCHED
      body.settings.stretchedWeeks = stretchedWeeks ? +stretchedWeeks : null
    }

    if (fundingType === FundingTypeValues.ONE_OFF) {
      if (total) {
        body.settings.total = convertDecimalToInteger(+total)
      }

      body.settings.showItemOnInvoice = !!showItemOnInvoice
    }
  }

  if (!_.keys(body.settings).length) {
    delete body.settings
  }

  if (fundingType === FundingTypeValues.REGULAR && priceChanges) {
    const finalPriceChanges = getPriceChangesBody(priceChanges)

    if (finalPriceChanges.length) {
      body.priceChanges = finalPriceChanges
    }
  }

  return body
}

export const getInitialValues = (nurseryFunding: NurseryFundingProduct) => {
  const { name, priceChanges, settings, type } = nurseryFunding || {}
  const {
    bookingPlanEndsMidWeek,
    closureDaysSessionChargeable,
    closureDaysSessionNotChargeable,
    deficitCharged,
    deficitLineItemName,
    hoursPerWeek,
    invoiceLineItemDeduction,
    invoiceLineItemDisplay,
    maxHours,
    maxMinutes,
    minutesPerWeek,
    showItemOnInvoice,
    stretched,
    stretchedWeeks,
    termTimeHolidays,
    total,
  } = settings || {}
  const initialValues = {} as FundingAddFormValues

  if (name) {
    initialValues.name = name
  }

  initialValues.deductFromParentInvoice = true

  switch (type) {
    case NurseryFundingProductType.NURSERY_ALLOCATED_FUNDING:
      initialValues.fundingType = FundingTypeValues.REGULAR
      initialValues.fundingAllocation = FundingAllocationValues.ALLOCATE_TO_SESSIONS
      break
    case NurseryFundingProductType.NURSERY_NO_DEDUCT_ALLOCATED_FUNDING:
      initialValues.fundingType = FundingTypeValues.REGULAR
      initialValues.fundingAllocation = FundingAllocationValues.ALLOCATE_TO_SESSIONS
      initialValues.deductFromParentInvoice = false
      break
    case NurseryFundingProductType.NURSERY_SUBTRACT_FUNDING:
      initialValues.fundingType = FundingTypeValues.REGULAR
      initialValues.fundingAllocation = FundingAllocationValues.SUBTRACT_FUNDING_RATE
      break
    case NurseryFundingProductType.NURSERY_NO_DEDUCT_SUBTRACT_FUNDING:
      initialValues.fundingType = FundingTypeValues.REGULAR
      initialValues.fundingAllocation = FundingAllocationValues.SUBTRACT_FUNDING_RATE
      initialValues.deductFromParentInvoice = false
      break
    case NurseryFundingProductType.NURSERY_ONE_OFF_FUNDING:
      initialValues.fundingType = FundingTypeValues.ONE_OFF
      break
    case NurseryFundingProductType.NURSERY_NO_DEDUCT_ONE_OFF_FUNDING:
      initialValues.fundingType = FundingTypeValues.ONE_OFF
      initialValues.deductFromParentInvoice = false
      break
    default:
      break
  }

  initialValues.settings = {}

  if (bookingPlanEndsMidWeek) {
    initialValues.settings.bookingPlanEndsMidWeek = bookingPlanEndsMidWeek
  }

  if (closureDaysSessionChargeable) {
    initialValues.settings.closureDaysSessionChargeable = closureDaysSessionChargeable
  }

  if (closureDaysSessionNotChargeable) {
    initialValues.settings.closureDaysSessionNotChargeable = closureDaysSessionNotChargeable
  }

  if (deficitCharged) {
    initialValues.settings.deficitCharged = deficitCharged
  }

  if (deficitLineItemName) {
    initialValues.settings.deficitLineItemName = deficitLineItemName
  }

  initialValues.settings.hoursPerWeek = hoursPerWeek || 0
  initialValues.settings.minutesPerWeek = minutesPerWeek || 0

  initialValues.settings.maxHours = maxHours || 0
  initialValues.settings.maxMinutes = maxMinutes || 0

  initialValues.settings.total = total ? total / 100 : 0
  initialValues.settings.showItemOnInvoice = showItemOnInvoice

  if (termTimeHolidays) {
    initialValues.settings.termTimeHolidays = termTimeHolidays
  }

  if (invoiceLineItemDeduction) {
    initialValues.settings.invoiceLineItemDeduction = invoiceLineItemDeduction
  }

  if (invoiceLineItemDisplay) {
    initialValues.settings.invoiceLineItemDisplay = invoiceLineItemDisplay
  }

  initialValues.settings.stretchedWeeks = stretchedWeeks
  initialValues.settings.fundingPeriod = stretched ? FundingPeriodValues.STRETCHED : FundingPeriodValues.TERM_TIME

  initialValues.priceChanges = getPriceChangesInitialValues(priceChanges)

  return initialValues
}
