import { DAYS_OF_WEEK_LIST } from 'constants/date'
import _ from 'lodash'

import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import { PriceChange } from 'services/product/constants'

import { isNumberLessOrEqualThan, isRequired } from 'utils/fieldValidation'

import { Button, Currency, FooterActions, Form, ModalBox, Spinner } from 'components'

import i18n from 'translations'

const isNumberLessOrEqualThan99999 = isNumberLessOrEqualThan(99999)

export const ADD_REGULAR_ITEMS_TO_REGULAR_BOOKINGS_MODAL_FORM = 'AddRegularItemsToRegularBookingsModalForm'

const WEEK_DAYS = _.times(7, (index) => ({
  label: i18n.t(`global:shortDayNames:${index + 1}`),
  value: index,
}))

export interface AddRegularItemsToRegularBookingsModalFormValues {
  daysApplicable?: number[]
  item?: {
    label: string
    priceChanges?: PriceChange[]
    value: number
  }
  quantity?: number
}

export interface AddRegularItemsToRegularBookingsModalFormProps {
  isFetchingPrice: boolean
  nurseryOpeningDays?: any
  onCloseClick?: () => void
  onSubmit: (values: any) => void
  price: number | null
}

type AddRegularItemsToRegularBookingsModalFormFullProps = InjectedFormProps<
    {},
    AddRegularItemsToRegularBookingsModalFormProps
  > & AddRegularItemsToRegularBookingsModalFormProps

const AddRegularItemsToRegularBookingsModalForm: React.FC<AddRegularItemsToRegularBookingsModalFormFullProps> = ({
  handleSubmit,
  isFetchingPrice,
  nurseryOpeningDays,
  onCloseClick,
  onSubmit,
  price,
}) => {
  const renderFooter = () => (
    <FooterActions spaceBetween>
      <FooterActions.Group>
        <FooterActions.Item>
          <Button
            hierarchy="tertiary"
            label={i18n.t('global:cancel')}
            negativeMargins
            onClick={onCloseClick}
          />
        </FooterActions.Item>
      </FooterActions.Group>
      <FooterActions.Group>
        <FooterActions.Item>
          <Button
            disabled={isFetchingPrice}
            label={i18n.t('global:Save')}
            negativeMargins
            submit
          />
        </FooterActions.Item>
      </FooterActions.Group>
    </FooterActions>
  )

  const renderPrice = () => {
    if (isFetchingPrice) {
      return (
        <Spinner position="flex-start" size="small" />
      )
    }

    if (!_.isNumber(price)) {
      return '-'
    }

    return (
      <Currency value={price / 100} />
    )
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ModalBox.ScrollContent>
        <Form.Row
          label={i18n.t('modals:AddRegularItemsToRegularBookings:itemName')}
          width={{ field: '300px' }}
          required
          verticalLabel
        >
          <Field
            clearable={false}
            component={Form.InfiniteDropdowns.NurseryItemsV3}
            extraFields={['priceChanges']}
            name="item"
            placeholder={i18n.t('modals:AddRegularItemsToRegularBookings:itemName')}
            validate={isRequired}
            bodyTarget
          />
        </Form.Row>
        <Form.Row
          label={i18n.t('modals:AddRegularItemsToRegularBookings:daysApplicable')}
          required
          verticalLabel
        >
          <Field
            component={Form.ButtonGroup}
            name="daysApplicable"
            options={_.map(WEEK_DAYS, (weekDay) => ({
              ...weekDay,
              disabled: !(
                _.find(nurseryOpeningDays, ({ day }) => day === DAYS_OF_WEEK_LIST[weekDay.value])
              ),
            }))}
            validate={isRequired}
            multi
          />
        </Form.Row>
        <Form.Row
          label={i18n.t('modals:AddRegularItemsToRegularBookings:quantity')}
          width={{ field: '100px' }}
          verticalLabel
        >
          <Field
            component={Form.TextField}
            name="quantity"
            type="number"
            validate={isNumberLessOrEqualThan99999}
            withoutDecimal
          />
        </Form.Row>
        <Form.Row
          label={i18n.t('modals:AddRegularItemsToRegularBookings:pricePerDay')}
          verticalLabel
        >
          {renderPrice()}
        </Form.Row>
      </ModalBox.ScrollContent>
      {renderFooter()}
    </Form>
  )
}

export default reduxForm<{}, AddRegularItemsToRegularBookingsModalFormProps>({
  form: ADD_REGULAR_ITEMS_TO_REGULAR_BOOKINGS_MODAL_FORM,
})(AddRegularItemsToRegularBookingsModalForm)
