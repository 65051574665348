import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change, getFormValues, stopSubmit } from 'redux-form'

import { REFERENCE_ASYNC_PAGE_TYPES, UPLOADED_FILE_STATUS } from 'services/legacy/upload/constants'

import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withDailyDiaryActivitiesService } from 'services/legacy/dailyDiaryActivities'
import { withUploadService } from 'services/legacy/upload'
import { withModalService } from 'services/utils/modal'
import { withSnackbarService } from 'services/utils/snackbar'

import i18n from 'translations'

import ActivityTabView from './ActivityTabView'
import { ACTIVITY_TAB_FORM } from './components/ActivityTabForm'

class ActivityTabContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
    }
  }

  componentDidMount() {
    const { dailyDiaryActivitiesActions, dailyDiaryActivitiesSelectors, date, selectedChildren } = this.props
    const body = dailyDiaryActivitiesSelectors.getValuesForm({ children: selectedChildren, date })

    dailyDiaryActivitiesActions.addRecord(
      body,
      (activity) => {
        dailyDiaryActivitiesActions.setTemporaryActivityId(activity.id)

        this.setState({
          isLoading: false,
        })
      },
    )
  }

  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    return injectValidation(errors)
  }

  handleSubmit = async (values) => {
    const {
      activityId,
      dailyDiaryActivitiesActions,
      dailyDiaryActivitiesSelectors,
      date,
      onSuccess,
      selectedChildren,
    } = this.props

    const body = dailyDiaryActivitiesSelectors.getValuesForm({
      children: selectedChildren,
      date,
      ...values,
    })

    delete body.media

    if (activityId) {
      dailyDiaryActivitiesActions.setTemporaryActivityId(null)
      dailyDiaryActivitiesActions.updateRecord(
        activityId,
        body,
        onSuccess,
        this.handleSubmitFailed,
      )
    }
  }

  handleReloadMedia = async (newFiles) => {
    const { changeFieldValue, formValues } = this.props
    const { media } = formValues || {}
    const updatedNewFiles = _.map(newFiles, (item) => ({ ...item, itemFromBe: true }))

    changeFieldValue('media', _.uniqBy([
      ...updatedNewFiles,
      ...(media || []),
    ], ({ id }) => id))
  }

  handleCheckProcessingFiles = async (readyFiles) => {
    const { changeFieldValue, formValues } = this.props
    let { media: currentMedia } = formValues

    currentMedia = _.map(currentMedia, (item) => {
      const newFile = _.find(readyFiles, ({ id }) => id === item.id)

      if (newFile) {
        return {
          ...item,
          status: UPLOADED_FILE_STATUS.READY,
        }
      }

      return item
    })

    changeFieldValue('media', currentMedia)
  }

  handleCancel = () => {
    const { activityId, dailyDiaryActivitiesActions, getFiles, onCancel, uploadActions } = this.props
    const files = getFiles([REFERENCE_ASYNC_PAGE_TYPES.ACTIVITY, activityId])

    _.each(files, (file) => {
      uploadActions.markFileToRemove(file.id)
    })

    dailyDiaryActivitiesActions.setTemporaryActivityId(null)
    dailyDiaryActivitiesActions.removeRecord(
      activityId,
      onCancel,
    )
  }

  handleUpdateMediaSuccess = (file) => {
    const { changeFieldValue, formValues, snackbarActions } = this.props
    const { media } = formValues
    const updatedMedia = _.map(media, (element) => {
      if (file.id === element.id) {
        return {
          ...element,
          children: file.children,
          name: file.name,
        }
      }

      return element
    })

    snackbarActions.show({
      message: i18n.t('services:Files:fileDetailsHasBeenChanged'),
    })

    changeFieldValue('media', updatedMedia)
  }

  handleUpdateMedia = (file) => {
    const { activityId, dailyDiaryActivitiesActions } = this.props
    const { children, id: fileId, itemFromBe, name } = file

    if (itemFromBe) {
      const body = [{
        children: _.map(children, ({ id }) => ({ id })),
        id: fileId,
        name,
      }]

      dailyDiaryActivitiesActions.updateActivityMedia({
        body,
        onSuccess: () => this.handleUpdateMediaSuccess(file),
        params: [activityId],
      })
    }
  }

  handleRemoveFileSuccess = (file) => {
    const { changeFieldValue, formValues, snackbarActions } = this.props
    const { media } = formValues
    const updatedMedia = _.filter(media, ({ id }) => id !== file.id)

    snackbarActions.show({
      message: i18n.t('services:Files:fileRemoved'),
    })

    changeFieldValue('media', updatedMedia)
  }

  handleRemoveFile = async (file) => {
    const { activityId, dailyDiaryActivitiesActions } = this.props
    const { id } = file
    const body = [{ id }]

    dailyDiaryActivitiesActions.removeActivityMedia({
      body,
      onSuccess: () => this.handleRemoveFileSuccess(file),
      params: activityId,
    })
  }

  render() {
    const {
      activityId,
      date,
      formValues,
      getInitialValues,
      isSubmitting,
      selectedChildren,
    } = this.props
    const { isLoading } = this.state
    const { media } = formValues || {}
    const initialValues = getInitialValues({ date })

    return (
      <ActivityTabView
        activityId={activityId}
        initialValues={initialValues}
        isLoading={isLoading}
        isSubmitting={isSubmitting}
        selectedChildren={selectedChildren}
        totalResults={media?.length || 0}
        onCancel={this.handleCancel}
        onCheckProcessingFiles={this.handleCheckProcessingFiles}
        onReloadMedia={this.handleReloadMedia}
        onRemoveFile={this.handleRemoveFile}
        onSubmit={this.handleSubmit}
        onUpdateMedia={this.handleUpdateMedia}
      />
    )
  }
}

const mapDispatch = {
  changeFieldValue: (field, value) => change(ACTIVITY_TAB_FORM, field, value),
  injectValidation: (params) => stopSubmit(ACTIVITY_TAB_FORM, params),
}

const mapState = (state, {
  appSelectors,
  dailyDiaryActivitiesSelectors,
  dailyDiaryActivitiesSingleState,
  uploadSelectors,
}) => ({
  activityId: dailyDiaryActivitiesSelectors.getTemporaryActivityId(state),
  formValues: getFormValues(ACTIVITY_TAB_FORM)(state),
  getFiles: (reference) => uploadSelectors.getFilesByReference(reference)(state),
  getInitialValues: (params) => dailyDiaryActivitiesSelectors.getInitialValues(params),
  isSubmitting: appSelectors.getIsSubmitting(
    dailyDiaryActivitiesSingleState,
  ),
})

const enhance = compose(
  withAppService,
  withDailyDiaryActivitiesService,
  withModalService,
  withUploadService,
  withSnackbarService,
  connect(mapState, mapDispatch),
)

export default enhance(ActivityTabContainer)
