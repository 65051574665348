import { TableColumn } from 'components/Table'
import _ from 'lodash'

import i18n from 'translations'

export const REPORT_TABLE_COLUMNS: TableColumn[] = [
  {
    align: 'left',
    field: 'child',
    title: _.upperFirst(i18n.t('global:child')),
    width: '240px',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'codeReference',
    title: _.upperFirst(i18n.t('global:Child:childReferenceCode')),
    width: '160px',
  },
  {
    field: 'ageMonths',
    title: i18n.t('global:Age'),
    width: '12%',
  },
  {
    field: 'dateOfBirth',
    title: i18n.t('global:dateOfBirthShort'),
    width: '12%',
  },
  {
    field: 'name',
    title: i18n.t('module:Finance:Reports:Funding:Table:Header:fundingName'),
    width: '15%',
  },
  {
    field: 'type',
    title: i18n.t('module:Finance:Reports:Funding:Table:Header:fundingType'),
    width: '15%',
  },
  {
    align: 'left',
    field: 'period',
    title: i18n.t('module:Finance:Reports:Funding:Table:Header:fundingAllocationFor'),
    width: '25%',
  },
  {
    field: 'deductedFromInvoice',
    title: i18n.t('module:Finance:Reports:Funding:Table:Header:deductedFromInvoice'),
    width: '12%',
  },
  {
    align: 'left',
    field: 'fundingHours',
    title: i18n.t('module:Finance:Reports:Funding:Table:Header:totalFundingAllocated'),
    width: '18%',
  },
  {
    align: 'right',
    field: 'fundingValue',
    title: i18n.t('module:Finance:Reports:Funding:Table:Header:totalFundingValue'),
    width: '15%',
  },
  {
    field: 'fundingInvoicedHours',
    title: i18n.t('module:Finance:Reports:Funding:Table:Header:fundingInvoiced'),
    width: '15%',
  },
  {
    align: 'right',
    field: 'fundingInvoicedValue',
    title: i18n.t('module:Finance:Reports:Funding:Table:Header:totalInvoicedFundingValue'),
    width: '15%',
  },
]

export const CHILD_REPORT_TABLE_COLUMNS: TableColumn[] = [
  {
    field: 'name',
    title: i18n.t('module:Finance:Reports:Funding:Table:Header:fundingName'),
    width: '15%',
  },
  {
    field: 'type',
    title: i18n.t('module:Finance:Reports:Funding:Table:Header:fundingType'),
    width: '15%',
  },
  {
    align: 'left',
    field: 'period',
    title: i18n.t('module:Finance:Reports:Funding:Table:Header:fundingAllocationFor'),
    width: '18%',
  },
  {
    field: 'deductedFromInvoice',
    title: i18n.t('module:Finance:Reports:Funding:Table:Header:deductedFromInvoice'),
    width: '15%',
  },
  {
    align: 'left',
    field: 'fundingHours',
    title: i18n.t('module:Finance:Reports:Funding:Table:Header:totalFundingAllocated'),
    width: '15%',
  },
  {
    align: 'right',
    field: 'fundingValue',
    title: i18n.t('module:Finance:Reports:Funding:Table:Header:totalFundingValue'),
    width: '15%',
  },
  {
    field: 'fundingInvoicedHours',
    title: i18n.t('module:Finance:Reports:Funding:Table:Header:fundingInvoiced'),
    width: '15%',
  },
  {
    align: 'right',
    field: 'fundingInvoicedValue',
    title: i18n.t('module:Finance:Reports:Funding:Table:Header:totalInvoicedFundingValue'),
    width: '15%',
  },
]

export const FUNDING_ALLOCATIONS = {
  NO_FUNDING: 'no_funding',
  OVER_FUNDED: 'overfunded',
  UNDER_FUNDED: 'underfunded',
}

export const FUNDING_ALLOCATION_OPTIONS = [
  {
    label: i18n.t('module:Finance:Reports:Funding:Filters:Allocation:Options:overFunded'),
    value: FUNDING_ALLOCATIONS.OVER_FUNDED,
  },
  {
    label: i18n.t('module:Finance:Reports:Funding:Filters:Allocation:Options:underFunded'),
    value: FUNDING_ALLOCATIONS.UNDER_FUNDED,
  },
  {
    label: i18n.t('module:Finance:Reports:Funding:Filters:Allocation:Options:noFunding'),
    value: FUNDING_ALLOCATIONS.NO_FUNDING,
  },
]
