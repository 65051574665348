import _ from 'lodash'
import React from 'react'

import { PAYMENT_STATUSES_OPTIONS } from 'services/legacy/invoicePayments/constants'

import { generateRoute } from 'utils/routing'

import { Avatar, Currency, DateString, DropdownMenu, Typography } from 'components'

import i18n from 'translations'

export const getPayments = (payments, params) => {
  if (!payments) {
    return null
  }

  const {
    onPaymentDownload,
    onPaymentRemove,
    onPaymentSend,
  } = params

  const mapPayment = (payment) => {
    const { amount, child, comment, id, integrationId, number, paymentDate, paymentType, status } = payment
    const { name: paymentTypeName } = paymentType

    const fullStatus = _.find(PAYMENT_STATUSES_OPTIONS, ({ value }) => (
      value === status
    )) || {}

    return {
      amount: <Currency value={amount} highlight />,
      child: (
        <Avatar
          avatarSize="small"
          initials={[child?.firstName, child?.surname]}
          src={child?.photo}
          title={(
            <Typography fontSize={14} primary>
              {`${child?.firstName} ${child?.surname}`}
            </Typography>
          )}
          to={generateRoute('CHILDREN.CHILD.FINANCE.PAYMENT_HISTORY', { childId: child?.id })}
        />
      ),
      comment: (
        <Typography maxWidth={400} wordBreak="break-word">
          {comment}
        </Typography>
      ),
      edit: (
        <DropdownMenu small>
          <DropdownMenu.Item
            icon="payment-history"
            label={i18n.t('module:Finance:Payments:viewDetails')}
            to={generateRoute('FINANCE.PAYMENTS.DETAILS', { paymentId: id })}
          />
          <DropdownMenu.Item
            type="download"
            primary
            onClick={() => onPaymentDownload(id)}
          />
          <DropdownMenu.Item
            icon="resend-receipt"
            label={i18n.t('global:Resend')}
            primary
            onClick={() => onPaymentSend(id)}
          />
          {!integrationId && (
            <DropdownMenu.Item
              type="remove"
              onClick={() => onPaymentRemove(id)}
            />
          )}
        </DropdownMenu>
      ),
      id,
      paymentDate: <DateString date={paymentDate} />,
      paymentType: `${paymentTypeName}${integrationId ? ` (${i18n.t('module:Finance:Payments:parentApp')})` : ''}`,
      receiptNumber: number,
      status: fullStatus?.label || '-',
    }
  }

  return _.map(payments, mapPayment)
}
