import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

export const StyledTextPlaceholder = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(98, 98, 98, .6);
  text-align: center;
  line-height: 13px;
  padding: 0 10px;
  transition: .2s;
  opacity: 0;
`

interface StyledUploadButtonProps {
  disabled?: boolean
}

export const StyledUploadButton = styled.div<StyledUploadButtonProps>`
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  
  &:hover {
    ${StyledTextPlaceholder} {
      opacity: 1;

      ${({ $disabled }) => $disabled && `
        opacity: 0;
      `}
    }
  }
`

export const StyledInputFile = styled.input`
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
`

export const StyledBadge = styled.div`
  border-radius: 50%;
  border: 3px solid ${NEUTRAL_COLOURS.WHITE};
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: ${NEUTRAL_COLOURS.GRAY};
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledButtonContainer = styled.div`
  position: relative;
`
