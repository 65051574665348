import { Range } from 'constants/types'
import { BasicSingleInitialState } from 'utils/reducer'
import { PriceChange } from 'services/product/constants'

import { createTypeFromEnumValues } from 'utils/typescript'

import i18n from 'translations'

export enum NurseryFundingProductType {
  ABSTRACT_NURSERY_ONE_OFF_FUNDING = 'abstract_nursery_one_off_funding',
  NURSERY_ALLOCATED_FUNDING = 'nursery_allocated_funding',
  NURSERY_FUNDING = 'nursery_funding',
  NURSERY_NO_DEDUCT_ALLOCATED_FUNDING = 'nursery_no_deduct_allocated_funding',
  NURSERY_NO_DEDUCT_ONE_OFF_FUNDING = 'nursery_no_deduct_one_off_funding',
  NURSERY_NO_DEDUCT_SUBTRACT_FUNDING = 'nursery_no_deduct_subtract_funding',
  NURSERY_ONE_OFF_FUNDING = 'nursery_one_off_funding',
  NURSERY_REGULAR_FUNDING = 'nursery_regular_funding',
  NURSERY_SUBTRACT_FUNDING = 'nursery_subtract_funding'
}

export interface NurseryFundingProductSettings {
  bookingPlanEndsMidWeek?: createTypeFromEnumValues<FundingRedistributionRulesValues>
  closureDaysSessionChargeable?: createTypeFromEnumValues<FundingRedistributionRulesValues>
  closureDaysSessionNotChargeable?: createTypeFromEnumValues<FundingRedistributionRulesValues>
  deficitCharged?: boolean
  deficitLineItemName?: string
  fundingPeriod?: string
  hoursPerWeek?: Range<1, 23>
  invoiceLineItemDeduction?: string
  invoiceLineItemDisplay?: string
  maxHours?: Range<1, 23>
  maxMinutes?: Range<0, 59>
  minutesPerWeek?: Range<0, 59>
  showItemOnInvoice?: boolean
  stretched?: boolean
  stretchedWeeks?: number
  termTimeHolidays?: createTypeFromEnumValues<FundingRedistributionRulesValues>
  total?: number
}

export interface NurseryFundingProduct {
  archived?: boolean
  archivedAt?: Date
  name?: string
  priceChanges?: PriceChange[]
  settings?: NurseryFundingProductSettings
  type?: createTypeFromEnumValues<NurseryFundingProductType>
}

export interface NurseryFundingV3ListState extends BasicSingleInitialState {
  data: NurseryFundingProduct[]
}

export interface NurseryFundingV3SingleState extends BasicSingleInitialState {
  data: NurseryFundingProduct
}

export interface NurseryFundingV3State {
  list: NurseryFundingV3ListState
  single: NurseryFundingV3SingleState
}

export interface NurseryFundingV3RootState {
  nurseryFundingV3: NurseryFundingV3State
}

export const FUNDING_STATUS_FILTERS = {
  ACTIVE: 'ACTIVE',
  ALL: 'ALL',
  ARCHIVED: 'ARCHIVED',
}

export const FUNDING_FILTER_OPTIONS = [
  {
    label: i18n.t('global:Active'),
    value: FUNDING_STATUS_FILTERS.ACTIVE,
  },
  {
    label: i18n.t('global:All'),
    value: FUNDING_STATUS_FILTERS.ALL,
  },
  {
    label: i18n.t('global:Archived'),
    value: FUNDING_STATUS_FILTERS.ARCHIVED,
  },
]

export enum FundingTypeValues {
  ONE_OFF = 'one-off',
  ONE_OFF_LEGACY = 'oneOff', // API return for Funding report one off fundings as "oneOff"
  REGULAR = 'regular',
}

export const FUNDING_TYPE_OPTIONS = [
  {
    label: i18n.t('services:NurseryFundingV3:fundingType:regularFunding'),
    value: FundingTypeValues.REGULAR,
  },
  {
    label: i18n.t('services:NurseryFundingV3:fundingType:oneOffFunding'),
    value: FundingTypeValues.ONE_OFF,
  },
]

export enum FundingPeriodValues {
  STRETCHED = 'stretched',
  TERM_TIME = 'term-time'
}

export const FUNDING_LOOP_USAGE_OPTIONS = [
  {
    label: i18n.t('services:NurseryFundingV3:FundingPeriod:termTime'),
    value: FundingPeriodValues.TERM_TIME,
  },
  {
    label: i18n.t('services:NurseryFundingV3:FundingPeriod:stretched'),
    value: FundingPeriodValues.STRETCHED,
  },
]

export enum FundingAllocationValues {
  ALLOCATE_TO_SESSIONS = 'allocateToSessions',
  SUBTRACT_FUNDING_RATE = 'subtractFundingRate',
}

export const FUNDING_ALLOCATION_OPTIONS = [
  {
    label: i18n.t('services:NurseryFundingV3:allocationOptions:allocateToSessions'),
    value: FundingAllocationValues.ALLOCATE_TO_SESSIONS,
  },
  {
    label: i18n.t('services:NurseryFundingV3:allocationOptions:subtractFundingRate'),
    value: FundingAllocationValues.SUBTRACT_FUNDING_RATE,
  },
]

export enum FundingRedistributionRulesValues {
  APPLY = 'apply',
  NOT_APPLY = 'not_apply',
  OTHER_DAYS = 'other_days'
}

export const FUNDING_REDISTRIBUTION_RULES_OPTIONS_WITHOUT_APPLY = [
  {
    label: i18n.t('services:NurseryFundingV3:fundingRedistributionRules:notApply'),
    value: FundingRedistributionRulesValues.NOT_APPLY,
  },
  {
    label: i18n.t('services:NurseryFundingV3:fundingRedistributionRules:otherDays'),
    value: FundingRedistributionRulesValues.OTHER_DAYS,
  },
]

export const FUNDING_REDISTRIBUTION_RULES_OPTIONS = [
  {
    label: i18n.t('services:NurseryFundingV3:fundingRedistributionRules:apply'),
    value: FundingRedistributionRulesValues.APPLY,
  },
  ...FUNDING_REDISTRIBUTION_RULES_OPTIONS_WITHOUT_APPLY,
]

export const FUNDING_INVOICE_DEDUCTION_TYPES_V3 = {
  INVOICE_TOTAL: 'invoice_total',
  LINE_ITEM: 'line_item',
}

export const FUNDING_INVOICE_DEDUCTION_OPTIONS_V3 = [
  {
    label: i18n.t('services:NurseryFundingV3:FundingInvoiceDeduction:DeductedFromInvoiceTotal:label'),
    tooltip: i18n.t('services:NurseryFundingV3:FundingInvoiceDeduction:DeductedFromInvoiceTotal:tooltip'),
    value: FUNDING_INVOICE_DEDUCTION_TYPES_V3.INVOICE_TOTAL,
  },
  {
    label: i18n.t('services:NurseryFundingV3:FundingInvoiceDeduction:DeductedFromSession:label'),
    tooltip: i18n.t('services:NurseryFundingV3:FundingInvoiceDeduction:DeductedFromSession:tooltip'),
    value: FUNDING_INVOICE_DEDUCTION_TYPES_V3.LINE_ITEM,
  },
]

export const FUNDING_LINE_ITEM_TYPES_V3 = {
  HIDE_ENTIRE_LINE_ITEM: 'hide_entire_line_item',
  HIDE_FUNDED_HOURS: 'hide_funded_hours',
  SHOW_FUNDED_HOURS: 'show_funded_hours',
  SHOW_FUNDED_VALUE: 'show_funded_value',
}

export const FUNDING_LINE_ITEM_OPTIONS_ALLOCATE_FUNDING_V3 = [
  {
    label: i18n.t('services:NurseryFundingV3:FundingLineItem:ShowHours.label'),
    tooltip: i18n.t('services:NurseryFundingV3:FundingLineItem:ShowHours.tooltip'),
    value: FUNDING_LINE_ITEM_TYPES_V3.SHOW_FUNDED_HOURS,
  },
  {
    label: i18n.t('services:NurseryFundingV3:FundingLineItem:HideHours.label'),
    tooltip: i18n.t('services:NurseryFundingV3:FundingLineItem:HideHours.tooltip'),
    value: FUNDING_LINE_ITEM_TYPES_V3.HIDE_FUNDED_HOURS,
  },
  {
    label: i18n.t('services:NurseryFundingV3:FundingLineItem:DoNotShow.label'),
    tooltip: i18n.t('services:NurseryFundingV3:FundingLineItem:DoNotShow.tooltip'),
    value: FUNDING_LINE_ITEM_TYPES_V3.HIDE_ENTIRE_LINE_ITEM,
  },
]

export const FUNDING_LINE_ITEM_OPTIONS_SUBTRACT_FUNDING_V3 = [
  {
    label: i18n.t('services:NurseryFundingV3:FundingLineItem:ShowHours.label'),
    tooltip: i18n.t('services:NurseryFundingV3:FundingLineItem:ShowHours.tooltip'),
    value: FUNDING_LINE_ITEM_TYPES_V3.SHOW_FUNDED_HOURS,
  },
  {
    label: i18n.t('services:NurseryFundingV3:FundingLineItem:ShowValue.label'),
    tooltip: i18n.t('services:NurseryFundingV3:FundingLineItem:ShowValue.tooltip'),
    value: FUNDING_LINE_ITEM_TYPES_V3.SHOW_FUNDED_VALUE,
  },
  {
    label: i18n.t('services:NurseryFundingV3:FundingLineItem:HideValue.label'),
    tooltip: i18n.t('services:NurseryFundingV3:FundingLineItem:HideValue.tooltip'),
    value: FUNDING_LINE_ITEM_TYPES_V3.HIDE_FUNDED_HOURS,
  },
  {
    label: i18n.t('services:NurseryFundingV3:FundingLineItem:DoNotShow.label'),
    tooltip: i18n.t('services:NurseryFundingV3:FundingLineItem:DoNotShow.tooltip'),
    value: FUNDING_LINE_ITEM_TYPES_V3.HIDE_ENTIRE_LINE_ITEM,
  },
]
