import _ from 'lodash'
import moment from 'moment'

import React, { useMemo } from 'react'
import { Field } from 'redux-form'

import { ChildProductTypes } from 'services/booking/childProducts/constants'
import { ChildProductRegularSession } from 'services/booking/childBooking/constants'
import { Option } from 'constants/models'

import { isRequired } from 'utils/fieldValidation'

import { filterAndGroupByWeek } from 'services/booking/childBooking/single/selectors'

import {
  Banner,
  Button,
  Currency,
  Form,
  Grid,
  Hr,
  Section,
  Select,
  Space,
  Table,
  TimeString,
  Tooltip,
  Typography,
} from 'components'

import i18n from 'translations'

import { removeTimes } from 'utils/date'
import Invoicing from '../Invoicing'
import { RegularBookingsAddFormValues } from '../../RegularBookingsAddForm'
import { StyledUntilChildLeavesWrapper } from './SessionAndRegularItemsStyled'

interface SessionAndRegularItemsProps {
  childLeavingDate: Date
  formValues: RegularBookingsAddFormValues
  isSubmitting: boolean
  onAddRegularItem: (index: number) => void
  onAddSession: (index: number) => void
  onChangeAlternateWeeks: (option: Option) => void
  onChangeUntilChildLeaves: (value: boolean) => void
  onRecalculateAllocations: () => void
  onRemoveChildProduct: (id: string) => void
}

const SessionAndRegularItems: React.FC<SessionAndRegularItemsProps> = ({
  childLeavingDate,
  formValues,
  isSubmitting,
  onAddRegularItem,
  onAddSession,
  onChangeAlternateWeeks,
  onChangeUntilChildLeaves,
  onRecalculateAllocations,
  onRemoveChildProduct,
}) => {
  const { childProducts } = formValues || {}

  const renderTimes = (item: ChildProductRegularSession) => {
    const { endTime: manualEndTime, product, startTime: manualStartTime } = item
    const { endTime, hourly, startTime } = product

    return (
      <div>
        <TimeString date={hourly ? manualStartTime : startTime} local={false} />
        {' - '}
        <TimeString date={hourly ? manualEndTime : endTime} local={false} />
      </div>
    )
  }

  const checkDateIsAvailable = useMemo(() => (iterationDate) => (
    !moment(removeTimes(iterationDate)).isSameOrBefore(moment(removeTimes(childLeavingDate)), 'days')
  ), [childLeavingDate])

  const renderPrices = (item) => {
    const { _extra } = item
    const { price } = _extra

    return <Currency value={price / 100} />
  }

  const renderDetails = (item) => {
    if (item.type === ChildProductTypes.REGULAR_SESSION) {
      return renderTimes(item)
    }

    if (item.type === ChildProductTypes.REGULAR_ITEM) {
      const { settings: { quantity } } = item

      return `${quantity}x`
    }

    return null
  }

  const renderTable = (alternate: number) => {
    const itemsGroupedByWeek = filterAndGroupByWeek({
      alternate,
      childProducts: formValues?.childProducts,
      types: [
        ChildProductTypes.REGULAR_ITEM,
        ChildProductTypes.REGULAR_SESSION,
      ],
    })

    return (
      <div>
        {_.map(itemsGroupedByWeek, (items, dayIndex) => (
          <Table key={`${alternate}_${dayIndex}`} margin="20px 0" minWidth={600} visualType="transparent" disableSticky>
            <Table.Thead>
              <Table.Tr>
                <Table.Th align="left" colSpan={4}>
                  {i18n.t(`global:DayNames:${+dayIndex + 1}`)}
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {_.map(items, (item) => (
                <Table.Tr key={item.id}>
                  <Table.Td align="left" width="40%">
                    {item.product.name}
                  </Table.Td>
                  <Table.Td align="left" width="20%">
                    {renderDetails(item)}
                  </Table.Td>
                  <Table.Td width="20%">
                    {renderPrices(item)}
                  </Table.Td>
                  <Table.Td width="10%">
                    <Button
                      disabled={isSubmitting}
                      hierarchy="secondary"
                      icon="trash"
                      size="small"
                      negativeMargins
                      onClick={() => onRemoveChildProduct(item.id)}
                    />
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        ))}
      </div>
    )
  }

  const renderGeneralSection = () => (
    <Section title={i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:generalInformation')}>
      <Grid>
        <Grid.Item width={{ desktop: '180px', mobile: '100%' }}>
          <Form.Row
            label={_.upperFirst(i18n.t('global:startDate'))}
            margin="0"
            width={{ field: '100%' }}
            required
            verticalLabel
          >
            <Field
              component={Form.DatePicker}
              disabled={isSubmitting}
              name="startDate"
              placeholder={_.upperFirst(i18n.t('global:startDate'))}
              validate={[isRequired]}
              onChange={onRecalculateAllocations}
            />
          </Form.Row>
        </Grid.Item>
        <Grid.Item width={{ desktop: '180px', mobile: '100%' }}>
          <Form.Row
            label={_.upperFirst(i18n.t('global:endDate'))}
            margin="0"
            required={!formValues?.untilChildLeaves}
            width={{ field: '100%' }}
            verticalLabel
          >
            <Field
              component={Form.DatePicker}
              disabled={formValues?.untilChildLeaves}
              disabledDays={checkDateIsAvailable}
              name="endDate"
              placeholder={_.upperFirst(i18n.t('global:endDate'))}
              validate={!formValues?.untilChildLeaves ? [isRequired] : []}
              onChange={onRecalculateAllocations}
            />
          </Form.Row>
        </Grid.Item>
        <Grid.Item width={{ desktop: '180px', mobile: '100%' }}>
          <Form.Row
            margin="0"
            width={{ field: '100%' }}
            label
            verticalLabel
          >
            <StyledUntilChildLeavesWrapper>
              <Field
                component={Form.Checkbox}
                disabled={isSubmitting}
                label={i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:untilChildLeaves')}
                name="untilChildLeaves"
                // @ts-ignore
                onChange={onChangeUntilChildLeaves}
              />
            </StyledUntilChildLeavesWrapper>
          </Form.Row>
        </Grid.Item>
        <Grid.Item width={{ desktop: '260px', mobile: '100%' }}>
          <Form.Row
            label={i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:attendanceSchedule')}
            margin="0"
            width={{ field: '100%' }}
            required
            verticalLabel
          >
            <Field
              component={Form.InfiniteDropdowns.Periods}
              disabled={isSubmitting}
              name="attendancePeriod"
              placeholder={i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:attendanceSchedule')}
              validate={[isRequired]}
              onChange={onRecalculateAllocations}
            />
          </Form.Row>
        </Grid.Item>
        <Grid.Item width={{ desktop: '460px', mobile: '100%' }}>
          <Form.Row
            margin="0"
            width={{ field: '100%' }}
            label
            verticalLabel
          >
            <Grid>
              <Grid.Item alignItems={{ desktop: 'center' }}>
                <Typography>
                  {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:alternateEvery')}
                </Typography>
              </Grid.Item>
              <Grid.Item alignItems={{ desktop: 'center' }} width={{ desktop: '120px' }}>
                <Select
                  clearable={false}
                  disabled={isSubmitting}
                  options={_.times(6, (i) => ({ label: i + 1, value: i + 1 }))}
                  value={formValues.alternates}
                  width={110}
                  onChange={onChangeAlternateWeeks}
                />
              </Grid.Item>
              <Grid.Item alignItems={{ desktop: 'center' }} width={{ desktop: '60px' }}>
                <Typography>
                  {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:weeks')}
                </Typography>
              </Grid.Item>
            </Grid>
            <Grid>
              <Grid.Item>
                <Field
                  component={Form.Error}
                  name="alternates"
                />
              </Grid.Item>
            </Grid>
          </Form.Row>
        </Grid.Item>
      </Grid>
      {1 < formValues?.alternates && (
        <Banner.Warning>
          {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:moreAlternatingWeeksInfo')}
        </Banner.Warning>
      )}
      <Space space="40px" />
    </Section>
  )

  const renderWeeklyTotal = (alternate) => {
    const summary = _.sumBy(
      _.filter(childProducts, (item) => (
        item.alternate === alternate && (
          item.type === ChildProductTypes.REGULAR_SESSION
          || item.type === ChildProductTypes.REGULAR_ITEM
        )
      )),
      (item) => item._extra.price / 100,
    )

    return <Currency value={summary} />
  }

  const renderWeekFooter = (alternate) => (
    <Grid>
      <Grid.Item flex={{ desktop: 1 }} justifyContent={{ mobile: 'center' }} width={{ mobile: '100%' }}>
        <Tooltip
          placement="top"
          title={!formValues.startDate && (
            i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:youMustAsFirstSetStartDate')
          )}
        >
          <Button
            disabled={isSubmitting}
            hierarchy="secondary"
            label={i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:addSessions')}
            onClick={() => !isSubmitting && formValues.startDate && onAddSession(alternate)}
          />
        </Tooltip>
        <Space padding="0 10px" inline />
        <Tooltip
          placement="top"
          title={!formValues.startDate && (
            i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:youMustAsFirstSetStartDate')
          )}
        >
          <Button
            disabled={isSubmitting}
            hierarchy="tertiary"
            label={i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:addRegularItems')}
            onClick={() => !isSubmitting && formValues.startDate && onAddRegularItem(alternate)}
          />
        </Tooltip>
      </Grid.Item>
      <Grid.Item
        alignItems={{ desktop: 'center' }}
        textAlign={{ desktop: 'right', mobile: 'center' }}
        width={{ mobile: '100%' }}
      >
        <Typography bold>
          {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:weeklyTotal')}
          {': '}
          {renderWeeklyTotal(alternate)}
        </Typography>
      </Grid.Item>
    </Grid>
  )

  const renderInvoicingSection = () => (
    <Section title={i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:invoicing')}>
      <Invoicing
        formValues={formValues}
        isSubmitting={isSubmitting}
      />
    </Section>
  )

  const renderWeeksSection = () => (
    <Section title={i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:regularSessionsAndRegularItems')}>
      {_.times(formValues?.alternates, (alternate) => (
        <div key={`week_${alternate}`}>
          {alternate ? <Hr margin="40px 0" /> : null}
          <Typography bold>
            {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:alternateWeek')}
            {' '}
            {alternate + 1}
          </Typography>
          <Space margin="20px 0" />
          {renderTable(alternate + 1)}
          <Space margin="20px 0" />
          {renderWeekFooter(alternate + 1)}
        </div>
      ))}
    </Section>
  )

  return (
    <React.Fragment>
      {renderGeneralSection()}
      {renderInvoicingSection()}
      {renderWeeksSection()}
    </React.Fragment>
  )
}

export default SessionAndRegularItems
