import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change, getFormSubmitErrors, getFormSyncErrors, getFormValues, isDirty, reset, stopSubmit } from 'redux-form'

import { UPLOAD_DIRECTORY } from 'services/legacy/upload/constants'
import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from 'constants/locale'
import { INDEPENDENT_NURSERY_OPTION } from 'services/nurseries/constants'
import { FEATURE_FLAGS, ROLES } from 'constants/security'

import auth from 'utils/auth'
import { getSubdomainFromName, getUsernameFromName } from 'utils/form'
import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withMembershipsService } from 'services/legacy/memberships'
import { withNurseriesService } from 'services/nurseries'
import { withSecurityService } from 'services/security'
import { withSubdomainService } from 'services/subdomain'
import { withUploadService } from 'services/legacy/upload'
import { withOrganizationService } from 'services/organization'
import { withSupportedCurrencyService } from 'services/legacy/supportedCurrency'
import { withSupportedLocaleService } from 'services/legacy/supportedLocale'
import { withRouter } from 'services/router'

import i18n from 'translations'

import NurseriesAddView from './NurseriesAddView'
import { NURSERIES_ADD_FORM } from './constants'

const MEMBERSHIP_GROUPS = {
  read: ['membership.profile', 'membership.profile.dbsCheck'],
}

const ORGANIZATION_GROUPS = {
  read: [
    'organization.organizationSettings',
    'organizationSettings',
    'organizationSettings.localeDetails',
  ],
}

class NurseriesAddContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentStage: 1,
      highestAvailableStage: 1,
      imageUploading: false,
      initialValues: {
        nursery: {
          organization: INDEPENDENT_NURSERY_OPTION,
        },
      },
      initialValuesIsFetching: true,
    }
  }

  componentDidMount() {
    const {
      authOrganization,
      isOrganizationContext,
      location,
      organizationActions,
      router,
      supportedCurrencyActions,
      supportedLocaleActions,
    } = this.props
    const { query: { organisation } } = location

    if (organisation) {
      const [organisationId, organisationSuffix, organisationName] = organisation.split(',')

      router.replace({
        pathname: location.pathname,
        query: '',
      })

      this.setState({
        initialValues: {
          nursery: {
            organization: {
              label: organisationName,
              subdomainSuffix: organisationSuffix,
              value: +organisationId,
            },
          },
        },
      })
    }

    this.setState({
      initialValuesIsFetching: false,
    })

    if (isOrganizationContext) {
      organizationActions.get(authOrganization.id, {
        params: { groups: ORGANIZATION_GROUPS },
      })
    }

    supportedCurrencyActions.list({})
    supportedLocaleActions.list({})
  }

  componentWillUnmount() {
    const { membershipsActions, resetForm, supportedCurrencyActions, supportedLocaleActions } = this.props

    membershipsActions.clear()
    supportedCurrencyActions.clear()
    supportedLocaleActions.clear()
    resetForm()
  }

  nextStage = () => {
    const { currentStage, highestAvailableStage } = this.state

    this.setState({ highestAvailableStage: highestAvailableStage === currentStage
      ? currentStage + 1
      : highestAvailableStage,
    })

    this.setState({ currentStage: currentStage + 1 })
  }

  previousStage = () => {
    const { currentStage } = this.state

    this.setState({ currentStage: currentStage - 1 })
  }

  handleNurserySubmit = async ({ nursery = {} }) => {
    const { authOrganization, changeField, nurseriesActions, nurseriesSelectors } = this.props

    if (false === nursery?.logo?.isUploaded) {
      const logo = await this.uploadFile(nursery.logo.file, UPLOAD_DIRECTORY.PUBLIC_IMAGES)

      changeField('nursery.logo', {
        isUploaded: true,
        value: logo,
      })
    }

    let subdomain = nursery.subdomain
    subdomain += authOrganization?.subdomainSuffix || ''

    const subdomainValidationPromise = nurseriesActions.list({
      params: { criteria: nurseriesSelectors.getCriteria({ subdomain }) },
    })

    const nurseryNameValidationPromise = nurseriesActions.list({
      params: { criteria: nurseriesSelectors.getCriteria({ nurseryName: nursery.name }) },
    })

    Promise.all([subdomainValidationPromise, nurseryNameValidationPromise]).then((values) => {
      const [nurseriesWithSubdomain, nurseriesWithName] = values

      const { data } = nurseriesWithName
      const nurseryWithName = _.some(data, ['name', nursery.name])

      if (
        !this.isNurseryExist('nurseryName', { data: nurseryWithName ? [nurseryWithName] : null })
        && !this.isNurseryExist('subdomain', nurseriesWithSubdomain)
      ) {
        this.nextStage()
      }
    })
  }

  isNurseryExist = (type = 'subdomain', { data }) => {
    const { injectValidation } = this.props

    if (data?.length) {
      const errors = {}

      if ('subdomain' === type) {
        errors.subdomain = i18n.t('module:Nurseries:Add:NurseryDetails:Form:Subdomain:error')
      }

      if ('nurseryName' === type) {
        errors.name = i18n.t('module:Nurseries:Add:NurseryDetails:Form:Name:error')
      }

      return injectValidation({ nursery: { ...errors } })
    }

    return false
  }

  handlePrimaryContactSubmit = () => {
    this.nextStage()
  }

  uploadFile = async (file, directory) => {
    const { uploadActions } = this.props

    this.setState({ imageUploading: true })

    const url = await uploadActions.uploadFile(file, undefined, directory)

    this.setState({ imageUploading: false })

    return url
  }

  handleSubmit = async (fields) => {
    const {
      authOrganization,
      changeField,
      isAdministrationContext,
      isOrganizationContext,
      navigate,
      nurseriesActions,
      nurseriesSelectors,
    } = this.props

    const subdomainSuffix = authOrganization?.subdomainSuffix || ''
    const payload = nurseriesSelectors.getCreateNurseryPayload({
      formValues: fields,
      isAdministrationContext,
      isOrganizationContext,
      subdomainSuffix,
    })

    if (false === payload?.logo?.isUploaded) {
      payload.logo = await this.uploadFile(payload.logo.file, UPLOAD_DIRECTORY.PUBLIC_IMAGES)

      changeField('nursery.logo', {
        isUploaded: true,
        value: payload.logo,
      })
    } else {
      payload.logo = payload?.logo?.value
    }

    if (false === payload?.primaryContact?.photo?.isUploaded) {
      payload.primaryContact.photo = await this.uploadFile(payload.primaryContact.photo.file)

      changeField('newPrimaryContact.photo', {
        isUploaded: true,
        value: payload.primaryContact.photo,
      })
    } else {
      payload.primaryContact.photo = payload?.primaryContact?.photo?.value
    }

    nurseriesActions.create({
      onSuccess: ({ data }) => { navigate(generateRoute('NURSERIES.DETAILS', { nurseryId: data.id })) },
      payload,
    })
  }

  handleExistingPrimaryContactSelect = (existingPrimaryContact) => {
    const { changeField, membershipsActions } = this.props

    changeField('newPrimaryContact', null)

    if (existingPrimaryContact?.value) {
      return membershipsActions.get(existingPrimaryContact.value, {
        params: { groups: MEMBERSHIP_GROUPS },
      })
    }

    return null
  }

  handleNurseryNameChange = (e) => {
    const { changeField, formValues, isSubdomainDirty } = this.props

    const { nursery } = formValues || {}

    const { name = '', subdomain = '' } = nursery || {}

    if (!isSubdomainDirty || getSubdomainFromName(name) === subdomain) {
      changeField('nursery.subdomain', getSubdomainFromName(e.target.value))
    }
  }

  handlePrimaryContactNameChange = (fieldName, e) => {
    const { changeField, formValues, isUsernameDirty } = this.props

    const { newPrimaryContact } = formValues || {}

    const { firstName = '', surname = '', username = '' } = newPrimaryContact || {}

    if (!isUsernameDirty || getUsernameFromName(firstName + surname) === username) {
      const name = 'surname' === fieldName ? firstName + e.target.value : e.target.value + surname

      changeField('newPrimaryContact.username', getUsernameFromName(name))
    }
  }

  handleStageChange = (stage) => {
    const { formSyncErrors, injectValidation } = this.props
    const { currentStage, highestAvailableStage } = this.state

    if (stage < currentStage) {
      return this.setState({ currentStage: stage })
    }

    if (stage <= highestAvailableStage) {
      if (!_.isEmpty(formSyncErrors)) {
        return injectValidation(formSyncErrors)
      }

      return this.setState({ currentStage: stage })
    }

    return null
  }

  render() {
    const {
      appProperties,
      authAccessMap: { section: { StaffingEnabled } },
      authOrganization,
      domainSuffix,
      errorMessages,
      formValues,
      isAdministrationContext,
      isFetching,
      isOrganizationContext,
      isSubmitting,
      membership,
      organizationSettings,
      supportedCurrency,
      supportedLocale,
      titlesOptions,
    } = this.props
    const { currentStage, highestAvailableStage, imageUploading, initialValues, initialValuesIsFetching } = this.state

    const organizationSuffix = formValues?.nursery?.organization?.subdomainSuffix
      || authOrganization?.subdomainSuffix
      || ''
    const prefix = appProperties.nurseryDomainPrefix
    const suffix = organizationSuffix + domainSuffix

    const finalInitialValues = { ...initialValues }

    finalInitialValues.nursery.nurserySettings = {
      ...finalInitialValues.nursery.nurserySettings,
      currency: _.find(supportedCurrency, ({ value }) => value === DEFAULT_CURRENCY),
      locale: _.find(supportedLocale, ({ value }) => value === DEFAULT_LOCALE),
    }

    if (isOrganizationContext) {
      finalInitialValues.nursery.nurserySettings = {
        ...finalInitialValues.nursery.nurserySettings,
        currency: organizationSettings?.currency,
        locale: organizationSettings?.locale,
      }
    }

    return (
      <NurseriesAddView
        currentStage={currentStage}
        errorMessages={errorMessages}
        formValues={formValues}
        highestAvailableStage={highestAvailableStage}
        initialValues={finalInitialValues}
        isAdministrationContext={isAdministrationContext}
        isFetching={isFetching || initialValuesIsFetching}
        isOrganizationContext={isOrganizationContext}
        isStaffingEnabled={StaffingEnabled}
        isSubmitting={isSubmitting || imageUploading}
        membership={membership}
        organizationSuffix={organizationSuffix}
        prefix={prefix}
        suffix={suffix}
        titlesOptions={titlesOptions}
        onExistingPrimaryContactSelect={this.handleExistingPrimaryContactSelect}
        onNurseryNameChange={this.handleNurseryNameChange}
        onNurserySubmit={this.handleNurserySubmit}
        onPreviousStageClick={this.previousStage}
        onPrimaryContactNameChange={this.handlePrimaryContactNameChange}
        onPrimaryContactSubmit={this.handlePrimaryContactSubmit}
        onStageChange={this.handleStageChange}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

NurseriesAddContainer.authParams = {
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
  ],
}

const mapState = (state, {
  appSelectors,
  membershipsSelectors,
  nurseriesSingleState,
  organizationSelectors,
  organizationSingleState,
  securitySelectors,
  subdomainSelectors,
  supportedCurrencySelectors,
  supportedLocaleSelectors,
}) => ({
  appProperties: appSelectors.getApplicationProperties(state),
  authAccessMap: {
    section: {
      StaffingEnabled: auth.SELECTORS.getIsAuthorised(state, {
        flags: [FEATURE_FLAGS.STAFF_REGISTER],
      }),
    },
  },
  authOrganization: securitySelectors.getAuthOrganization(state),
  domainSuffix: subdomainSelectors.findDomainSuffix(),
  errorMessages: appSelectors.getErrorMessages(nurseriesSingleState),
  formSubmitErrors: getFormSubmitErrors(NURSERIES_ADD_FORM)(state),
  formSyncErrors: getFormSyncErrors(NURSERIES_ADD_FORM)(state),
  formValues: getFormValues(NURSERIES_ADD_FORM)(state),
  isAdministrationContext: securitySelectors.isAdministrationContext(state),
  isFetching: appSelectors.getIsFetching(organizationSingleState),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
  isSubdomainDirty: isDirty(NURSERIES_ADD_FORM)(state, ['nursery.subdomain']),
  isSubmitting: appSelectors.getIsSubmitting(nurseriesSingleState),
  isUsernameDirty: isDirty(NURSERIES_ADD_FORM)(state, ['newPrimaryContact.username']),
  membership: membershipsSelectors.getMembershipDataSelector(state),
  organizationSettings: organizationSelectors.getOrganizationSettings(state),
  supportedCurrency: supportedCurrencySelectors.getSupportedCurrencyListOptions(state),
  supportedLocale: supportedLocaleSelectors.getSupportedLocaleListOptions(state),
  titlesOptions: appSelectors.getUserTitlesOptions(state),
})

const mapDispatch = {
  changeField: (field, value) => change(NURSERIES_ADD_FORM, field, value),
  injectValidation: (errors) => stopSubmit(NURSERIES_ADD_FORM, errors),
  resetForm: () => reset(NURSERIES_ADD_FORM),
}

const enhance = compose(
  withAppService,
  withMembershipsService,
  withNurseriesService,
  withOrganizationService,
  withSecurityService,
  withSubdomainService,
  withSupportedCurrencyService,
  withSupportedLocaleService,
  withUploadService,
  withRouter,
  connect(mapState, mapDispatch),
)

export default enhance(NurseriesAddContainer)
