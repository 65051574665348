import _ from 'lodash'

import React from 'react'

import {
  FUNDING_FILTER_OPTIONS,
  NurseryFundingProduct,
  NurseryFundingProductType,
} from 'services/product/nurseryFundingV3/constants'
import { PaginationDetails } from 'services/utils/pagination/constants'

import { generateRoute } from 'utils/routing'

import { getCurrentPriceFromPriceChanges } from 'services/product/selectors'

import {
  Button,
  Callout,
  Currency,
  EmptyState,
  Page,
  Pagination,
  Section,
  Select,
  Spinner,
  Table,
  Toolbar,
} from 'components'

import i18n from 'translations'

import { ManagementFundingListV3Filters } from './ManagementFundingListV3Container'

const COLUMNS = [
  {
    align: 'left',
    alignTh: 'left',
    field: 'name',
    title: i18n.t('module:Management:Finance:Funding:List:fundingName'),
    width: '320px',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'type',
    title: i18n.t('module:Management:Finance:Funding:List:type'),
    width: '200px',
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'invoice',
    title: i18n.t('module:Management:Finance:Funding:List:invoice'),
    width: '120px',
  },
  {
    field: 'hourlyRate',
    title: i18n.t('module:Management:Finance:Funding:List:hourlyRate'),
    width: '120px',
  },
  {
    field: 'weeklyHours',
    title: i18n.t('module:Management:Finance:Funding:List:weeklyHours'),
    width: '140px',
  },
  {
    field: 'fundingValue',
    title: i18n.t('module:Management:Finance:Funding:List:fundingValue'),
    width: '120px',
  },
  {
    align: 'center',
    field: 'action',
  },
]

interface ManagementFundingListV2ViewProps {
  errorMessages: string[]
  filters: ManagementFundingListV3Filters
  isFetching: boolean
  onFilterChange: <
    K extends keyof ManagementFundingListV3Filters
  >(key: K) => (value: ManagementFundingListV3Filters[K]) => void
  pagination: PaginationDetails
  records: NurseryFundingProduct[]
}

const ManagementFundingListV2View: React.FC<ManagementFundingListV2ViewProps> = ({
  errorMessages,
  filters,
  isFetching,
  onFilterChange,
  pagination,
  records,
}) => {
  const renderPagination = () => !isFetching && (
    <Pagination {...pagination} />
  )

  const renderRecord = (record) => {
    const { archived, name, priceChanges, settings, type } = record
    const { hoursPerWeek, minutesPerWeek, total } = settings

    const price = getCurrentPriceFromPriceChanges(priceChanges)
    let finalType = i18n.t('module:Management:Finance:Funding:List:regularFunding')
    let invoice = i18n.t('module:Management:Finance:Funding:List:deducted')

    if (
      type === NurseryFundingProductType.NURSERY_ONE_OFF_FUNDING
      || type === NurseryFundingProductType.NURSERY_NO_DEDUCT_ONE_OFF_FUNDING
    ) {
      finalType = i18n.t('module:Management:Finance:Funding:List:oneOffFunding')
    }

    if (
      type === NurseryFundingProductType.NURSERY_NO_DEDUCT_ONE_OFF_FUNDING
      || type === NurseryFundingProductType.NURSERY_NO_DEDUCT_ALLOCATED_FUNDING
      || type === NurseryFundingProductType.NURSERY_NO_DEDUCT_SUBTRACT_FUNDING
    ) {
      invoice = i18n.t('module:Management:Finance:Funding:List:notDeducted')
    }

    let finalName = name

    if (archived) {
      finalName = `${name} (${i18n.t('global:archived')})`
    }

    return ({
      action: (
        <Button
          hierarchy="tertiary"
          icon="edit"
          size="small"
          to={generateRoute('MANAGEMENT.FINANCE_SETTINGS.FUNDING.EDIT', { nurseryFundingId: record.id })}
          negativeMargins
        />
      ),
      fundingValue: total ? <Currency value={total / 100} /> : '-',
      hourlyRate: price ? <Currency value={price} /> : '-',
      invoice,
      name: finalName,
      type: finalType,
      weeklyHours: (!hoursPerWeek && !minutesPerWeek) ? '-' : (
        `${hoursPerWeek || 0}h ${minutesPerWeek ? `${minutesPerWeek}m` : ''}`
      ),
    })
  }

  const renderFilters = () => (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <Select
            clearable={false}
            options={FUNDING_FILTER_OPTIONS}
            placeholder={_.upperFirst(i18n.t('global:status'))}
            searchable={false}
            value={filters.status}
            v2
            onChange={onFilterChange('status')}
          />
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    if (!isFetching && !records?.length) {
      return (
        <EmptyState
          icon="funding"
          text1={i18n.t('module:Management:Finance:Funding:List:notFound')}
        />
      )
    }

    return (
      <div>
        {renderPagination()}
        <Table
          columns={COLUMNS}
          data={_.map(records, renderRecord)}
          minWidth={980}
        />
        {renderPagination()}
      </div>
    )
  }

  const actions = (
    <Section.Actions primary={[{ to: generateRoute('MANAGEMENT.FINANCE_SETTINGS.FUNDING.ADD') }]} />
  )

  return (
    <Page.Section
      actions={actions}
      isLoading={isFetching}
      title={i18n.t('module:Management:Finance:Funding:title')}
    >
      <Callout content={errorMessages} error />
      {renderFilters()}
      {renderContent()}
    </Page.Section>
  )
}

export default ManagementFundingListV2View
