import _ from 'lodash'
import styled from 'styled-components'
import color from 'color'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { hour24InMilliseconds } from 'constants/date'

import { StyledWrapper } from 'components/Tooltip/TooltipStyled'

interface StyledHoursWrapperProps {
  $disabled: boolean
  $isLoading: boolean
}

export const StyledHoursWrapper = styled.div<StyledHoursWrapperProps>`
  background: ${NEUTRAL_COLOURS.GRAY_TERTIARY};
  border-radius: 12px;
  height: 22px;
  width: 100%;
  position: relative;

  @keyframes placeHolderShimmer {
    0% {
      background-position: 0 0
    }

    100% {
      background-position: 1000px 0
    }
  }

  ${({ $disabled }) => $disabled && `
    opacity: .4;
    cursor: not-allowed;
  `}

  ${({ $isLoading }) => $isLoading && `
    background: linear-gradient(
      to right, 
      ${NEUTRAL_COLOURS.GRAY_TERTIARY} 20%, 
      ${NEUTRAL_COLOURS.GRAY_SECONDARY} 55%, 
      ${NEUTRAL_COLOURS.GRAY_TERTIARY} 85%
    );
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
  `}
`

export const StyledItem = styled.div`
  border-radius: 12px;
  top: 0;
  bottom: 0;
  position: absolute;
  cursor: pointer;
  transition: .1s;

  ${({ $startTime }) => !_.isUndefined($startTime) && `
    left: ${(($startTime * 100) / hour24InMilliseconds) || 0}%;
  `}

  ${({ $endTime }) => $endTime && `
    right: ${(100 - ($endTime * 100) / hour24InMilliseconds) || 0}%;
  `}
`

export const StyledSession = styled(StyledItem)`
  background: #C2DCC8;
  z-index: 10;
  
  ${StyledWrapper} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

export const StyledSessionDraft = styled(StyledItem)`
  background: #C2DCC8;
  z-index: 12;

  ${StyledWrapper} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

export const StyledAllocation = styled(StyledItem)`
  background: ${color('#BE96D9').alpha(0.6).rgb().string()};
  z-index: 15;

  ${StyledWrapper} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

export const StyledRestFundingAllocation = styled(StyledItem)`
  background: #AAC1AF;
  z-index: 15;
  
  ${StyledWrapper} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

export const StyledFundingFormWrapper = styled.div`
  padding: 10px 15px 15px;
  min-width: 280px;
  min-height: 140px;

  .rc-time-picker-input {
    width: 100px !important;
  }
  
  button {
    margin: 0;
  }
`

export const StyledHoursTotalWrapper = styled.div`
  position: relative; 
  display: flex;
  align-items: center;
  flex: 1;
  
  ${({ $isDefined }) => $isDefined && `
    padding: 8px 0;
  `}
`

export const StyledExcludedFundingBox = styled.div`
  position: absolute;
  top: 24px;
  left: 2px;
`

export const StyledDayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
