
import moment from 'moment'
import _ from 'lodash'

import { createSelector } from 'reselect'

import { RootState } from 'core/reducers'
import { ChildProductTypes } from 'services/booking/childProducts/constants'
import {
  Allocation,
  AllocationDefault,
  ChildBooking,
  ChildProduct,
  ChildProductRegularAllocatedFunding,
  ChildProductRegularSubtractFunding,
  FeeCalculationType,
  RedistributeRoleType,
} from 'services/booking/childBooking/constants'
// eslint-disable-next-line max-len, import-newlines/enforce
import { RegularBookingsAddFormValues } from 'module/Children/Child/ChildBookingPattern/RegularBookings/RegularBookingsAdd/components/RegularBookingsAddForm/RegularBookingsAddForm'
import { DAYS_OF_WEEK_LIST, DEFAULT_DATE_FORMAT } from 'constants/date'
import { Child } from 'services/child/models'
import { Option } from 'constants/models'
import {
  ApplicableValues,
  NURSERY_DISCOUNT_BEFORE_AFTER,
  NurseryDiscountTypesV3,
} from 'services/product/nurseryDiscountsV3/constants'
import { NurseryFundingProductType } from 'services/product/nurseryFundingV3/constants'
import {
  AddFundingToRegularBookingsModalFormValues,
} from 'modals/RegularBookings/AddFundingToRegularBookingsModal/components/AddFundingToRegularBookingsModalForm'
import {
  OTHER_OPTION,
} from 'modals/RegularBookings/AddDiscountToRegularBookingsModal/components/AddDiscountToRegularBookingsModalForm'

import { convertDecimalToInteger, convertDisplayToPayloadValue, uuidV6 } from 'utils/data'
import { removeDate } from 'utils/date'

export const getChildBookingSingleState = (state: RootState) => state.childBooking.single

export const getChildBookingSingleDataState = createSelector(
  [getChildBookingSingleState],
  (state) => state.data,
)

export const getBody = (
  params: RegularBookingsAddFormValues,
  child: Child,
  forCalculator?: boolean,
) => {
  const { alternates, attendancePeriod, childProducts, endDate, id, settings, startDate, untilChildLeaves } = params

  const body: ChildBooking = {
    child: {
      id: child.id,
    },
  }

  if (id) {
    body.id = id
  }

  if (alternates) {
    body.alternates = +alternates
  }

  if (attendancePeriod?.value || (attendancePeriod as any)?.id) {
    body.attendancePeriod = {
      id: attendancePeriod.value as number || (attendancePeriod as any)?.id,
    }
  }

  if (startDate) {
    body.startDate = moment(startDate).format(DEFAULT_DATE_FORMAT)
  }

  if (!untilChildLeaves && endDate) {
    body.endDate = moment(endDate).format(DEFAULT_DATE_FORMAT)
  } else if (untilChildLeaves) {
    body.endDate = null
  }

  if (settings) {
    body.settings = {}

    if (settings.feeCalculation) {
      body.settings.feeCalculation = settings.feeCalculation
    }

    if (settings.feeCalculation === FeeCalculationType.MONTHLY_PACKAGE
    || settings.feeCalculation === FeeCalculationType.WEEKLY_PACKAGE
    || settings.feeCalculation === FeeCalculationType.ANNUALISED) {
      if (settings.calculationWeeks) {
        body.settings.calculationWeeks = +settings.calculationWeeks
      }

      if (settings.calculationMonths) {
        body.settings.calculationMonths = +settings.calculationMonths
      }

      if (settings.packageLineItemName) {
        body.settings.packageLineItemName = settings.packageLineItemName
      }

      if (settings.packagePrice) {
        body.settings.packagePrice = convertDecimalToInteger(+settings.packagePrice)
      }

      if (settings.excludedMonths?.length) {
        const months = _.map(settings.excludedMonths, (month: Option) => +month.value || +month) as any

        if (1 <= +settings.calculationMonths && 12 >= +settings.calculationMonths) {
          const finalRemainingMonths = 12 - +settings.calculationMonths

          body.settings.excludedMonths = months.slice(0, finalRemainingMonths)
        }
      }

      if (settings.packageLineItemName) {
        body.settings.packageLineItemName = settings.packageLineItemName
      }

      if (settings.packageLineItemLevelOfDetail) {
        body.settings.packageLineItemLevelOfDetail = settings.packageLineItemLevelOfDetail
      }

      if (settings.packageLineItemDeduction) {
        body.settings.packageLineItemDeduction = settings.packageLineItemDeduction
      }

      if (settings.packageLineItemDisplay) {
        body.settings.packageLineItemDisplay = settings.packageLineItemDisplay
      }
    }
  }

  if (childProducts?.length) {
    const newChildProducts = []

    _.each(childProducts, (product) => {
      const item = {} as any

      if (forCalculator && (
        product.type !== ChildProductTypes.REGULAR_SESSION
        && product.type !== ChildProductTypes.REGULAR_ALLOCATED_FUNDING
      )) {
        return
      }

      if (product.id) {
        item.id = product.id
      }

      if (product.type) {
        item.type = product.type
      }

      if (product.alternate) {
        item.alternate = product.alternate
      }

      if (product.product?.id) {
        item.product = product.product
      }

      if (product.dayOfWeek) {
        item.dayOfWeek = product.dayOfWeek
      }

      if (product.type === ChildProductTypes.REGULAR_SESSION) {
        if (false === product.product.hourly) {
          if (!_.isUndefined(product.product.startTime)) {
            item.product.startTime = product.product.startTime
          }

          if (!_.isUndefined(product.product.endTime)) {
            item.product.endTime = product.product.endTime
          }
        } else {
          if (!_.isUndefined(product.startTime)) {
            item.startTime = product.startTime
          }

          if (!_.isUndefined(product.endTime)) {
            item.endTime = product.endTime
          }
        }

        if (!_.isUndefined(product.settings?.consumablesIncluded)) {
          item.settings = {
            consumablesIncluded: product.settings.consumablesIncluded,
          }
        }
      }

      if (product.type === ChildProductTypes.REGULAR_ITEM) {
        if (product.settings?.quantity) {
          item.settings = {
            quantity: +product.settings.quantity,
          }
        }
      }

      if (product.settings && (
        product.type === ChildProductTypes.REGULAR_AMOUNT_DISCOUNT
        || product.type === ChildProductTypes.REGULAR_PERCENTAGE_DISCOUNT
        || product.type === ChildProductTypes.REGULAR_LINK_DISCOUNT
      )) {
        item.settings = {}

        if (product.settings.name) {
          item.settings.name = product.settings.name
        }

        if (product.settings.value) {
          item.settings.value = +product.settings.value
        }

        if (product.settings.overwritten) {
          item.settings.overwritten = product.settings.overwritten
        }

        if (product.settings.applicable) {
          item.settings.applicable = product.settings.applicable
        }

        if (!_.isUndefined(product.settings.applicableBeforeFunding)) {
          item.settings.applicableBeforeFunding = product.settings.applicableBeforeFunding
        }
      }

      if (product.settings && (
        product.type === ChildProductTypes.REGULAR_ALLOCATED_FUNDING
        || product.type === ChildProductTypes.REGULAR_SUBTRACT_FUNDING
      )) {
        const {
          settings: { allocations, customLocalAuthority, defaultAllocations, hourlyRate, hoursPerDay, hoursPerWeek },
        } = product
        item.settings = {}

        item.settings.customLocalAuthority = !!customLocalAuthority

        if (hourlyRate) {
          item.settings.hourlyRate = convertDisplayToPayloadValue(hourlyRate)
        }

        if (hoursPerWeek) {
          item.settings.hoursPerWeek = +hoursPerWeek
        }

        if (product.type === ChildProductTypes.REGULAR_ALLOCATED_FUNDING) {
          if (hoursPerDay) {
            item.settings.hoursPerDay = +hoursPerDay
          }

          if (allocations?.length) {
            item.settings.allocations = _.map(allocations, (allocation: Allocation) => ({
              date: moment(allocation.date).format(DEFAULT_DATE_FORMAT),
              product: {
                id: allocation.product.id,
              },
              redistributeRole: allocation.redistributeRole || RedistributeRoleType.ACTUAL,
              times: allocation.times,
            }))
          }

          if (defaultAllocations?.length) {
            item.settings.defaultAllocations = _.map(defaultAllocations, (allocation: AllocationDefault) => ({
              dayOfWeek: allocation.dayOfWeek,
              product: {
                id: allocation.product.id,
              },
              times: allocation.times,
            }))
          }
        }
      }

      newChildProducts.push(item)
    })

    body.childProducts = newChildProducts
  }

  return body
}

interface FilterAndGroupByWeekMethodParams {
  alternate: number
  childProducts: ChildProduct[]
  types: ChildProductTypes[]
}

export const filterAndGroupByWeek = ({ alternate, childProducts, types }: FilterAndGroupByWeekMethodParams) => (
  _.flow(
    (elements) => _.groupBy(elements, (item) => (
      _.findIndex(DAYS_OF_WEEK_LIST, (i) => i === item.dayOfWeek)
    )),
    (elements) => elements,
  )(
    _.filter(childProducts, (item) => (
      item.alternate === alternate && _.includes(types, item.type)
    )),
  )
)

export const getFundingBody = ({
  addPriceChanges,
  allocations,
  alternate,
  defaultAllocations,
  id,
  saveExtraFields,
  skipTransformHourlyRate,
  values,
}: {
  addPriceChanges?: boolean
  allocations?: Allocation[]
  alternate: number
  defaultAllocations?: AllocationDefault[]
  id?: string
  saveExtraFields?: boolean
  skipTransformHourlyRate?: boolean
  values: AddFundingToRegularBookingsModalFormValues
}) => {
  const { product, settings } = values
  const { customLocalAuthority, hourlyRate, hoursPerDay, hoursPerWeek } = settings || {}

  const body: ChildProductRegularSubtractFunding | ChildProductRegularAllocatedFunding = {
    alternate,
    id: id || uuidV6(),
    product: {
      id: product.value || product.id,
      name: product.label || product.name,
    },
  }

  if (addPriceChanges) {
    body.product.priceChanges = product.priceChanges
  }

  if (NurseryFundingProductType.NURSERY_SUBTRACT_FUNDING === product.type) {
    body.type = ChildProductTypes.REGULAR_SUBTRACT_FUNDING
  }

  if (NurseryFundingProductType.NURSERY_ALLOCATED_FUNDING === product.type) {
    body.type = ChildProductTypes.REGULAR_ALLOCATED_FUNDING
  }

  if (
    customLocalAuthority
    || hourlyRate
    || hoursPerDay
    || hoursPerWeek
    || defaultAllocations?.length
    || allocations?.length
  ) {
    body.settings = {}

    if (customLocalAuthority) {
      body.settings.customLocalAuthority = !!customLocalAuthority
    }

    if (hourlyRate) {
      body.settings.hourlyRate = skipTransformHourlyRate ? hourlyRate : convertDisplayToPayloadValue(hourlyRate)
    }

    if (hoursPerDay) {
      body.settings.hoursPerDay = +hoursPerDay
    }

    if (hoursPerWeek) {
      body.settings.hoursPerWeek = +hoursPerWeek
    }

    if (allocations?.length) {
      body.settings.allocations = _.map(allocations, (allocation: Allocation) => {
        const newAllocation = {
          date: moment(allocation.date).format(DEFAULT_DATE_FORMAT),
          product: {
            alternate: allocation.product.alternate,
            id: allocation.product.id,
          },
          redistributeRole: allocation.redistributeRole,
          times: allocation.times,
        } as Allocation

        if (saveExtraFields && allocation._extra) {
          newAllocation._extra = allocation._extra
        }

        return newAllocation
      })
    }

    body.settings.defaultAllocations = _.map(defaultAllocations, (allocation: AllocationDefault) => {
      const newAllocation = {
        dayOfWeek: allocation.dayOfWeek,
        product: {
          alternate: allocation.product.alternate,
          id: allocation.product.id,
        },
        times: allocation.times,
      } as AllocationDefault

      if (saveExtraFields && allocation._extra) {
        newAllocation._extra = allocation._extra
      }

      return newAllocation
    })
  }

  return body
}

export const getSessionBodyForPriceCalculator = ({ alternate, date, formValues }) => {
  const { endTime, includeConsumables, session, startTime } = formValues

  const body = {
    childProduct: {
      alternate,
      product: {
        id: session.value,
      },
      type: ChildProductTypes.REGULAR_SESSION,
    },
    date: moment(date).format(DEFAULT_DATE_FORMAT),
  } as any

  if (!_.isUndefined(includeConsumables)) {
    body.childProduct.settings = {
      consumablesIncluded: includeConsumables,
    }
  }

  if (startTime) {
    body.childProduct.startTime = session.startTime || +moment(removeDate(startTime)).format('x')
  }

  if (endTime) {
    body.childProduct.endTime = session.endTime || +moment(removeDate(endTime)).format('x')
  }

  return body
}

export const getDiscountBodyForPriceCalculator = ({ alternate, childProducts, date, formValues }) => {
  const { applicable, applicableBeforeFunding, daysApplicable, discount, name, type, value } = formValues

  const body = {
    childProduct: {
      alternate,
    },
    date: moment(date).format(DEFAULT_DATE_FORMAT),
  } as any

  if (OTHER_OPTION.value === discount?.value) {
    if (type) {
      body.childProduct.type = type
    }

    if (name) {
      if (!body.childProduct?.settings) {
        body.childProduct.settings = {}
      }

      body.childProduct.settings.name = name
    }

    if (value) {
      if (!body.childProduct?.settings) {
        body.childProduct.settings = {}
      }

      body.childProduct.settings.value = (+value || 0) * 100
    }

    _.mapValues(applicable, (checked, key: ApplicableValues) => {
      if (!body.childProduct?.settings) {
        body.childProduct.settings = {}
      }

      if (!body.childProduct.settings?.applicable) {
        body.childProduct.settings.applicable = []
      }

      body.childProduct.settings.applicable.push(key)
    })

    if (
      !_.isUndefined(applicableBeforeFunding)
      && ChildProductTypes.REGULAR_PERCENTAGE_DISCOUNT === type
      && !!applicable?.sessions
    ) {
      body.childProduct.settings.applicableBeforeFunding = (
        applicableBeforeFunding !== NURSERY_DISCOUNT_BEFORE_AFTER.AFTER
      )
    }
  } else {
    body.childProduct.product = {
      id: discount.value,
    }
    body.childProduct.type = ChildProductTypes.REGULAR_LINK_DISCOUNT

    if (discount.settings.allowOverride) {
      if (!body.childProduct?.settings) {
        body.childProduct.settings = {}
      }

      body.childProduct.settings = {
        ...body.childProduct.settings,
        overwritten: true,
        value: value * 100,
      }
    }
  }

  if (daysApplicable?.length) {
    body.daysApplicable = _.map(daysApplicable, (index) => DAYS_OF_WEEK_LIST[index])
  }

  if (discount.type === NurseryDiscountTypesV3.PERCENTAGE
    || ChildProductTypes.REGULAR_PERCENTAGE_DISCOUNT === type
  ) {
    body.associations = _.map(childProducts, (childProduct) => {
      const newChildProduct = {
        ...childProduct,
        settings: {
          ...childProduct.settings,
          hourlyRate: childProduct.settings?.hourlyRate
            ? convertDisplayToPayloadValue(childProduct.settings.hourlyRate)
            : childProduct.settings?.hourlyRate,
        },
      }

      delete newChildProduct._extra

      return newChildProduct
    })
  }

  return body
}

export const getRegularItemBodyForPriceCalculator = ({ alternate, date, formValues }) => {
  const { item, quantity } = formValues

  return {
    childProduct: {
      alternate,
      product: {
        id: item?.value,
      },
      settings: {
        quantity: +quantity,
      },
      type: ChildProductTypes.REGULAR_ITEM,
    },
    date: moment(date).format(DEFAULT_DATE_FORMAT),
  }
}
