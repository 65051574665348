import i18n from 'translations'

export const UPDATE_NURSERY_SETTINGS_EVENT = 'EVENT/UPDATE_NURSERY_SETTINGS_EVENT'

export const UPDATE_ABSENCE_REASON_EVENT = 'EVENT/UPDATE_ABSENCE_REASON_EVENT'

const OPEN_PERIOD = {
  OPEN_FOR_TERM_TIME: 'OPEN_FOR_TERM_TIME',
  OPEN_FOR_YEAR_ROUND: 'OPEN_FOR_YEAR_ROUND',
}

const OPEN_PERIOD_TEXT = {
  [OPEN_PERIOD.OPEN_FOR_TERM_TIME]: i18n.t('module:Nurseries:OpenPeriod:termTime'),
  [OPEN_PERIOD.OPEN_FOR_YEAR_ROUND]: i18n.t('module:Nurseries:OpenPeriod:allYearRound'),
}

const OPEN_PERIOD_OPTIONS = [
  {
    label: i18n.t('module:Nurseries:OpenPeriod:termTime'),
    value: OPEN_PERIOD.OPEN_FOR_TERM_TIME,
  },
  {
    label: i18n.t('module:Nurseries:OpenPeriod:allYearRound'),
    value: OPEN_PERIOD.OPEN_FOR_YEAR_ROUND,
  },
]

const EXTRA_ITEMS_CALCULATION = {
  ACTUAL_SESSIONS: 'ACTUAL_SESSIONS',
  AVERAGE_COST: 'AVERAGE_COST',
}

const INVOICE_LEVEL_DETAIL_DISPLAY = {
  GROUPED_ITEM_TYPES_WITHOUT_COST_BREAKDOWN: 'GROUPED_ITEM_TYPES_WITHOUT_COST_BREAKDOWN',
  ITEM_TYPES_WITH_COST_BREAKDOWN: 'ITEM_TYPES_WITH_COST_BREAKDOWN',
}

const SESSION_CALCULATION = {
  ACTUAL_SESSIONS: 'ACTUAL_SESSIONS',
  AVERAGE_COST: 'AVERAGE_COST',
}

const FORM_THANKYOU_PAGE_TYPE = {
  CUSTOM: 'CUSTOM',
  DEFAULT: 'DEFAULT',
}

const FORM_THANKYOU_PAGE_OPTIONS = [
  {
    label: i18n.t('module:Management:Enquiries:EnquiryForm:FormSubmissionPage:ThankYouPageOptions:Default:label'),
    tooltip: i18n.t('module:Management:Enquiries:EnquiryForm:FormSubmissionPage:ThankYouPageOptions:Default:tooltip'),
    tooltipPlacement: 'right',
    value: FORM_THANKYOU_PAGE_TYPE.DEFAULT,
  },
  {
    label: i18n.t('module:Management:Enquiries:EnquiryForm:FormSubmissionPage:ThankYouPageOptions:Custom:label'),
    tooltip: i18n.t('module:Management:Enquiries:EnquiryForm:FormSubmissionPage:ThankYouPageOptions:Custom:tooltip'),
    tooltipPlacement: 'right',
    value: FORM_THANKYOU_PAGE_TYPE.CUSTOM,
  },
]

export const EDITING_STAFF_ROTA = {
  PAST_WEEK_CANNOT_EDITED: 'PAST_WEEK_CANNOT_EDITED',
  PAST_WEEK_CAN_EDITED: 'PAST_WEEK_CAN_EDITED',
}

export const EDITING_STAFF_ROTA_OPTIONS = [
  {
    label: i18n.t('module:Management:General:RotaAndOccupancy:EditingStaffRota:Options:PastWeekCannotEdited:label'),
    value: EDITING_STAFF_ROTA.PAST_WEEK_CANNOT_EDITED,
  },
  {
    label: i18n.t('module:Management:General:RotaAndOccupancy:EditingStaffRota:Options:PastWeekCanEdited:label'),
    value: EDITING_STAFF_ROTA.PAST_WEEK_CAN_EDITED,
  },
]

export default {
  EXTRA_ITEMS_CALCULATION,
  FORM_THANKYOU_PAGE_OPTIONS,
  FORM_THANKYOU_PAGE_TYPE,
  INVOICE_LEVEL_DETAIL_DISPLAY,
  OPEN_PERIOD,
  OPEN_PERIOD_OPTIONS,
  OPEN_PERIOD_TEXT,
  SESSION_CALCULATION,
}

export const INDEPENDENT_NURSERY_OPTION = {
  label: i18n.t('module:Organisations:Add:Form:independentNursery'),
  value: 0,
}
