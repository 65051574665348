/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef, useState } from 'react'
import { uuidV6 } from 'utils/data'

import i18n from 'translations'

export const CLEAR_ALL = 'CLEAR ALL'

interface CircleAttributeProps {
  cx: number
  cy: number
  'data-id': string
  fill: string
  opacity?: number
  r: number
  stroke?: string
  'stroke-width'?: number
  style: string
}

interface CoordinateTransform {
  [key: string]: Coordinate
}

interface Coordinate {
  x: number
  y: number
}

interface SvgInteractiveProps {
  defaultValues: Coordinate[]
  displayClearAll?: boolean
  image: string
  onClick: (coordinates: Coordinate[]) => void
  readOnly?: number
  svgViewBox?: string
}

const SvgInteractive: React.FC<SvgInteractiveProps> = ({
  defaultValues,
  displayClearAll,
  image,
  onClick,
  readOnly,
  svgViewBox = '0 0 924 535',
}) => {
  const svgRef = useRef<SVGSVGElement>(null)

  const [coordinates, setCoordinates] = useState<CoordinateTransform>({})

  const createCircleElement = (attributes: CircleAttributeProps) => {
    const circle = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'circle',
    )

    Object.keys(attributes).forEach((key) => {
      circle.setAttribute(key, attributes[key])
    })

    return circle
  }

  const addCircle = (id: string, { x, y }: Coordinate) => {
    const groupElement = document.createElementNS('http://www.w3.org/2000/svg', 'g')
    groupElement.setAttributeNS(null, 'data-id', `g-${id}`)

    const circle1 = createCircleElement({
      cx: x,
      cy: y,
      'data-id': id,
      fill: 'transparent',
      opacity: 0.3,
      r: 14,
      stroke: '#E20714',
      'stroke-width': 3,
      style: `cursor: ${readOnly ? 'not-allowed' : 'pointer'}`,
    })

    const circle2 = createCircleElement({
      cx: x,
      cy: y,
      'data-id': id,
      fill: '#E20714',
      r: 9,
      style: `cursor: ${readOnly ? 'not-allowed' : 'pointer'}`,
    })

    const exitSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
    exitSvg.setAttributeNS(null, 'x', (x - 6.68).toString())
    exitSvg.setAttributeNS(null, 'y', (y - 5.68).toString())
    exitSvg.setAttributeNS(null, 'style', `cursor: ${readOnly ? 'not-allowed' : 'pointer'}`)

    const exitPath1 = document.createElementNS('http://www.w3.org/2000/svg', 'path')
    // eslint-disable-next-line max-len
    exitPath1.setAttribute('d', 'M10.1752 2.64645C10.3702 2.84171 10.3702 3.15829 10.1752 3.35355L4.18173 9.35355C3.98668 9.54882 3.67044 9.54882 3.47539 9.35355C3.28034 9.15829 3.28034 8.84171 3.47539 8.64645L9.46886 2.64645C9.66391 2.45118 9.98015 2.45118 10.1752 2.64645Z')
    exitPath1.setAttribute('fill', 'white')
    exitPath1.setAttribute('data-id', id)

    const exitPath2 = document.createElementNS('http://www.w3.org/2000/svg', 'path')
    // eslint-disable-next-line max-len
    exitPath2.setAttribute('d', 'M3.47539 2.64645C3.67044 2.45118 3.98668 2.45118 4.18173 2.64645L10.1752 8.64645C10.3702 8.84171 10.3702 9.15829 10.1752 9.35355C9.98015 9.54882 9.66391 9.54882 9.46886 9.35355L3.47539 3.35355C3.28034 3.15829 3.28034 2.84171 3.47539 2.64645Z')
    exitPath2.setAttribute('fill', 'white')
    exitPath2.setAttribute('data-id', id)

    exitSvg.appendChild(exitPath1)
    exitSvg.appendChild(exitPath2)

    groupElement.appendChild(circle1)
    groupElement.appendChild(circle2)
    groupElement.appendChild(exitSvg)

    groupElement.addEventListener('mouseover', () => {
      groupElement.setAttribute('opacity', '0.6')
    })

    groupElement.addEventListener('mouseout', () => {
      groupElement.setAttribute('opacity', '1')
    })

    svgRef.current.appendChild(groupElement)
  }

  useEffect(() => {
    const elements = svgRef.current.querySelectorAll('[data-id^="g-"]')

    elements.forEach((element) => svgRef.current.removeChild(element))

    let newCoordinates

    if (!defaultValues?.length) {
      newCoordinates = {}
    } else {
      newCoordinates = defaultValues?.reduce((result, item) => ({
        ...result,
        [uuidV6()]: item,
      }), {})
    }

    setCoordinates(newCoordinates)
    Object.keys(newCoordinates).forEach((key) => {
      addCircle(key, newCoordinates[key])
    })
  }, [defaultValues])

  useEffect(() => {
    Object.keys(coordinates).forEach((key) => {
      addCircle(key, coordinates[key])
    })
  }, [])

  const handleSaveCoordinates = (updatedCoordinates) => {
    setCoordinates(CLEAR_ALL === updatedCoordinates ? [] : updatedCoordinates)
    onClick?.(
      Object.keys(updatedCoordinates).map((key) => updatedCoordinates[key]),
    )
  }

  const removeCircle = (id: string) => {
    const elements = svgRef.current.querySelectorAll(`[data-id="g-${id}"]`)

    elements.forEach((element) => svgRef.current.removeChild(element))

    const newCoordinates = { ...coordinates }

    delete newCoordinates[id]

    handleSaveCoordinates(newCoordinates)
  }

  const handleClick: React.MouseEventHandler<SVGSVGElement> = (e) => {
    if (readOnly) {
      return
    }

    const target = e.target as HTMLElement

    if ('text' === target.tagName) {
      handleSaveCoordinates(CLEAR_ALL)
      return
    }

    if ('circle' === target.tagName || 'path' === target.tagName) {
      removeCircle(target.dataset.id)
      return
    }

    const pt = svgRef.current.createSVGPoint()

    pt.x = e.clientX
    pt.y = e.clientY

    const { x, y } = pt.matrixTransform(
      svgRef.current.getScreenCTM().inverse(),
    )

    const newId = uuidV6()

    addCircle(newId, { x, y })

    const newCoordinates = {
      ...coordinates,
      [newId]: { x, y },
    }

    handleSaveCoordinates(newCoordinates)
  }

  const onClickClearAll = () => {
    const elementKeys = Object.keys(coordinates)

    elementKeys.forEach((element) => removeCircle(element))
  }

  return (
    <svg fill="none" ref={svgRef} viewBox={svgViewBox} xmlns="http://www.w3.org/2000/svg" onClick={handleClick}>
      <image height="100%" width="100%" xlinkHref={image} />
      {displayClearAll && (
        <a style={{ cursor: 'pointer', userSelect: 'none' }} onClick={onClickClearAll}>
          <text fill="#29AFA0" x="850" y="25">
            {i18n.t('global:ClearAll')}
          </text>
        </a>
      )}
    </svg>
  )
}

export default SvgInteractive
