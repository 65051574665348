import { compose } from 'recompose'
import { connect } from 'react-redux'

import React, { Component } from 'react'

import { withAppService } from 'services/app'
import { withInvoicesService } from 'services/legacy/invoices'
import { withModalService } from 'services/utils/modal'

import PreviewModalView from './PreviewModalView'

const INVOICE_AUTO_CALCULATE_GROUPS = {
  read: [
    'invoice.subItems',
    'calculator.preview',
    'invoicePreview',
    'invoicePreview.invoice',
    'invoicePreview.items.invoiceItem',
    'invoice.items',
    'invoice.child',
    'child',
    'child.finance',
    'invoiceItem',
  ],
}

const INVOICE_PREVIEW_GROUPS = {
  read: [
    'invoicePreview.creditNotes',
    'creditNote',
    'invoice.child',
    'child',
    'child.finance',
    'invoice.sendLogs',
  ],
}

class PreviewModalContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isCalculating: false,
    }
  }

  componentDidMount() {
    const { invoiceId, invoicesActions } = this.props

    if (!invoiceId) {
      this.calculateEstimatedInvoice()

      return
    }

    invoicesActions.getPreview(invoiceId, { groups: INVOICE_PREVIEW_GROUPS })
  }

  componentWillUnmount() {
    const { invoicesActions } = this.props

    invoicesActions.clearPreview()
  }

  handleAutoCalculateSuccess = () => {
    this.setState({ isCalculating: false })
  }

  calculateEstimatedInvoice = () => {
    const { autoCalculateParams, childId, invoicesActions, invoicesSelectors } = this.props
    const { period } = autoCalculateParams
    const { after: startDate, before: endDate } = period

    this.setState({ isCalculating: true })

    const body = invoicesSelectors.getAutoCalculateBodySelector({ endDate, startDate })

    invoicesActions.autoCalculate({
      body,
      onFailed: this.handleSendConflict,
      onSuccess: this.handleAutoCalculateSuccess,
      params: [childId, { groups: INVOICE_AUTO_CALCULATE_GROUPS }],
    })
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  render() {
    const { invoicePreview, isFetching } = this.props
    const { isCalculating } = this.state

    const isLoading = isFetching || isCalculating

    return (
      <PreviewModalView
        invoicePreview={invoicePreview}
        isLoading={isLoading}
        onCloseClick={this.handleCloseClick}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  invoicesPreviewState,
  invoicesSelectors,
}) => ({
  errorMessages: appSelectors.getErrorMessages(invoicesPreviewState),
  invoicePreview: invoicesSelectors.getInvoiceFormattedPreviewSelector(state),
  isFetching: appSelectors.getIsFetching(invoicesPreviewState),
})

const enhance = compose(
  withAppService,
  withInvoicesService,
  withModalService,
  connect(mapState),
)

export default enhance(PreviewModalContainer)
