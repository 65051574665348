import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { compose } from 'recompose'

import { properties } from 'app-config'

import { FieldError } from 'components'

import { withUploadService, withUploadServiceProps } from 'services/legacy/upload'

import { StyledInputContainer, StyledTextEditor } from './TextEditorFieldStyled'

require('tinymce/plugins/table')
require('tinymce/plugins/image')
require('tinymce/plugins/lists')
require('tinymce/plugins/imagetools')
require('tinymce/plugins/autoresize')
require('tinymce/plugins/link')

export interface TextEditorFieldProps {
  disabled?: boolean
  error?: string
  mentionOptions?: {
    data: any
  }
  onChange?: () => {}
  placeholder?: string
  plugins?: string[]
  toolbar?: string
  uploadDirectory?: string
  valid?: boolean
  value?: string
}

type TextEditorFieldFullProps = TextEditorFieldProps
  & withUploadServiceProps

const TextEditorField: React.FC<PropsWithChildren<TextEditorFieldFullProps>> = ({
  uploadActions,
  disabled,
  error,
  mentionOptions,
  placeholder,
  plugins = ['mention'],
  uploadDirectory = '/images',
  toolbar,
  valid,
  value,
  onChange,
}) => {
  const editorRef = useRef()
  const [eventDefined, setEventDefined] = useState<boolean>(false)

  const onUploadImage = async (blobInfo, success) => {
    const image = await uploadActions.uploadFile(blobInfo.blob(), blobInfo.filename(), uploadDirectory)

    return success(image)
  }

  useEffect(() => {
    if (!eventDefined && editorRef.current) {
      setEventDefined(true)

      // @ts-ignore
      editorRef.current.editor.on('ObjectResized', (e) => {
        if ('IMG' === e.target.nodeName) {
          const { height, target, width } = e
          let { src } = target

          src = src
            .replace(/width=\d+/, '')
            .replace(/height=\d+/, '')
            .replace('?', '')
            .replace('&', '')

          src = `${src}?width=${width}&height=${height}`

          target.src = src
        }
      })
    }
  }, [eventDefined])

  const mentions = {
    delay: 0,
    delimiter: '{',
    render: (dropdownItem) => '<li>'
      .concat('<a href="javascript:;">')
      .concat(`<div class="name">${dropdownItem.name}</div>`)
      .concat(`<div class="placeholder">${dropdownItem.placeholder}</div>`)
      .concat('</a>')
      .concat('</li>'),
    source: (query, process) => {
      process(mentionOptions ? mentionOptions.data : null)
    },
  }

  const setup = (editor) => {
    editor.ui.registry.addButton('customImageButton', {
      icon: 'image',
      onAction: () => {
        // @ts-ignore
        editorRef.current.editor.execCommand('mceImage')

        setTimeout(() => {
          const event = new Event('click', { bubbles: true })
          document.querySelector('.tox-dialog__body-nav-item:nth-of-type(2)').dispatchEvent(event)
          // @ts-ignore
          document.querySelector('.tox-dialog__body-nav-item:nth-of-type(2)').focus()
        })
      },
    })
  }

  /* eslint-disable camelcase */
  const initOptions = {
    content_css: ['/tinymce/content.min.css'],
    contextmenu: false,
    images_upload_handler: onUploadImage,
    mentions,
    menubar: false,
    min_height: 200,
    plugins,
    setup,
    skin_url: `${window.location.origin}/tinymce`,
    statusbar: false,
    toolbar: toolbar?.replace('image', 'customImageButton'),
  }
  /* eslint-enable camelcase */

  return (
    <React.Fragment>
      <StyledInputContainer>
        <StyledTextEditor
          $disabled={disabled}
          $valid={valid}
          apiKey={properties.tinyMceApiKey}
          init={initOptions}
          placeholder={placeholder}
          ref={editorRef}
          value={value}
          onEditorChange={onChange}
        />
      </StyledInputContainer>
      <FieldError error={error} />
    </React.Fragment>
  )
}

const enhance = compose(
  withUploadService,
)

export default enhance(TextEditorField)
