import _ from 'lodash'

import React from 'react'

import { ChildProductTypes } from 'services/booking/childProducts/constants'
import { NurseryDiscountTypesV3 } from 'services/product/nurseryDiscountsV3/constants'

import { Button, Currency, Hr, Space, Table, Typography } from 'components'

import { filterAndGroupByWeek } from 'services/booking/childBooking/single/selectors'

import i18n from 'translations'

import { RegularBookingsAddFormValues } from '../RegularBookingsAddForm'

interface DiscountsProps {
  formValues: RegularBookingsAddFormValues
  isSubmitting: boolean
  onAddDiscount: (index: number) => void
  onRemoveChildProduct: (id: string) => void
}

const Discounts: React.FC<DiscountsProps> = ({
  formValues,
  isSubmitting,
  onAddDiscount,
  onRemoveChildProduct,
}) => {
  const { alternates } = formValues || {}

  const renderPrices = (item) => {
    const { _extra } = item
    const { price } = _extra

    return <Currency value={price / 100} />
  }

  const renderDetails = (item) => {
    if (item.product?.type === NurseryDiscountTypesV3.PERCENTAGE
      || item.type === ChildProductTypes.REGULAR_PERCENTAGE_DISCOUNT
    ) {
      return (
        <Typography maxWidth={160} ellipsis>
          {`${item._extra.value / 100}%`}
        </Typography>
      )
    }

    return i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:fixedAmount')
  }

  const renderTable = (alternate: number) => {
    const itemsGroupedByWeek = filterAndGroupByWeek({
      alternate,
      childProducts: formValues?.childProducts,
      types: [
        ChildProductTypes.REGULAR_LINK_DISCOUNT,
        ChildProductTypes.REGULAR_PERCENTAGE_DISCOUNT,
        ChildProductTypes.REGULAR_AMOUNT_DISCOUNT,
      ],
    })

    return (
      <div>
        {_.map(itemsGroupedByWeek, (items, dayIndex) => (
          <Table key={`${alternate}_${dayIndex}`} minWidth={600} visualType="transparent" disableSticky>
            <Table.Thead>
              <Table.Tr>
                <Table.Th align="left" colSpan={4}>
                  {i18n.t(`global:DayNames:${+dayIndex + 1}`)}
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {_.map(items, (item) => (
                <Table.Tr key={item.id}>
                  <Table.Td align="left" width="40%">
                    <Typography maxWidth={300} ellipsis>
                      {item.product?.name || item.settings?.name}
                    </Typography>
                  </Table.Td>
                  <Table.Td align="left" width="20%">
                    {renderDetails(item)}
                  </Table.Td>
                  <Table.Td width="20%">
                    {renderPrices(item)}
                  </Table.Td>
                  <Table.Td width="10%">
                    <Button
                      disabled={isSubmitting}
                      hierarchy="secondary"
                      icon="trash"
                      size="small"
                      negativeMargins
                      onClick={() => !isSubmitting && onRemoveChildProduct(item.id)}
                    />
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        ))}
      </div>
    )
  }

  const renderDiscountsWeek = (alternate) => (
    <div key={alternate}>
      <Typography fontSize={18} bold>
        {1 < alternates
          // eslint-disable-next-line max-len
          ? `${i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:discountForAlternatingWeek')} ${alternate + 1}`
          : i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:discount')}
      </Typography>
      <Space margin="20px 0" />
      {renderTable(alternate + 1)}
      <Space margin="20px 0" />
      <Button
        disabled={isSubmitting}
        hierarchy="secondary"
        icon="plus"
        label={i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:addDiscount')}
        size="small"
        onClick={() => !isSubmitting && onAddDiscount(alternate + 1)}
      />
      {alternates - 1 !== alternate && <Hr margin="20px 0" />}
    </div>
  )

  return (
    <div>
      {_.times(alternates, renderDiscountsWeek)}
    </div>
  )
}

export default Discounts
