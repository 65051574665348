import _ from 'lodash'

import React, { useCallback, useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'
import { change, getFormValues } from 'redux-form'

import { BasicModalProps } from 'modals'
import { ChildProductTypes } from 'services/booking/childProducts/constants'
import { Child } from 'services/child/models'
import {
  AddSessionToRegularBookingsModalFormValues,
} from 'modals/RegularBookings/AddSessionToRegularBookingsModal/components/AddSessionToRegularBookingsModalForm'
// eslint-disable-next-line max-len, import-newlines/enforce
import { RegularBookingsAddFormValues } from 'module/Children/Child/ChildBookingPattern/RegularBookings/RegularBookingsAdd/components/RegularBookingsAddForm/RegularBookingsAddForm'
import { NURSERY_DISCOUNTS_FILTER } from 'services/product/nurseryDiscountsV3/constants'
// eslint-disable-next-line max-len, import-newlines/enforce
import { BeforeAfterType } from 'module/Management/ManagementFinanceSettings/ManagementDiscounts/v3/ManagementDiscountsV3Add/components/constants'

import { usePrevious } from 'utils/hooks'

import { withChildService, withChildServiceProps } from 'services/legacy/child'
import { withChildBookingService, withChildBookingServiceProps } from 'services/booking/childBooking'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withNurseryDiscountsV3Service, withNurseryDiscountsV3ServiceProps } from 'services/product/nurseryDiscountsV3'
import { withNurseryContextService, withNurseryContextServiceProps } from 'services/nurseryContext'

import AddDiscountToRegularBookingsModalView from './AddDiscountToRegularBookingsModalView'
import {
  ADD_DISCOUNT_TO_REGULAR_BOOKINGS_MODAL_FORM,
  AddDiscountToRegularBookingsModalFormValues,
  OTHER_OPTION,
} from './components/AddDiscountToRegularBookingsModalForm'

const NURSERY_DISCOUNTS_GROUPS = {
  read: [
    'nurseryDiscountProduct.settings',
    'nurseryDiscountProduct.nursery',
    'nurseryDiscountProductSettings',
  ],
}

export interface AddDiscountToRegularBookingsModalProps {
  alternate: number
  child: Child
  onSuccess: (
    values: AddSessionToRegularBookingsModalFormValues,
    price: number,
    value: number,
    daysBreakdown: any
  ) => void
  regularBookingsFormValues: RegularBookingsAddFormValues
}

const mapDispatch = {
  changeField: (field, value) => change(ADD_DISCOUNT_TO_REGULAR_BOOKINGS_MODAL_FORM, field, value),
}

const mapState = (state, { nurseryContextSelectors }) => ({
  formValues: getFormValues(
    ADD_DISCOUNT_TO_REGULAR_BOOKINGS_MODAL_FORM,
  )(state) as AddDiscountToRegularBookingsModalFormValues,
  nurseryOpeningDays: nurseryContextSelectors.getNurseryOpeningDays(state),
})

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type AddDiscountToRegularBookingsModalContainerFullProps = PropsFromRedux
  & AddDiscountToRegularBookingsModalProps
  & BasicModalProps
  & withChildServiceProps
  & withChildBookingServiceProps
  & withModalServiceProps
  & withNurseryDiscountsV3ServiceProps
  & withNurseryContextServiceProps

const AddDiscountToRegularBookingsModalContainer: React.FC<
  AddDiscountToRegularBookingsModalContainerFullProps
> = ({
  alternate,
  changeField,
  child,
  childActions,
  childBookingSelectors,
  formValues,
  hideModal,
  nurseryDiscountsV3Actions,
  nurseryDiscountsV3Selectors,
  nurseryOpeningDays,
  onSuccess,
  regularBookingsFormValues,
}) => {
  const [initialValues, setInitialValues] = useState<AddDiscountToRegularBookingsModalFormValues>(null)
  const [price, setPrice] = useState<number>(null)
  const [value, setValue] = useState<number>(null)
  const [daysBreakdown, setDaysBreakdown] = useState<object>(null)
  const [isFetchingPrice, setIsFetchingPrice] = useState<boolean>(false)

  const prevState = usePrevious({ formValues })

  const debounceGetProductPriceCalculator = useCallback(
    _.debounce((newValue, cb) => {
      cb(newValue)
    }, 500),
    [],
  )

  useEffect(() => {
    const criteria = nurseryDiscountsV3Selectors.getCriteriaSelector({ statusFilter: NURSERY_DISCOUNTS_FILTER.ACTIVE })

    nurseryDiscountsV3Actions.list({
      onSuccess: ({ data }) => {
        if (data?.length) {
          const firstElement = _.first(data) as any

          setInitialValues({
            applicableBeforeFunding: BeforeAfterType.AFTER,
            discount: {
              label: firstElement.name,
              settings: firstElement.settings,
              type: firstElement.type,
              value: firstElement.id,
            },
            value: firstElement.settings.value / 100,
          })
        }
      },
      params: {
        criteria,
        groups: NURSERY_DISCOUNTS_GROUPS,
        limit: 1,
      },
    })
  }, [])

  const getCalculator = () => {
    if (
      !regularBookingsFormValues.startDate
      || !formValues
      || !formValues?.discount
      || (!formValues?.name && formValues.discount.value === OTHER_OPTION.value)
      || !formValues?.daysApplicable?.length
    ) {
      setPrice(null)

      return
    }

    setIsFetchingPrice(true)

    const body = childBookingSelectors.getDiscountBodyForPriceCalculator({
      alternate,
      childProducts: regularBookingsFormValues.childProducts,
      date: regularBookingsFormValues.startDate,
      formValues,
    })

    debounceGetProductPriceCalculator(body, (newBody) => {
      childActions.getProductPriceCalculator({
        body: newBody,
        onFailed: () => {
          setPrice(null)
          setIsFetchingPrice(false)
        },
        onSuccess: (response) => {
          const { data } = response
          const { total } = data

          setPrice(total)
          setValue(data.value)
          setDaysBreakdown(data.daysBreakdown)
          setIsFetchingPrice(false)
        },
        params: [child.id, {}],
      })
    })
  }

  useEffect(getCalculator, [
    formValues?.discount,
    formValues?.type,
    formValues?.value,
    formValues?.applicable,
    formValues?.applicableBeforeFunding,
    formValues?.daysApplicable,
  ])

  useEffect(() => {
    if (formValues?.name && !(prevState as any)?.formValues?.name) {
      getCalculator()
    }
  }, [formValues?.name])

  const handleCloseClick = () => {
    hideModal()
  }

  const handleSubmit = (values) => {
    onSuccess(values, price, value, daysBreakdown)
    hideModal()
  }

  const handleChangeDiscount = (option: any) => {
    if (option.settings?.value && OTHER_OPTION.value !== option?.value) {
      changeField('value', option.settings.value / 100)
    }

    if (OTHER_OPTION.value === option?.value) {
      changeField('value', 0)
      changeField('type', ChildProductTypes.REGULAR_PERCENTAGE_DISCOUNT)
    }
  }

  return (
    <AddDiscountToRegularBookingsModalView
      formValues={formValues}
      initialValues={initialValues}
      isFetchingPrice={isFetchingPrice}
      nurseryOpeningDays={nurseryOpeningDays}
      price={price}
      onChangeDiscount={handleChangeDiscount}
      onCloseClick={handleCloseClick}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withChildService,
  withChildBookingService,
  withModalService,
  withNurseryDiscountsV3Service,
  withNurseryContextService,
  connector,
)

export default enhance(AddDiscountToRegularBookingsModalContainer)
