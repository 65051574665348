import _ from 'lodash'
import moment from 'moment'

import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import { DAYS_OF_WEEK_LIST } from 'constants/date'

import { isRequired } from 'utils/fieldValidation'

import { Banner, Button, Currency, FooterActions, Form, ModalBox, Spinner, TimeString } from 'components'

import i18n from 'translations'

export const ADD_SESSION_TO_REGULAR_BOOKINGS_MODAL_FORM = 'AddSessionToRegularBookingsModalForm'

const NURSERY_SESSIONS_GROUPS = {
  read: [
    'nurserySession',
    'nurserySessionProduct.items',
  ],
}

const WEEK_DAYS = _.times(7, (index) => ({
  label: i18n.t(`global:shortDayNames:${index + 1}`),
  value: index,
}))

export interface AddSessionToRegularBookingsModalFormValues {
  daysApplicable?: number[]
  endTime?: number
  includeConsumables?: boolean
  session?: {
    costTwoYears?: number
    costTwoYearsAbove?: number
    costUnderTwo?: number
    endTime: number
    hourly: boolean
    items?: any[]
    label: string
    startTime: number
    value: number
  }
  startTime?: number
}

export interface AddSessionToRegularBookingsModalFormProps {
  dayOfWeeksOverlap: number[]
  formValues?: AddSessionToRegularBookingsModalFormValues
  hoursOverlap: boolean
  isFetchingPrice: boolean
  nurseryOpeningDays?: any
  onCloseClick?: () => void
  onSubmit: (values: AddSessionToRegularBookingsModalFormValues) => void
  price: number | null
}

type AddSessionToRegularBookingsModalFormFullProps = InjectedFormProps<{}, AddSessionToRegularBookingsModalFormProps>
  & AddSessionToRegularBookingsModalFormProps

const AddSessionToRegularBookingsModalForm: React.FC<AddSessionToRegularBookingsModalFormFullProps> = ({
  dayOfWeeksOverlap,
  formValues,
  handleSubmit,
  hoursOverlap,
  isFetchingPrice,
  nurseryOpeningDays,
  onCloseClick,
  onSubmit,
  price,
}) => {
  const { session } = formValues || {}
  const sessionHasConsumables = _.find(session?.items, ({ type }) => 'nursery_session_consumable' === type)

  const renderFooter = () => (
    <FooterActions spaceBetween>
      <FooterActions.Group>
        <FooterActions.Item>
          <Button
            hierarchy="tertiary"
            label={i18n.t('global:cancel')}
            negativeMargins
            onClick={onCloseClick}
          />
        </FooterActions.Item>
      </FooterActions.Group>
      <FooterActions.Group>
        <FooterActions.Item>
          <Button
            disabled={isFetchingPrice}
            label={i18n.t('global:Save')}
            negativeMargins
            submit
          />
        </FooterActions.Item>
      </FooterActions.Group>
    </FooterActions>
  )

  const renderTimeRow = () => {
    if (!session) {
      return '-'
    }

    const { endTime, hourly, startTime } = session

    if (hourly) {
      return (
        <React.Fragment>
          <Form.Row.Item>
            <Field
              component={Form.TimePicker}
              name="startTime"
              validate={isRequired}
            />
          </Form.Row.Item>
          <Form.Row.TextItem text={i18n.t('global:to')} />
          <Form.Row.Item>
            <Field
              component={Form.TimePicker}
              name="endTime"
              validate={isRequired}
            />
          </Form.Row.Item>
        </React.Fragment>
      )
    }

    return (
      <div>
        <TimeString date={startTime} local={false} />
        {' - '}
        <TimeString date={endTime} local={false} />
      </div>
    )
  }

  const renderPrice = () => {
    if (isFetchingPrice) {
      return (
        <Spinner position="flex-start" size="small" />
      )
    }

    if (!_.isNumber(price)) {
      return '-'
    }

    return (
      <Currency value={price / 100} />
    )
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {hoursOverlap && (
        <Banner.Warning>
          {i18n.t('modals:AddSessionToRegularBookings:sessionsCannotOverlap', {
            dayOfWeeks: _.map(dayOfWeeksOverlap, (index) => (
              i18n.t(`global:DayNames:${+index + 1}`)
            )).join(', '),
          })}
        </Banner.Warning>
      )}
      <ModalBox.ScrollContent>
        <Form.Row
          label={i18n.t('modals:AddSessionToRegularBookings:sessionName')}
          width={{ field: '300px' }}
          required
          verticalLabel
        >
          <Field
            clearable={false}
            component={Form.InfiniteDropdowns.NurserySessionsV3}
            extraFields={[
              'hourly',
              'startTime',
              'items',
              'endTime',
              'costTwoYears',
              'costTwoYearsAbove',
              'costUnderTwo',
            ]}
            groups={NURSERY_SESSIONS_GROUPS}
            name="session"
            placeholder={i18n.t('modals:AddSessionToRegularBookings:sessionName')}
            validate={isRequired}
            bodyTarget
          />
        </Form.Row>
        <Form.Row
          label={i18n.t('modals:AddSessionToRegularBookings:daysApplicable')}
          required
          verticalLabel
        >
          <Field
            component={Form.ButtonGroup}
            name="daysApplicable"
            options={_.map(WEEK_DAYS, (weekDay) => ({
              ...weekDay,
              disabled: !(
                _.find(nurseryOpeningDays, ({ day }) => day === DAYS_OF_WEEK_LIST[weekDay.value])
              ),
            }))}
            validate={isRequired}
            multi
          />
        </Form.Row>
        <Form.Row
          label={i18n.t('global:Time')}
          verticalLabel
        >
          {renderTimeRow()}
        </Form.Row>
        {sessionHasConsumables && (
          <Form.Row
            label={i18n.t('modals:AddSessionToRegularBookings:includeConsumables')}
            verticalLabel
          >
            <Field
              component={Form.Switch}
              name="includeConsumables"
            />
          </Form.Row>
        )}
        <Form.Row
          label={i18n.t('global:Price')}
          verticalLabel
        >
          {renderPrice()}
        </Form.Row>
      </ModalBox.ScrollContent>
      {renderFooter()}
    </Form>
  )
}

const validate = (fields) => {
  const errors = {} as any

  if (fields.endTime && fields.startTime && !moment(fields.endTime).isAfter(fields.startTime)) {
    errors.endTime = i18n.t('modals:AddSessionToRegularBookings:endTimeValidation')
  }

  return errors
}

export default reduxForm<{}, AddSessionToRegularBookingsModalFormProps>({
  form: ADD_SESSION_TO_REGULAR_BOOKINGS_MODAL_FORM,
  validate,
})(AddSessionToRegularBookingsModalForm)
