import React from 'react'

import { toInt } from 'utils/data'

import { Currency, ProgressBar } from 'components'

import {
  StyledContainer,
  StyledProgressContainer,
  StyledProgressTextContainer,
  StyledTextContainer,
  StyledTitleContainer,
} from './ChildFundingAllocatedWidgetItemStyled'

const ChildFundingAllocatedWidgetItem = ({ progress = 0, showSymbol, title, total = 0 }) => {
  const percent = toInt(((progress || 0) * 100) / (total || 100))

  return (
    <StyledContainer>
      <StyledTitleContainer>
        {title}
      </StyledTitleContainer>
      <StyledProgressContainer>
        <ProgressBar isExceedAvailable={progress > total} width={percent} />
      </StyledProgressContainer>
      <StyledTextContainer>
        <StyledProgressTextContainer>
          {showSymbol ? <Currency value={progress} /> : progress}
          &nbsp;
        </StyledProgressTextContainer>
        {'of'}
        &nbsp;
        {showSymbol ? <Currency value={total} /> : total}
      </StyledTextContainer>
    </StyledContainer>
  )
}

export default ChildFundingAllocatedWidgetItem
