import moment from 'moment'
import userflow from 'userflow.js'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { goBack } from 'react-router-redux'

import { FEATURE_FLAGS, ROLES } from 'constants/security'

import { withRouter } from 'services/router'

import { setRoleGroupForUserflow } from 'core/helpers/userflow'

import { EVENTS, logEvent } from 'analytics'

import layout from 'constants/layout'
import { LOGIN_SUCCESSFUL } from 'services/legacy/auth/constants'
import { GET_ORGANISATION_SETTINGS_EVENT, HEADER_PINNED_EVENT, IS_HEADER_PINNED_LS } from 'services/shell/constants'
import { UPDATE_ABSENCE_REASON_EVENT, UPDATE_NURSERY_SETTINGS_EVENT } from 'services/nurseries/constants'
import { MENU_ITEM_WIDTH } from 'components/MenuItem/MenuItemStyled'
import { RELOAD_GLOBAL_NURSERY_SETTINGS_EVENT } from 'services/nurseryContext/constants'
import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { checkBrowserIsOutOfTheList, isIOS } from 'utils/browser'
import auth from 'utils/auth'
import eventBus from 'utils/eventBus'
import cookies from 'utils/cookies'

import { properties } from 'app-config'

import { findDomain } from 'services/subdomain/single/selectors'

import ApprovalsWrapperContainer from 'module/Approvals/ApprovalsWrapperContainer'
import Children from 'module/Children'
import DailyDiary from 'module/DailyDiary'
import Finance from 'module/Finance'
import Management from 'module/Management'
import NurseriesAdd from 'module/Nurseries/NurseriesAdd'
import Occupancy from 'module/Occupancy'
import Enquiries from 'module/Enquiries'
import Register from 'module/Register'
import RoomsWrapper from 'module/Rooms'
import Settings from 'module/Settings'
import Newsletters from 'module/Newsletters'
import Reports from 'module/Reports'
import Learning from 'module/Learning'
import NurseryFiles from 'module/NurseryFiles/NurseryFilesList'
import Injury from 'module/Safeguarding/Injury/InjuryWrapper'
import ParentInbox from 'module/ParentInbox'
import StaffLeave from 'module/Staff/StaffLeave'
import StaffList from 'module/Staff/StaffList'
import StaffRegisterWrapper from 'module/Staff/StaffRegisterWrapper'
import StaffRota from 'module/Staff/StaffRota'
import StaffWorklogList from 'module/Staff/StaffWorklog/StaffWorklogList'
import OrganizationReports from 'module/Organization/Reports'
import OrganizationNurseryRota from 'module/Organization/NurseryRota'
import OrganizationChildren from 'module/Organization/Children'

import { withAppService } from 'services/app'
import { withAbsenceReasonsService } from 'services/legacy/absenceReasons'
import { withDeviceService } from 'services/device'
import { withMembershipsService } from 'services/legacy/memberships'
import { withNurseriesService } from 'services/nurseries'
import { withModalService } from 'services/utils/modal'
import { withShellService } from 'services/shell'
import { withOrganizationService } from 'services/organization'
import { withVersionControlService } from 'services/versionControl'
import { withSubdomainService } from 'services/subdomain'
import { withNurseryContextService } from 'services/nurseryContext'
import { withAuthenticationService } from 'services/authentication'
import { withSnackbarService } from 'services/utils/snackbar'
import { withSecurityService } from 'services/security'
import { withRegisterService } from 'services/legacy/register'

import i18n from 'translations'

import ShellView from './ShellView'
import { getAuthAccessMap, getMenuList } from './ShellHelpers'
import withIdleTimer from './withIdleTimer'
import AuthoriseDeviceContent from './components/AuthoriseDeviceContent'

const BE_BANNER_COOKIE_NAME = 'HIDE_BE_BANNER'
const MAINTENANCE_BANNER_COOKIE_NAME = 'HIDE_MAINTENANCE_BANNER'

const HEADER_STATUS = {
  PINNED: 'pinned',
  UNFIX: 'unfix',
  UNPINNED: 'unpinned',
}

const GROUPS_NURSERY_SETTINGS = {
  read: [
    'nursery.likeAndCommentMembers',
    'nursery.settings',
    'nursery.organization',
    'nursery.newFinance',
    'nurseryIntegrations',
    'nurseryIntegrations.stripe',
    'nurserySettings',
    'nurserySettings.localeDetails',
    'nurserySettings.likeAndComment',
    'nurserySettings.messaging',
    'nurserySettings.security',
    'nurserySecuritySettings',
    'nurserySettings.learning',
    'nurseryLearningSettings',
    'nurseryLikeAndCommentSettings',
    'organization',
    'organization.newFinance',
    'nurseryCodeReferenceGenerationSettings',
    'nurserySettings.referenceCodeGeneration',
  ],
}

const GROUPS_ORGANIZATION_SETTINGS = {
  read: [
    'organization.organizationSettings',
    'organizationSettings',
    'organizationSettings.security',
    'organizationSecuritySettings',
    'organizationSettings.localeDetails',
  ],
}

moment.updateLocale(moment.locale(), {
  relativeTime: {
    M: `%d${i18n.t('global:MomentRelativeShortTime:month')}`,
    MM: `%d${i18n.t('global:MomentRelativeShortTime:month')}`,
    d: `%d${i18n.t('global:MomentRelativeShortTime:day')}`,
    dd: `%d${i18n.t('global:MomentRelativeShortTime:day')}`,
    future: '%s',
    h: `%d${i18n.t('global:MomentRelativeShortTime:hour')}`,
    hh: `%d${i18n.t('global:MomentRelativeShortTime:hour')}`,
    m: `%d${i18n.t('global:MomentRelativeShortTime:minute')}`,
    mm: `%d${i18n.t('global:MomentRelativeShortTime:minute')}`,
    past: '%s',
    s: i18n.t('global:MomentRelativeShortTime:second'),
    ss: i18n.t('global:MomentRelativeShortTime:second'),
    w: `%d${i18n.t('global:MomentRelativeShortTime:week')}`,
    ww: `%d${i18n.t('global:MomentRelativeShortTime:week')}`,
    y: `%d${i18n.t('global:MomentRelativeShortTime:year')}`,
    yy: `%d${i18n.t('global:MomentRelativeShortTime:year')}`,
  },
})

class ShellContainer extends Component {
  constructor(props) {
    super(props)

    window.localStorage.setItem(IS_HEADER_PINNED_LS, '0')
    this.fetchNurserySettings()

    this.state = {
      confirmGDPRModalIsVisible: false,
      isMessagingEnabled: false,
      menuItemsToShow: 0,
      organizationSettingsIsDownloaded: false,
      showInfoBanner: false,
      showMaintenanceBanner: false,
      sidebarIsOpen: false,
    }
  }

  componentDidMount() {
    const { OfflineModeEnabled, dashboardTitle, shellActions } = this.props

    this.addBrowserClassNames()
    this.calculateMenuItemsToShow()

    window.addEventListener('resize', this.calculateMenuItemsToShow)
    window.addEventListener('offline', () => this.handleChangeOfflineMode(true))
    window.addEventListener('online', () => this.handleChangeOfflineMode(false))
    eventBus.on(RELOAD_GLOBAL_NURSERY_SETTINGS_EVENT, this.fetchGlobalNurserySettings)
    eventBus.on(UPDATE_NURSERY_SETTINGS_EVENT, this.updateNurserySettingsEventCallback)
    eventBus.on(UPDATE_ABSENCE_REASON_EVENT, this.fetchAbsenceReasonsEvent)
    eventBus.on(GET_ORGANISATION_SETTINGS_EVENT, this.fetchOrganizationSettingsEventCallback)
    eventBus.on(LOGIN_SUCCESSFUL, this.loginSuccessEventCallback)

    if (OfflineModeEnabled) {
      this.fetchRegister()
      this.fetchAbsenceReason()
    }

    shellActions.setRouteTitle({
      name: 'DASHBOARD',
      title: dashboardTitle,
    })
  }

  componentDidUpdate(prevProps) {
    const {
      OfflineModeEnabled,
      authOrganization,
      authUser,
      isModalVisible,
      isOrganizationContext,
      modalActions,
      modalConsts,
      nurseryOptions,
    } = this.props
    const { confirmGDPRModalIsVisible, organizationSettingsIsDownloaded } = this.state

    if (false === prevProps.OfflineModeEnabled && OfflineModeEnabled) {
      this.fetchRegister()
    }

    if (
      prevProps?.nurseryOptions?.id === undefined
      && nurseryOptions?.id
    ) {
      this.fetchNurserySettings()
    }

    if (
      (isOrganizationContext && !organizationSettingsIsDownloaded && authOrganization?.id)
      || (prevProps?.authOrganization?.id === undefined && authOrganization?.id)
    ) {
      this.fetchOrganizationSettings(authOrganization)
    }

    if (!prevProps.authUser && authUser) {
      this.unauthorisedDeviceNotificationRequest()

      if (checkBrowserIsOutOfTheList()) {
        modalActions.show(modalConsts.TYPES.ALERT, {
          icon: 'warning',
          text: (
            <React.Fragment>
              {i18n.t('module:Shell:notSupportedBrowserMessagePart1')}
              <br />
              <br />
              {i18n.t('module:Shell:notSupportedBrowserMessagePart2')}
            </React.Fragment>
          ),
        })
      }
    }

    if (authUser && !authUser.gdprAcceptedAt && !isModalVisible && !confirmGDPRModalIsVisible) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ confirmGDPRModalIsVisible: true })

      modalActions.show(modalConsts.TYPES.TERMS_UPDATE, {
        onConfirm: this.confirmGDPR,
        onRequestClose: () => {
          this.setState({ confirmGDPRModalIsVisible: false })
        },
      })
    }
  }

  componentWillUnmount() {
    eventBus.remove(RELOAD_GLOBAL_NURSERY_SETTINGS_EVENT, this.fetchGlobalNurserySettings)
    eventBus.remove(UPDATE_NURSERY_SETTINGS_EVENT, this.updateNurserySettingsEventCallback)
    eventBus.remove(GET_ORGANISATION_SETTINGS_EVENT, this.fetchOrganizationSettingsEventCallback)
    eventBus.remove(UPDATE_ABSENCE_REASON_EVENT, this.fetchAbsenceReasonsEvent)
    eventBus.remove(LOGIN_SUCCESSFUL, () => {
      setTimeout(this.fetchNurserySettings)
    })
    window.removeEventListener('resize', this.calculateMenuItemsToShow)
    window.removeEventListener('offline', () => this.handleChangeOfflineMode(true))
    window.removeEventListener('online', () => this.handleChangeOfflineMode(false))
  }

  fetchAbsenceReasonsEvent = () => this.fetchAbsenceReason(true)

  loginSuccessEventCallback = (e) => {
    if (e?.detail?.user?.profile?.roles) {
      setRoleGroupForUserflow(e.detail.user.profile.roles)
    }

    setTimeout(() => {
      this.fetchAbsenceReason()
      this.fetchNurserySettings()
    })
  }

  updateNurserySettingsEventCallback = (e) => this.updateNurserySettings(e.detail)

  fetchRegister = () => {
    const {
      Register: RegisterAccess,
      isNurseryContext,
      nurseryOptions,
      registerActions,
      registerSelectors,
    } = this.props

    if (!nurseryOptions.id || !isNurseryContext || !RegisterAccess) {
      return null
    }

    const criteria = registerSelectors.getCriteria({
      date: moment().format(DEFAULT_DATE_FORMAT),
    })

    return registerActions.listGlobal({
      params: [{ criteria }],
    })
  }

  fetchAbsenceReason = (clearCache) => {
    const { absenceReasonsActions, isNurseryContext, nurseryOptions } = this.props

    if (!nurseryOptions.id || !isNurseryContext) {
      return null
    }

    if (clearCache) {
      const channel = new BroadcastChannel('sw-messages')

      channel.postMessage({ type: 'CLEAR_DROPDOWN_ITEMS_CACHE' })
    }

    return absenceReasonsActions.dropdownList({
      params: {
        criteria: [{
          field: 'archived',
          value: false,
        }],
        page: 1,
      },
      recursively: true,
    })
  }

  updateNurserySettings = (response) => {
    const { hasRoleAccessToMobilePaymentsBanner, nurseryStripeNurseryIntegration, shellActions } = this.props
    const { data } = response
    const { nurserySettings: { currency, locale, messaging } } = data || {}
    const { enabledForParents } = messaging || {}

    const hideBanner = cookies.get(BE_BANNER_COOKIE_NAME)
    const hideMaintenanceBanner = cookies.get(MAINTENANCE_BANNER_COOKIE_NAME)

    shellActions.setSettings({ currency, locale })

    this.setState({
      isMessagingEnabled: !!enabledForParents,
      showInfoBanner: !hideBanner && hasRoleAccessToMobilePaymentsBanner && !nurseryStripeNurseryIntegration,
      showMaintenanceBanner: !hideMaintenanceBanner && properties.showMaintenanceBanner,
    })
  }

  fetchOrganizationSettingsSuccess = (response) => {
    const { shellActions } = this.props
    const { organizationSettings: { currency, locale } } = response || {}

    shellActions.setSettings({ currency, locale })
  }

  fetchOrganizationSettingsEventCallback = (e) => this.fetchOrganizationSettings(e.detail)

  fetchOrganizationSettings = (organization) => {
    const { organizationActions } = this.props

    this.setState({
      organizationSettingsIsDownloaded: true,
    })

    return organizationActions.get(organization.id, {
      onSuccess: this.fetchOrganizationSettingsSuccess,
      onlyData: true,
      params: { groups: GROUPS_ORGANIZATION_SETTINGS },
    })
  }

  fetchGlobalNurserySettings = () => {
    const { nurseryContextActions, nurseryOptions } = this.props

    return nurseryContextActions.getSettings({
      onSuccess: this.updateNurserySettings,
      params: [nurseryOptions.id, {
        groups: GROUPS_NURSERY_SETTINGS,
      }],
    })
  }

  fetchNurserySettings = async () => {
    const { isNurseryContext, nurseriesActions, nurseryOptions } = this.props

    if (!nurseryOptions.id || !isNurseryContext) {
      return null
    }

    /*
     * This code is to remove in the future.
     * Use `nurseryContextActions.getSettings` method to get and store data about nursery in nursery context.
     * Using normal get method you may get overwriting data in store (e.g. on the nursery preview page
     * in the admin panel or on settings). Method from nurseryContextActions should be store information
     * about context nursery. And `get` method should be using for example on nursery preview in admin panel
     * or on get info about another nursery.
     */
    await nurseriesActions.get(nurseryOptions.id, {
      params: { groups: GROUPS_NURSERY_SETTINGS },
    })

    return this.fetchGlobalNurserySettings()
  }

  handleChangeOfflineMode = (isOffline) => {
    const { appActions } = this.props

    if (isOffline) {
      return appActions.setAppIsOffline()
    }

    return appActions.setAppIsOnline()
  }

  addBrowserClassNames = () => {
    if (isIOS()) {
      document.body.classList.add('ios')
    }
  }

  confirmGDPR = () => {
    const { membershipsActions } = this.props

    this.setState({ confirmGDPRModalIsVisible: false })
    membershipsActions.confirmGdpr({
      params: { groups: { read: ['membership.profile'] } },
    })
  }

  handleReload = () => {
    window.location.reload()
  }

  unauthorisedDeviceNotificationRequest = () => {
    const {
      deviceIdentity,
      isAdministrationContext,
      isDeputyManager,
      isNurseryManager,
      isOrganizationContext,
      shell,
      snackbarActions,
    } = this.props
    const shouldSee = (!isAdministrationContext
      && !isOrganizationContext
      && (isNurseryManager || isDeputyManager)
      && !deviceIdentity
      && !shell.common.suppressDeviceWarning)

    if (shouldSee) {
      snackbarActions.show({
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        autoDismiss: false,
        message: (onClose) => (
          <AuthoriseDeviceContent onClose={onClose} />
        ),
        variant: 'tertiary',
      })
    }
  }

  handleBackButton = () => {
    const { backButtonActive, goBack } = this.props // eslint-disable-line

    if (!backButtonActive) {
      return false
    }

    return goBack()
  }

  handleHelpClick = () => {
    const { OfflineModeEnabled, isNurseryContext, isOffline, userflowFlagIsEnabled } = this.props

    if (isNurseryContext && OfflineModeEnabled && isOffline) {
      return null
    }

    if (userflowFlagIsEnabled) {
      userflow.toggleResourceCenter()
    } else {
      const { modalActions, modalConsts } = this.props

      modalActions.show(modalConsts.TYPES.HELP)
    }

    return false
  }

  handleLogout = () => {
    const { authenticationActions, navigate } = this.props

    logEvent(EVENTS.AUTHENTICATION_SIGNED_OUT, { context: 'top bar' })
    authenticationActions.logout()

    navigate('/login?fromLogout')
  }

  handleChangeSidebar = (sidebarIsOpen) => {
    if (sidebarIsOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    this.setState({ sidebarIsOpen })
  }

  handleShowPremiumModal = () => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.PREMIUM_PLAN)
  }

  getTitle() {
    const {
      authNursery,
      authOrganization,
      hasOrganizationAccess,
      isAdministrationContext,
      routes,
      subdomainName,
    } = this.props

    const currentRoute = routes[routes.length - 1]

    if (isAdministrationContext) {
      return ''
    }

    if (currentRoute.enableUnauthorizedMainHeader) {
      return subdomainName
    }

    if (hasOrganizationAccess && authOrganization && authOrganization.name) {
      return authOrganization.name
    }

    if (authNursery) {
      return authNursery.name
    }

    return ''
  }

  getLogo() {
    const {
      authNurseryLogo,
      authOrganization,
      hasOrganizationAccess,
      isNurseryContext,
      routes,
      subdomainLogo,
    } = this.props

    const currentRoute = routes[routes.length - 1]

    if (currentRoute.enableUnauthorizedMainHeader) {
      return subdomainLogo
    }

    if (hasOrganizationAccess && authOrganization && authOrganization.logo) {
      return authOrganization.logo
    }

    if (authNurseryLogo && isNurseryContext) {
      return authNurseryLogo
    }

    return null
  }

  calculateMenuItemsToShow = () => {
    if (580 >= window.innerWidth) {
      return this.setState({
        menuItemsToShow: 0,
      })
    }

    const { layoutWidth } = layout
    const width = window.innerWidth < layoutWidth ? window.innerWidth : layoutWidth
    const menuItemsToShow = parseInt(width / MENU_ITEM_WIDTH, 10) - 1

    return this.setState({
      menuItemsToShow,
    })
  }

  setHeaderStatus = (headerStatus) => {
    if (headerStatus === HEADER_STATUS.PINNED && !document.body.classList.contains('headroom-pinned')) {
      document.body.classList.add('headroom-pinned')
    }

    if (headerStatus !== HEADER_STATUS.PINNED && document.body.classList.contains('headroom-pinned')) {
      document.body.classList.remove('headroom-pinned')
    }

    const isPinned = headerStatus === HEADER_STATUS.PINNED
    window.localStorage.setItem(IS_HEADER_PINNED_LS, (+isPinned).toString())
    eventBus.dispatch(HEADER_PINNED_EVENT)
  }

  handleBeBannerCloseClick = () => {
    cookies.set(BE_BANNER_COOKIE_NAME, 1, {
      domain: findDomain(),
      expires: properties.infoBannerExpirationTime,
    })
  }

  handleMaintenanceBannerCloseClick = () => {
    cookies.set(MAINTENANCE_BANNER_COOKIE_NAME, 1, {
      domain: findDomain(),
      expires: properties.infoBannerExpirationTime,
    })
  }

  render() {
    const {
      OfflineModeEnabled,
      authUser,
      backButtonActive,
      backgroundFilter,
      children,
      dashboardIcon,
      dashboardTitle,
      hasOrganizationAccess,
      hasVersionMismatch,
      isAdministrationContext,
      isBranded,
      isFinanceV3Enabled,
      isNurseryContext,
      isOffline,
      isOrganizationContext,
      isSuperAdmin,
      routes,
      shell,
    } = this.props
    const { isMessagingEnabled, menuItemsToShow, showInfoBanner, showMaintenanceBanner, sidebarIsOpen } = this.state
    const currentRoute = routes[routes.length - 1]
    const title = this.getTitle()
    const logo = this.getLogo()

    const showOrganizationMenu = isOrganizationContext && hasOrganizationAccess
    const showSuperAdminMenu = isSuperAdmin && isAdministrationContext

    const authAccessMap = getAuthAccessMap(this.props)

    const menuItems = getMenuList({
      authAccessMap,
      showOrganizationMenu,
      showSuperAdminMenu,
    })

    return (
      <ShellView
        authUser={authUser}
        backButtonActive={backButtonActive}
        backgroundFilter={backgroundFilter}
        currentRoute={currentRoute}
        dashboardIcon={dashboardIcon}
        dashboardTitle={dashboardTitle}
        hasVersionMismatch={hasVersionMismatch}
        isBranded={isBranded}
        isFinanceV3Enabled={isFinanceV3Enabled}
        isMessagingEnabled={isMessagingEnabled}
        isNurseryContext={isNurseryContext}
        isOffline={isNurseryContext && OfflineModeEnabled && isOffline}
        logo={logo}
        menuItems={menuItems}
        menuItemsToShow={menuItemsToShow}
        minimal={shell?.common?.minimal}
        showInfoBanner={showInfoBanner}
        showMaintenanceBanner={showMaintenanceBanner}
        sidebarIsOpen={sidebarIsOpen}
        title={title}
        onBackButton={this.handleBackButton}
        onBeBannerCloseClick={this.handleBeBannerCloseClick}
        onChangeSidebar={this.handleChangeSidebar}
        onHelpClick={this.handleHelpClick}
        onLogout={this.handleLogout}
        onMaintenanceBannerCloseClick={this.handleMaintenanceBannerCloseClick}
        onPin={() => this.setHeaderStatus(HEADER_STATUS.PINNED)}
        onReload={this.handleReload}
        onShowPremiumModal={this.handleShowPremiumModal}
        onUnfix={() => this.setHeaderStatus(HEADER_STATUS.UNFIX)}
        onUnpin={() => this.setHeaderStatus(HEADER_STATUS.UNPINNED)}
      >
        {children}
      </ShellView>
    )
  }
}

const mapState = (state, {
  appSelectors,
  deviceSelectors,
  modalSelectors,
  nurseriesSelectors,
  nurseryContextSelectors,
  organizationSelectors,
  params,
  securitySelectors,
  subdomainSelectors,
  versionControlSelectors,
}) => ({
  ApprovalList: auth.SELECTORS.getComponentIsAuthorised(state, ApprovalsWrapperContainer),
  Children: auth.SELECTORS.getComponentIsAuthorised(state, Children),
  CreateNursery: auth.SELECTORS.getComponentIsAuthorised(state, NurseriesAdd),
  DailyDiary: auth.SELECTORS.getComponentIsAuthorised(state, DailyDiary),
  Enquiries: auth.SELECTORS.getComponentIsAuthorised(state, Enquiries),
  Finance: auth.SELECTORS.getComponentIsAuthorised(state, Finance),
  Injury: auth.SELECTORS.getComponentIsAuthorised(state, Injury),
  Learning: auth.SELECTORS.getComponentIsAuthorised(state, Learning),
  Management: auth.SELECTORS.getComponentIsAuthorised(state, Management),
  Newsletters: auth.SELECTORS.getComponentIsAuthorised(state, Newsletters),
  NurseryFiles: auth.SELECTORS.getComponentIsAuthorised(state, NurseryFiles),
  Occupancy: auth.SELECTORS.getComponentIsAuthorised(state, Occupancy),
  OfflineModeEnabled: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.OFFLINE_MODE],
  }),
  OrganizationChildren: auth.SELECTORS.getComponentIsAuthorised(state, OrganizationChildren),
  OrganizationNurseryRota: auth.SELECTORS.getComponentIsAuthorised(state, OrganizationNurseryRota),
  OrganizationReports: auth.SELECTORS.getComponentIsAuthorised(state, OrganizationReports),
  ParentInbox: auth.SELECTORS.getComponentIsAuthorised(state, ParentInbox),
  PremiumFinance: auth.SELECTORS.getComponentIsAuthorisedButFlagIsMissing(state, Finance),
  PremiumInjury: auth.SELECTORS.getComponentIsAuthorisedButFlagIsMissing(state, Injury),
  PremiumOccupancy: auth.SELECTORS.getComponentIsAuthorisedButFlagIsMissing(state, Occupancy),
  PremiumReports: auth.SELECTORS.getComponentIsAuthorisedButFlagIsMissing(state, Reports),
  Register: auth.SELECTORS.getComponentIsAuthorised(state, Register),
  Reports: auth.SELECTORS.getComponentIsAuthorised(state, Reports),
  RoomsWrapper: auth.SELECTORS.getComponentIsAuthorised(state, RoomsWrapper),
  Settings: auth.SELECTORS.getComponentIsAuthorised(state, Settings),
  StaffLeave: auth.SELECTORS.getComponentIsAuthorised(state, StaffLeave),
  StaffList: auth.SELECTORS.getComponentIsAuthorised(state, StaffList),
  StaffRegister: auth.SELECTORS.getComponentIsAuthorised(state, StaffRegisterWrapper),
  StaffRota: auth.SELECTORS.getComponentIsAuthorised(state, StaffRota),
  StaffWorklog: auth.SELECTORS.getComponentIsAuthorised(state, StaffWorklogList),
  authNursery: securitySelectors.getAuthNursery(state),
  authNurseryLogo: nurseriesSelectors.getAuthNurseryLogo(state),
  authOrganization: securitySelectors.getAuthOrganization(state),
  authUser: securitySelectors.getAuthUser(state),
  backButtonActive: 0 < state?.router?.common?.navCount,
  backgroundFilter: modalSelectors.getBackgroundFilter(state),
  dashboardIcon: subdomainSelectors.getDashboardIcon(state),
  dashboardTitle: subdomainSelectors.getDashboardTitle(state),
  deviceIdentity: deviceSelectors.getDeviceIdentity(state),
  hasOrganizationAccess: securitySelectors.hasOrganizationAccess(state),
  hasOrganizationFullAccess: securitySelectors.hasOrganizationFullAccess(state),
  hasParentAppAccess: securitySelectors.hasParentAppAccessSelector(state),
  hasRoleAccessToMobilePaymentsBanner: auth.SELECTORS.getIsAuthorised(state, {
    roles: [
      ROLES.ORGANIZATION_DIRECTOR,
      ROLES.ORGANIZATION_NATIONAL_ADMIN,
      ROLES.ORGANIZATION_FINANCE_ADMIN,
      ROLES.ORGANIZATION_LINE_MANAGER,
      ROLES.NURSERY_MANAGER,
      ROLES.NURSERY_ADMIN,
      ROLES.SUPER_ADMIN,
    ],
  }),
  hasVersionMismatch: versionControlSelectors.isVersionMismatch(state),
  isAdministrationContext: securitySelectors.isAdministrationContext(state),
  isBranded: subdomainSelectors.subdomainIsBranded(state),
  isDeputyManager: securitySelectors.isDeputyManager(state),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
  isModalVisible: !!state.modal.modalType,
  isNurseryContext: securitySelectors.isNurseryContext(state),
  isNurseryManager: securitySelectors.isNurseryManager(state),
  isOffline: appSelectors.getAppIsOffline(state),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
  isSuperAdmin: securitySelectors.isSuperAdmin(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  nurseryStripeNurseryIntegration: nurseriesSelectors.getNurseryStripeNurseryIntegration(state),
  organisation: organizationSelectors.getOrganizationData(state),
  organizationSessionExpireTime: organizationSelectors.getSecuritySessionExpireTimeSettings(state),
  organizationSessionExpireTimeEnabled: organizationSelectors.getSecuritySessionExpireTimeEnabledSettings(state),
  sessionExpireTime: nurseryContextSelectors.getSessionExpireTime(state),
  sessionExpireTimeEnabled: nurseryContextSelectors.getSessionExpireTimeEnabled(state),
  shell: state.shell,
  subdomainLogo: subdomainSelectors.getSubdomainLogo(state),
  subdomainName: subdomainSelectors.getSubdomainName(state),
  userflowFlagIsEnabled: auth.SELECTORS.getIsAuthorised(state, { flags: [FEATURE_FLAGS.USERFLOW] }),
})

const mapDispatch = {
  goBack,
}

const enhance = compose(
  withAuthenticationService,
  withAppService,
  withAbsenceReasonsService,
  withDeviceService,
  withMembershipsService,
  withModalService,
  withNurseryContextService,
  withNurseriesService,
  withOrganizationService,
  withRegisterService,
  withRouter,
  withSecurityService,
  withSubdomainService,
  withShellService,
  withSnackbarService,
  withVersionControlService,
  connect(mapState, mapDispatch),
  withIdleTimer,
)

export default enhance(ShellContainer)
