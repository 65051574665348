import _ from 'lodash'

import React from 'react'

import { Table } from 'components'

import i18n from 'translations'

const InvoicesTable = ({
  invoices,
  noDataText,
  onTableSortChange,
  sortField,
  sortOrder,
}) => (
  <div>
    <Table
      columns={[
        {
          field: 'number',
          sortable: true,
          title: 'Invoice number',
        },
        {
          align: 'left',
          field: 'child',
          sortKey: 'child.firstName',
          sortable: true,
          title: _.upperFirst(i18n.t('global:child')),
        },
        {
          align: 'left',
          field: 'primaryBillPayer',
          title: _.upperFirst(i18n.t('global:PrimaryBillPayer')),
        },
        {
          field: 'issueDate',
          sortable: true,
          title: 'Invoice date',
        },
        {
          field: 'processedDate',
          sortable: true,
          title: 'Created date',
        },
        {
          align: 'left',
          field: 'name',
          sortable: true,
          title: 'Invoice name',
        },
        {
          field: 'total',
          sortable: true,
          title: 'Amount',
        },
        {
          field: 'status',
          sortable: true,
          title: 'Status',
        },
        {
          field: 'totalOutstanding',
          sortable: true,
          title: 'Unpaid Amount',
        },
        {
          field: 'rowToButton',
        },
      ]}
      data={invoices}
      minWidth={1040}
      noDataText={noDataText}
      sortField={sortField}
      sortOrder={sortOrder}
      onSortChange={onTableSortChange}
    />
  </div>
)

export default InvoicesTable
