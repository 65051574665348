import { ROLES } from 'constants/security'
import moment from 'moment'
import _ from 'lodash'

import React, { useEffect, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'

import { ChildBooking } from 'services/booking/childBooking/constants'
import { ModalType } from 'modals'
import { PriceChange } from 'services/product/constants'
import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { RESPONSE } from 'constants/http'
import { UPDATE_CHILD_SESSION_SETUP } from 'module/Children/Child/ChildContainer'

import eventBus from 'utils/eventBus'
import { generateRoute } from 'utils/routing'
import auth from 'utils/auth'

import { withRouter, withRouterProps } from 'services/router'
import { withChildService, withChildServiceProps } from 'services/legacy/child'
import { withAppService, withAppServiceProps } from 'services/app'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withChildBookingService, withChildBookingServiceProps } from 'services/booking/childBooking'

import i18n from 'translations'

import RegularBookingsPreviewView from './RegularBookingsPreviewView'

type RegularBookingsPreviewContainerProps = withAppServiceProps
  & withChildServiceProps
  & withChildBookingServiceProps
  & withRouterProps
  & withModalServiceProps

const mapState = (state, {
  appSelectors,
  childBookingPreviewState,
  childBookingSelectors,
  childBookingSingleState,
  childFundingSummaryCalculatorState,
  childSelectors,
}) => ({
  child: childSelectors.getChildSelector(state),
  childBooking: childBookingSelectors.getChildBookingSingleDataState(state),
  childBookingPreview: childBookingSelectors.getChildBookingPreviewDataState(state),
  errorMessages: appSelectors.getErrorMessages(childBookingSingleState),
  fundingSummary: childSelectors.getFundingSummaryCalculator(state),
  hasAccessToEditRegularBooking: auth.SELECTORS.getIsAuthorised(state, {
    roles: [
      ROLES.ORGANIZATION_DIRECTOR,
      ROLES.ORGANIZATION_NATIONAL_ADMIN,
      ROLES.ORGANIZATION_FINANCE_ADMIN,
      ROLES.ORGANIZATION_LINE_MANAGER,
      ROLES.DEPUTY_MANAGER,
      ROLES.NURSERY_MANAGER,
      ROLES.NURSERY_ADMIN,
      ROLES.SUPER_ADMIN,
    ],
  }),
  isFetching: appSelectors.getIsFetching(childBookingSingleState),
  isFetchingFundingSummary: appSelectors.getIsFetching(childFundingSummaryCalculatorState),
  isFetchingPreview: appSelectors.getIsFetching(childBookingPreviewState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const CHILD_BOOKING_GROUPS = {
  read: [
    'childBookingPriceChange',
    'childBookingPriceChange.priceGroup',
    'childBooking.attendancePeriod',
    'childBooking.childBookingPriceChanges',
    'period',
    'productPriceGroup',
    'childBooking.settings',
    'childBookingSettings',
  ],
}

const CHILD_PREVIEW_BOOKING_GROUPS = {
  read: [
    'childBookingPreview',
    'childBookingPreview.childBooking',
    'childBookingPreview.product',
  ],
}

const SUMMARY_GROUPS = {
  read: [
    'childFundingSummary',
  ],
}

const RegularBookingsPreviewContainer: React.FC<RegularBookingsPreviewContainerProps & PropsFromRedux> = ({
  child,
  childActions,
  childBooking,
  childBookingActions,
  childBookingPreview,
  childBookingSelectors,
  errorMessages,
  fundingSummary,
  hasAccessToEditRegularBooking,
  isFetching,
  isFetchingFundingSummary,
  isFetchingPreview,
  modalActions,
  navigate,
  params,
}) => {
  const { bookingId } = params

  const [selectedPriceChange, setSelectedPriceChange] = useState<PriceChange>(null)

  useEffect(() => {
    if (selectedPriceChange) {
      childBookingActions.preview({
        params: [bookingId, moment(selectedPriceChange.startDate).format(DEFAULT_DATE_FORMAT), {
          groups: CHILD_PREVIEW_BOOKING_GROUPS,
        }],
      })
    }
  }, [selectedPriceChange])

  const handleFetchItemSuccess = (response: ChildBooking) => {
    const { childBookingPriceChanges } = response

    const firstPriceChange = _.first(childBookingPriceChanges) as PriceChange
    const currentPriceChange = _.find(childBookingPriceChanges, (item: PriceChange) => (
      moment(item.startDate).isSameOrBefore(moment()) && moment(item.endDate).isSameOrAfter(moment())
    ))

    setSelectedPriceChange(currentPriceChange || firstPriceChange)

    const body = {
      childBooking: childBookingSelectors.getBody(response as any, child),
    }

    childActions.getFundingSummaryCalculator({
      body,
      params: [child.id, {
        groups: SUMMARY_GROUPS,
      }],
    })
  }

  const fetchItem = () => {
    childBookingActions.get({
      onFailed: (data) => {
        if (RESPONSE.HTTP_404_NOT_FOUND === data.code) {
          navigate(generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS', {
            childId: params.childId,
          }))
        }
      },
      onSuccess: (response) => handleFetchItemSuccess(response?.data),
      params: [bookingId, {
        groups: CHILD_BOOKING_GROUPS,
      }],
    })
  }

  const handleArchiveRegularBookingSuccess = () => {
    eventBus.dispatch(UPDATE_CHILD_SESSION_SETUP)

    navigate(generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS', { childId: child.id }))
  }

  const handleArchiveRegularBookingConfirmed = () => childBookingActions.remove({
    onSuccess: handleArchiveRegularBookingSuccess,
    params: [params.bookingId],
  })

  const handleArchiveRegularBooking = () => {
    modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Delete'),
      icon: 'trash',
      onConfirm: handleArchiveRegularBookingConfirmed,
      text: i18n.t('module:Finance:RegularBookings:List:confirmRemove'),
    })
  }

  const handleClickPriceChange = (priceChange: PriceChange) => {
    setSelectedPriceChange(priceChange)

    setTimeout(() => {
      window.scrollTo(0, 0)
    })
  }

  useEffect(() => () => {
    childBookingActions.clearList()
  }, [])

  useEffect(fetchItem, [bookingId])

  return (
    <RegularBookingsPreviewView
      child={child}
      childBooking={childBooking}
      childBookingPreview={childBookingPreview}
      errorMessages={errorMessages}
      fundingSummary={fundingSummary}
      hasAccessToEditRegularBooking={hasAccessToEditRegularBooking}
      isFetching={isFetching}
      isFetchingPreview={isFetchingPreview}
      isFetchingSummary={isFetchingFundingSummary}
      selectedPriceChange={selectedPriceChange}
      onArchiveRegularBooking={handleArchiveRegularBooking}
      onClickPriceChange={handleClickPriceChange}
    />
  )
}

const enhance = compose(
  withAppService,
  withChildService,
  withChildBookingService,
  withModalService,
  withRouter,
  connector,
)

export default enhance(RegularBookingsPreviewContainer)
