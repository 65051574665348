import moment from 'moment'

import React from 'react'

import { ModalType } from 'modals'
import { TYPES } from 'components/DropdownMenu/constants'

import { Page, Section, Spinner } from 'components'

import i18n from 'translations'

import ManagementFundingAddForm, { FundingAddFormValues } from './components/FundingAddForm/FundingAddForm'

interface ManagementFundingAddV3ViewProps {
  errorMessages?: string[]
  formValues?: FundingAddFormValues
  initialValues?: FundingAddFormValues
  isArchived: boolean
  isEdit: boolean
  isFetching: boolean
  isInsideModal?: boolean
  isSubmitting: boolean
  onArchiveClick: (isArchived: boolean) => any
  onCancelClick: () => void
  onChangeDeficitCharged: (value: boolean) => void
  onChangeStartDate: (value: moment.Moment, index: number) => void
  onConfirmChangeRedistributionRule: (fieldName: string) => () => void
  onPreviewClick: (modalType: ModalType.PREVIEW_INVOICE_DEDUCTION | ModalType.PREVIEW_LINE_ITEM) => void
  onRemovePriceChangeRow: (index: number) => void
  onSubmit: (values: FundingAddFormValues) => void
}

interface GetTitleProps {
  isEdit: boolean
  isInsideModal: boolean
}

const getTitle = ({ isEdit, isInsideModal }: GetTitleProps): string => {
  if (isInsideModal) {
    return null
  }

  return isEdit
    ? i18n.t('module:Management:Finance:Funding:Edit:title')
    : i18n.t('module:Management:Finance:Funding:Add:title')
}

const ManagementFundingAddV3View: React.FC<ManagementFundingAddV3ViewProps> = ({
  errorMessages,
  isArchived,
  isEdit,
  isFetching,
  isInsideModal,
  onArchiveClick,
  ...rest
}) => {
  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    return (
      <ManagementFundingAddForm {...rest} isEdit={isEdit} />
    )
  }

  const WrapperComponent = isInsideModal ? Section : Page.Section

  return (
    <WrapperComponent
      actions={isEdit && !isInsideModal && (
        <Section.Actions
          options={[{
            onClick: () => onArchiveClick(isArchived),
            type: isArchived ? TYPES.UNARCHIVE : TYPES.ARCHIVE,
          }]}
        />
      )}
      errorMessages={errorMessages}
      title={getTitle({ isEdit, isInsideModal })}
    >
      {renderContent()}
    </WrapperComponent>
  )
}

export default ManagementFundingAddV3View
