import Skeleton from '@mui/material/Skeleton'
import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import {
  AllocationDefault,
  ChildBooking,
  ChildBookingPreview,
  ChildBookingPreviewSummaryActual,
  ChildBookingPreviewSummaryAnnualised,
  ChildBookingPreviewSummaryPackage,
  FEE_CALCULATION_OPTIONS,
  FeeCalculationType,
  WeekDayPreview,
  WeekDayPreviewItem,
} from 'services/booking/childBooking/constants'
import { ChildProductTypes } from 'services/booking/childProducts/constants'
import { FundingSummary as FundingSummaryModel } from 'services/child/fundingSummaryCalculator/constants'
import { Child } from 'services/child/models'
import { PriceChange } from 'services/product/constants'
import { NEUTRAL_COLOURS } from 'constants/colors'
import { DAYS_OF_WEEK_LIST, DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT } from 'constants/date'

import { isSameDay, millisecondsToHoursAndMinutesString } from 'utils/date'
import { XOR } from 'utils/typescript'
import { generateRoute } from 'utils/routing'

import {
  Accordion,
  Box,
  Button,
  CircleIcon,
  Currency,
  DropdownMenu,
  Grid,
  Page,
  Space,
  Spinner,
  Tabs,
  Tooltip,
  Typography,
} from 'components'

import i18n from 'translations'

import AllocationBar from '../components/AllocationBar'
import FundingSummary from '../components/FundingSummary'
import {
  StyledButtonWrapper,
  StyledItem,
  StyledTabInsideWrapper,
  StyledTabWrapper,
  StyledWrapperAccordionHeader,
} from './RegularBookingsPreviewStyled'

interface RegularBookingsPreviewViewProps {
  child: Child
  childBooking: ChildBooking
  childBookingPreview: ChildBookingPreview
  errorMessages: string[]
  fundingSummary: FundingSummaryModel[]
  hasAccessToEditRegularBooking: boolean
  isFetching: boolean
  isFetchingPreview: boolean
  isFetchingSummary: boolean
  onArchiveRegularBooking: () => void
  onClickPriceChange: (priceChange: PriceChange) => void
  selectedPriceChange: PriceChange
}

const RegularBookingsPreviewView: React.FC<RegularBookingsPreviewViewProps> = ({
  child,
  childBooking,
  childBookingPreview,
  errorMessages,
  fundingSummary,
  hasAccessToEditRegularBooking,
  isFetching,
  isFetchingPreview,
  isFetchingSummary,
  onArchiveRegularBooking,
  onClickPriceChange,
  selectedPriceChange,
}) => {
  const {
    alternates,
    attendancePeriod,
    childBookingPriceChanges,
    endDate,
    id,
    settings,
    startDate,
  } = childBooking || {}
  const { summaryPreview, weekDayPreviews } = childBookingPreview || {}
  const {
    actualPriceForTypicalWeek,
    difference,
    estimatedWeeklyTotal,
    monthlyAnnualisedTotal,
    monthlyPackage,
    totalForATypicalWeek,
    weeklyFixedPrice,
  } = summaryPreview as XOR<
    ChildBookingPreviewSummaryActual,
    XOR<ChildBookingPreviewSummaryPackage, ChildBookingPreviewSummaryAnnualised>
    > || {}
  const { calculationMonths, calculationWeeks, feeCalculation, packagePrice } = settings || {}
  const feeCalculationOption = _.find(FEE_CALCULATION_OPTIONS, ({ value }) => value === feeCalculation)

  const sortedPriceChanges = _.reverse(_.sortBy(childBookingPriceChanges, (priceChange: ChildBooking) => (
    moment(priceChange.startDate).format('x')
  )))

  const renderPriceChanges = () => _.map(sortedPriceChanges, (priceChange: ChildBooking) => {
    const showLabel = feeCalculation === FeeCalculationType.ANNUALISED && !(
      isSameDay(moment(priceChange.startDate), moment(priceChange.startDate).startOf('month'))
      && isSameDay(moment(priceChange.endDate), moment(priceChange.endDate).endOf('month'))
      && moment(priceChange.startDate).format('MM') === moment(priceChange.endDate).format('MM')
    )
    let isAnnualised = false
    const betweenMonths = []

    if (moment(priceChange.startDate) < moment(priceChange.endDate)) {
      const date = moment(priceChange.startDate).startOf('month')

      while (date < moment(priceChange.endDate).endOf('month')) {
        betweenMonths.push(date.format('YYYY-MM'))
        date.add(1, 'month')
      }
    }

    if (
      FeeCalculationType.ANNUALISED === feeCalculation
      && moment(priceChange.startDate).format('YYYYMM') !== moment(priceChange.endDate).format('YYYYMM')
      && _.find(betweenMonths, (month) => (
        moment(priceChange.startDate).isSameOrBefore(moment(month, 'YYYY-MM').startOf('month'))
        && moment(priceChange.endDate).isSameOrAfter(moment(month, 'YYYY-MM').endOf('month'))
      ))
    ) {
      isAnnualised = true
    }

    return (
      <StyledItem
        $active={priceChange.id === selectedPriceChange?.id}
        key={priceChange.id}
        onClick={() => onClickPriceChange(priceChange)}
      >
        {moment(priceChange.startDate).format(DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT)}
        {priceChange.endDate && (
          <React.Fragment>
            {' - '}
            {moment(priceChange.endDate).format(DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT)}
          </React.Fragment>
        )}
        {showLabel && (
          <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
            {'('}
            {_.lowerFirst(i18n.t('module:Children:Child:BookingPattern:RegularBookings:Preview:calendarMonthly'))}
            {isAnnualised && `, ${i18n.t('services:Product:NurseryDiscounts:feeCalculation:annualised')}`}
            {')'}
          </Typography>
        )}
      </StyledItem>
    )
  })

  const renderWeekDayItemContentTimes = (weekDayItem) => {
    if (ChildProductTypes.REGULAR_SUBTRACT_FUNDING === weekDayItem.type) {
      return null
    }

    return (
      <React.Fragment>
        {' ('}
        {moment(weekDayItem.startTime).utc().format('HH:mm')}
        {' - '}
        {moment(weekDayItem.endTime).utc().format('HH:mm')}
        {')'}
      </React.Fragment>
    )
  }

  const renderWeekDayItemContent = (weekDayItem: WeekDayPreviewItem) => {
    if (_.includes(
      [
        ChildProductTypes.REGULAR_AMOUNT_DISCOUNT,
        ChildProductTypes.REGULAR_LINK_DISCOUNT,
        ChildProductTypes.REGULAR_PERCENTAGE_DISCOUNT,
      ],
      weekDayItem.type)
    ) {
      return '-'
    }

    if (ChildProductTypes.REGULAR_ITEM === weekDayItem.type) {
      return `${weekDayItem.quantity}x`
    }

    if (_.includes(
      [
        ChildProductTypes.REGULAR_SESSION,
        ChildProductTypes.REGULAR_ALLOCATED_FUNDING,
        ChildProductTypes.REGULAR_SUBTRACT_FUNDING,
      ],
      weekDayItem.type)
    ) {
      const summary = (weekDayItem.endTime - weekDayItem.startTime) || 0

      return (
        <React.Fragment>
          {millisecondsToHoursAndMinutesString(summary)}
          {renderWeekDayItemContentTimes(weekDayItem)}
        </React.Fragment>
      )
    }

    return ''
  }

  const renderTab = (alternate: number) => {
    const childProductsFilteredByAlternate = _.filter(weekDayPreviews, (item: WeekDayPreview) => (
      alternate + 1 === item.numberOfWeek
    ))

    return (
      <Tabs.Item
        title={i18n.t('module:Children:Child:BookingPattern:RegularBookings:Preview:week', { index: alternate + 1 })}
      >
        <Accordion>
          {_.map(childProductsFilteredByAlternate, (item) => {
            const { dayOfWeek, items, price } = item

            const index = _.findIndex(DAYS_OF_WEEK_LIST, (i) => i === dayOfWeek)

            const sessions = _.map(
              _.filter(items, ({ type }) => (
                type === ChildProductTypes.REGULAR_SESSION
              )),
              (session) => ({
                _extra: {
                  id: session.id,
                },
                dayOfWeek,
                product: {
                  id: session.id,
                  name: session.name,
                },
                times: [{
                  endTime: session.endTime,
                  startTime: session.startTime,
                }],
              }) as AllocationDefault,
            )

            const allocations = _.map(
              _.filter(items, ({ type }) => (
                type === ChildProductTypes.REGULAR_ALLOCATED_FUNDING
              )),
              (funding) => ({
                _extra: {
                  id: funding.id,
                },
                dayOfWeek,
                product: {
                  id: funding.id,
                  name: funding.name,
                },
                times: [{
                  endTime: funding.endTime,
                  startTime: funding.startTime,
                }],
              }) as AllocationDefault,
            )

            return (
              <Accordion.Item
                header={(
                  <StyledWrapperAccordionHeader>
                    <AllocationBar
                      allocations={allocations}
                      leftLabel={i18n.t(`global:shortDayNames:${index + 1}`)}
                      sessions={sessions}
                      summary={_.isNumber(price) ? (
                        <Typography fontSize={16}>
                          <Currency value={price / 100} />
                        </Typography>
                      ) : null}
                      isPreview
                    />
                  </StyledWrapperAccordionHeader>
                )}
                key={dayOfWeek}
              >
                {_.map(items, (weekDayItem) => (
                  <Grid key={weekDayItem.id}>
                    <Grid.Item width={{ desktop: '200px' }}>
                      <Typography>
                        {weekDayItem.name}
                      </Typography>
                    </Grid.Item>
                    <Grid.Item flex={{ desktop: 1 }}>
                      <Typography>
                        {renderWeekDayItemContent(weekDayItem)}
                      </Typography>
                    </Grid.Item>
                    <Grid.Item textAlign={{ desktop: 'right' }} width={{ desktop: '100px' }}>
                      {_.isNumber(weekDayItem.price) ? (
                        <Currency value={weekDayItem.price / 100} />
                      ) : null}
                    </Grid.Item>
                  </Grid>
                ))}
              </Accordion.Item>
            )
          })}
        </Accordion>
      </Tabs.Item>
    )
  }

  const renderSummary = () => {
    if (isFetchingPreview) {
      return (
        <Box marginBottom={0} withPadding>
          <Skeleton sx={{ fontSize: '32px' }} variant="text" />
          <Space space="8px" />
          <Skeleton sx={{ fontSize: '22px' }} variant="text" />
          <Skeleton sx={{ fontSize: '22px' }} variant="text" />
        </Box>
      )
    }

    if (feeCalculation === FeeCalculationType.WEEKLY_PACKAGE || feeCalculation === FeeCalculationType.MONTHLY_PACKAGE) {
      return (
        <Box marginBottom={0} withPadding>
          <Grid>
            <Grid.Item flex={{ desktop: 1 }}>
              <Typography fontSize={18} bold>
                {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Preview:weeklyPackage')}
                {' '}
                <Typography fontSize={18} inline>
                  {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Preview:invoiceCalculation')}
                </Typography>
              </Typography>
            </Grid.Item>
            <Grid.Item />
          </Grid>
          <Grid>
            <Grid.Item flex={{ desktop: 1 }}>
              {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Preview:actualPriceForTypicalWeek')}
            </Grid.Item>
            <Grid.Item flex={{ desktop: '1fr' }} textAlign={{ desktop: 'right' }}>
              <Currency value={actualPriceForTypicalWeek / 100} />
            </Grid.Item>
          </Grid>
          <Grid>
            <Grid.Item flex={{ desktop: 1 }}>
              {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Preview:weeklyFixedPrice')}
            </Grid.Item>
            <Grid.Item flex={{ desktop: '1fr' }} textAlign={{ desktop: 'right' }}>
              <Currency value={weeklyFixedPrice ? (weeklyFixedPrice / 100) : 0} />
            </Grid.Item>
          </Grid>
          <Grid>
            <Grid.Item flex={{ desktop: 1 }}>
              {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Preview:difference')}
            </Grid.Item>
            <Grid.Item flex={{ desktop: '1fr' }} textAlign={{ desktop: 'right' }}>
              <Currency value={difference ? (difference / 100) : 0} />
            </Grid.Item>
          </Grid>
          <Space space="15px" />
          <Grid>
            <Grid.Item flex={{ desktop: 1 }}>
              <Typography fontSize={18} bold>
                {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Preview:monthlyPackage')}
              </Typography>
              <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} margin="5px 0 0" bold>
                <Currency value={packagePrice / 100} />
                {` x ${calculationWeeks} ${i18n.t('global:week')} / ${calculationMonths} ${i18n.t('global:month')}`}
              </Typography>
            </Grid.Item>
            <Grid.Item>
              <Typography fontSize={18}>
                <Currency value={monthlyPackage / 100} />
              </Typography>
            </Grid.Item>
          </Grid>
        </Box>
      )
    }

    if (feeCalculation === FeeCalculationType.ANNUALISED) {
      return (
        <Box marginBottom={0} withPadding>
          <Grid>
            <Grid.Item flex={{ desktop: 1 }}>
              <Typography fontSize={18} bold>
                {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Preview:annualised')}
                {' '}
                <Typography fontSize={18} inline>
                  {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Preview:invoiceCalculation')}
                </Typography>
              </Typography>
            </Grid.Item>
            <Grid.Item />
          </Grid>
          <Grid>
            <Grid.Item flex={{ desktop: 1 }}>
              {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Preview:totalForATypicalWeek')}
            </Grid.Item>
            <Grid.Item flex={{ desktop: '1fr' }} textAlign={{ desktop: 'right' }}>
              <Currency value={totalForATypicalWeek / 100} />
            </Grid.Item>
          </Grid>
          <Space space="15px" />
          <Grid>
            <Grid.Item flex={{ desktop: 1 }}>
              <Typography fontSize={18} bold>
                {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Preview:monthlyAnnualisedTotal')}
              </Typography>
              <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} margin="5px 0 0" bold>
                <Currency value={totalForATypicalWeek / 100} />
                {` x ${calculationWeeks} ${i18n.t('global:week')} / ${calculationMonths} ${i18n.t('global:month')}`}
              </Typography>
            </Grid.Item>
            <Grid.Item>
              <Typography fontSize={18}>
                <Currency value={monthlyAnnualisedTotal / 100} />
              </Typography>
            </Grid.Item>
          </Grid>
        </Box>
      )
    }

    return (
      <Box marginBottom={0} withPadding>
        <Grid>
          <Grid.Item flex={{ desktop: 1 }}>
            <Typography fontSize={18} bold>
              {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Preview:calendarMonthly')}
              {' '}
              <Typography fontSize={18} inline>
                {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Preview:invoiceCalculation')}
              </Typography>
            </Typography>
          </Grid.Item>
          <Grid.Item />
        </Grid>
        <Space space="5px" />
        <Grid>
          <Grid.Item flex={{ desktop: 1 }}>
            <Typography fontSize={18} bold>
              {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Preview:estimatedWeeklyTotal')}
            </Typography>
          </Grid.Item>
          <Grid.Item>
            <Typography fontSize={18}>
              <Currency value={estimatedWeeklyTotal / 100 || 0} />
            </Typography>
          </Grid.Item>
        </Grid>
      </Box>
    )
  }

  const renderContent = () => {
    const priceGroup = _.find(childBookingPriceChanges, (item) => item.id === selectedPriceChange?.id)

    return (
      <div>
        <Grid>
          <Grid.Item alignItems={{ desktop: 'center' }} flex={{ desktop: 1 }}>
            <Typography fontSize={20} bold>
              {moment(startDate).format(DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT)}
              {endDate ? (
                <React.Fragment>
                  {' - '}
                  {moment(endDate).format(DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT)}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {' - '}
                  {moment(child.leavingDate).format(DISPLAY_DATE_SHORT_MONTH_NAME_FORMAT)}
                  <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14} bold inline>
                    {' ('}
                    {i18n.t('module:Finance:RegularBookings:List:untilChildLeaves')}
                    {')'}
                  </Typography>
                </React.Fragment>
              )}
            </Typography>
          </Grid.Item>
          {hasAccessToEditRegularBooking && (
            <Grid.Item>
              <DropdownMenu small>
                <DropdownMenu.Item
                  to={generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS.EDIT', {
                    bookingId: id,
                    childId: child.id,
                  })}
                  type="edit"
                />
                <DropdownMenu.Item
                  type="delete"
                  onClick={onArchiveRegularBooking}
                />
              </DropdownMenu>
            </Grid.Item>
          )}
        </Grid>
        <Space space="5px" />
        {attendancePeriod && (
          <Typography color={NEUTRAL_COLOURS.GRAY} margin="0 0 10px">
            {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Preview:attendanceSchedule')}
            {': '}
            <Typography fontSize={15} bold inline>
              {attendancePeriod.name}
            </Typography>
          </Typography>
        )}
        <Space space="15px" />
        <Grid spacing={10}>
          {priceGroup && (
            <Grid.Item flex={{ desktop: 1 }}>
              <Box withPadding>
                <Typography fontSize={15} margin="0 0 5px 0">
                  {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Preview:priceGroup')}
                </Typography>
                <Typography bold>
                  {priceGroup.priceGroup.startAge}
                  {'-'}
                  {priceGroup.priceGroup.endAge}
                  {' '}
                  {i18n.t('global:months')}
                </Typography>
              </Box>
            </Grid.Item>
          )}
          {feeCalculationOption && (
            <Grid.Item flex={{ desktop: 1 }}>
              <Box withPadding>
                <Typography fontSize={15} margin="0 0 5px 0">
                  {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Preview:invoicing')}
                </Typography>
                <Typography bold>
                  {feeCalculationOption.label}
                </Typography>
              </Box>
            </Grid.Item>
          )}
        </Grid>
        <Space space="10px" />
        <StyledTabWrapper>
          <StyledTabInsideWrapper>
            <Tabs fullWidth={false} variant="standard">
              {_.times(alternates, renderTab)}
            </Tabs>
            <Space margin="-20px 0 0" />
          </StyledTabInsideWrapper>
        </StyledTabWrapper>
        <Space margin="10px 0 0" />
        {renderSummary()}
        <Space space="25px" />
        {!isFetchingSummary && fundingSummary?.length ? (
          <Typography fontSize={20} padding="20px 0" bold>
            {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Preview:fundingSummary')}
          </Typography>
        ) : null}
        <FundingSummary
          data={fundingSummary}
          isFetching={isFetchingSummary}
        />
      </div>
    )
  }

  const renderBody = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    return (
      <Grid spacing={20} wrap={{ desktop: 'nowrap', mobile: 'wrap' }} flex>
        <Grid.Item width={{ desktop: '300px', mobile: '100%' }}>
          <Box
            title={(
              <Grid flex>
                <Grid.Item alignItems={{ desktop: 'center' }} flex={{ desktop: 1 }}>
                  {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Preview:priceChanges')}
                </Grid.Item>
                <Grid.Item>
                  <Tooltip
                    title={i18n.t('module:Children:Child:BookingPattern:RegularBookings:Preview:priceChangesTooltip')}
                  >
                    <CircleIcon
                      cursor="pointer"
                      icon="info"
                      iconSize={16}
                      size={24}
                      secondary
                    />
                  </Tooltip>
                </Grid.Item>
              </Grid>
            )}
            withPadding
          >
            {renderPriceChanges()}
          </Box>
        </Grid.Item>
        <Grid.Item flex={{ desktop: 1 }} width={{ mobile: '100%' }}>
          {renderContent()}
        </Grid.Item>
      </Grid>
    )
  }

  return (
    <Page.Section
      actions={!isFetching && childBooking && hasAccessToEditRegularBooking && (
        <StyledButtonWrapper>
          <Button
            hierarchy="secondary"
            label={i18n.t('module:Children:Child:BookingPattern:RegularBookings:Preview:scheduleBookingPatternChange')}
            to={`${generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS.ADD', {
              childId: child?.id,
            })}?childBooking=${childBooking.id}`}
          />
        </StyledButtonWrapper>
      )}
      errorMessages={errorMessages}
      title={i18n.t('module:Finance:RegularBookings:title')}
    >
      {renderBody()}
    </Page.Section>
  )
}

export default RegularBookingsPreviewView

