import moment from 'moment'
import { get } from 'idb-keyval'
import { Sync } from '@blossomdev/sync'

import { applyMiddleware, combineReducers, compose, createStore as createReduxStore } from 'redux'
import { routerMiddleware, routerReducer } from 'react-router-redux'
import { reducer as reduxFormReducer } from 'redux-form'
import { createEpicMiddleware } from 'redux-observable'
import baseHistory from 'react-router/lib/browserHistory'
import thunk from 'redux-thunk'
import inject from 'redux-inject'

import { STORE_KEY_NAME_IN_INDEX_DB } from 'constants/global'
import { REMOVE_IDENTITY } from 'services/authentication/common/constants'
import { Subdomain } from 'services/subdomain/models'
import { Profile } from 'services/authentication/models'

import * as reducers from 'core/reducers'
import ErrorHandlingMiddleware from 'core/reduxMiddleware/ErrorHandlingMiddleware'
import SyncWithIndexDBMiddleware from 'core/reduxMiddleware/SyncWithIndexDBMiddleware'
import epics from 'core/epics'
import { createServices } from 'core/services'
import DeviceIdentityService from 'core/services/DeviceIdentityService'
import CookieIdentityService from 'core/services/CookieIdentityService'

import deviceIdentityActions from 'services/device/actions'
import authenticationActions from 'services/authentication/actions'
import * as authenticationSelectors from 'services/authentication/selectors'

import { properties } from 'app-config'
import * as Sentry from '@sentry/react'

export const initializeMomentJS = () => moment.locale('en-gb')

export const iosPolyfill = () => {
  document.documentElement.style.setProperty('--app-height', `${window.innerHeight * 0.01}px`)
}

export const setHotReload = (store) => {
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('core/reducers', () => {
      const nextRootReducer = require('core/reducers')

      store.replaceReducer(nextRootReducer)
    })
  }
}

export const setDeviceIdentity = (store) => {
  const existingDeviceIdentity = DeviceIdentityService.getIdentity()
  const existingDeviceRealIdentity = DeviceIdentityService.getRealIdentity()

  if (null !== existingDeviceIdentity) {
    store.dispatch(deviceIdentityActions.changeDeviceIdentity(existingDeviceIdentity))
  }
  if (null !== existingDeviceRealIdentity) {
    store.dispatch(deviceIdentityActions.changeDeviceRealIdentity(existingDeviceRealIdentity))
  }
}

export const setCookieIdentity = (store) => {
  if (CookieIdentityService.isIdentitySet(properties.identityKeyLegacy)) {
    window.location.reload()
    store.dispatch(authenticationActions.logout({ key: properties.identityKeyLegacy }))
  }
}

export const createStore = async () => {
  let oldStore = ''

  if (!navigator.onLine) {
    oldStore = await get(STORE_KEY_NAME_IN_INDEX_DB)
  }

  const reducer = combineReducers({
    form: reduxFormReducer,
    routing: routerReducer,
    ...reducers,
  })

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const services = createServices((...args) => store.dispatch(
    // @ts-ignore
    ...args,
  ))

  const epicMiddleware = createEpicMiddleware({
    dependencies: services,
  })

  const middleware = [
    inject(services),
    thunk,
    routerMiddleware(baseHistory),
    epicMiddleware,
    ErrorHandlingMiddleware,
    SyncWithIndexDBMiddleware,
  ]

  const sentryReduxEnhancer = Sentry.createReduxEnhancer()

  const enhancer = compose(
    applyMiddleware(...middleware),
    sentryReduxEnhancer,
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
  )

  const rootReducer = (state, action) => {
    if (!navigator.onLine && !state) {
      try {
        return reducer(JSON.parse(oldStore), action)
      } catch (e) {}
    }

    if (action.type === REMOVE_IDENTITY) {
      return reducer({
        device: state.device,
        shell: state.shell,
        snackbar: state.snackbar,
        subdomain: state.subdomain,
      } as any, action)
    }

    return reducer(state, action)
  }

  const store = createReduxStore(rootReducer, enhancer)

  epicMiddleware.run(epics)

  return { services, store }
}

export const configureSync = (subdomain: Subdomain) => {
  if (!subdomain?.syncEndpoint && subdomain?.id !== properties.defaultSubdomain) {
    // eslint-disable-next-line no-console
    console.error('SyncEndpoint 404.')
  }

  Sync.configure({ graphqlEndpoint: subdomain?.syncEndpoint })
}

export const loadMeData = async ({ services, store, token }): Promise<Profile> => {
  const { authenticationApiClient } = services

  return new Promise((resolve, reject) => {
    if (navigator.onLine) {
      authenticationApiClient.authMe(token).then(
        ({ data }) => {
          resolve(data)
        },
        reject,
      )
    } else {
      const state = store.getState()
      const profile = authenticationSelectors.getMeProfile(state)

      resolve(profile)
    }
  })
}
