import _ from 'lodash'

import React from 'react'

import colors from 'constants/colors'

import invoiceConstants from 'services/legacy/invoices/constants'

import { Currency, DropdownMenu, Table } from 'components'

import i18n from 'translations'

import { StyledDescriptionContainer } from './InvoiceTableStyled'

const { INVOICE_PREVIEW_ITEM_TYPE, INVOICE_PREVIEW_UNIT_TYPES } = invoiceConstants

const LABEL_STYLE = {
  paddingLeft: 50,
  paddingRight: 10,
}

const InvoiceTableItem = ({
  isEdit,
  isGrouped,
  item,
  onEditClick,
  onRemoveClick,
  preview,
  rowIndex,
}) => {
  const {
    description,
    editable,
    invoiceItem,
    isSubItem,
    label,
    name,
    quantity,
    total,
    type,
    unit,
    unitPrice,
    visible,
  } = item

  const { id, total: invoiceItemTotal, type: invoiceItemType, uid } = invoiceItem || {}
  const rowStyle = { background: colors.tableOddEven[rowIndex % 2] }

  if (!visible) {
    return null
  }

  const renderEditButton = () => (
    <DropdownMenu small>
      <DropdownMenu.Item
        type="edit"
        onClick={() => onEditClick({
          itemIndex: isEdit ? (id || uid) : uid,
          readOnly: !editable,
        })}
      />
      {editable && (
        <DropdownMenu.Item
          type="remove"
          onClick={() => onRemoveClick({
            itemIndex: isEdit ? (id || uid) : uid,
            total: invoiceItemTotal,
            type: invoiceItemType,
          })}
        />
      )}
    </DropdownMenu>
  )

  const renderName = () => (
    <React.Fragment>
      {name}
      {description && (
        <StyledDescriptionContainer>
          {description}
        </StyledDescriptionContainer>
      )}
    </React.Fragment>
  )

  const renderTotal = () => {
    if (!total && 0 !== total) {
      return null
    }

    if (INVOICE_PREVIEW_UNIT_TYPES.HOURS === unit) {
      return i18n.t('components:Finance:InvoiceTable:fundingHours', { quantity: total })
    }

    return <Currency value={total} />
  }

  if (isGrouped) {
    return (
      <Table.Tr inactive={INVOICE_PREVIEW_ITEM_TYPE.ITEM === type} style={rowStyle}>
        <Table.Td align="left" style={isSubItem && LABEL_STYLE}>
          <span>{_.upperFirst(label)}</span>
        </Table.Td>
        <Table.Td align="left">
          <span>{renderName()}</span>
        </Table.Td>
        <Table.Td align="right">
          <span>{renderTotal()}</span>
        </Table.Td>
        {!preview && (
          <Table.Td width="80px">
            {!isSubItem && INVOICE_PREVIEW_ITEM_TYPE.ITEM === type ? renderEditButton() : null}
          </Table.Td>
        )}
      </Table.Tr>
    )
  }

  return (
    <Table.Tr style={rowStyle}>
      <Table.Td align="left" style={isSubItem && LABEL_STYLE}>
        {_.upperFirst(label)}
      </Table.Td>
      <Table.Td align="left">
        {renderName()}
      </Table.Td>
      <Table.Td>
        {unitPrice && <Currency value={unitPrice} />}
      </Table.Td>
      <Table.Td>
        {quantity}
      </Table.Td>
      <Table.Td align="right">
        {renderTotal()}
      </Table.Td>
      {!preview && (
        <Table.Td>
          {!isSubItem ? renderEditButton() : null}
        </Table.Td>
      )}
    </Table.Tr>
  )
}

export default InvoiceTableItem
