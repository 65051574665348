import _ from 'lodash'
import moment from 'moment'

import React, { useEffect, useMemo } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'
import { change, getFormValues, stopSubmit } from 'redux-form'

import { ModalType } from 'modals'
import {
  FUNDING_INVOICE_DEDUCTION_TYPES_V3,
  FUNDING_LINE_ITEM_TYPES_V3,
  FundingAllocationValues,
  FundingPeriodValues,
  FundingRedistributionRulesValues,
  FundingTypeValues,
} from 'services/product/nurseryFundingV3/constants'

import { scrollToErrorFields } from 'utils/dom'
import { generateRoute } from 'utils/routing'
import { getBackendErrors } from 'utils/backendErrors'

import { withAppService, withAppServiceProps } from 'services/app'
import { withNurseryFundingV3Service, withNurseryFundingV3ServiceProps } from 'services/product/nurseryFundingV3'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withRouter, withRouterProps } from 'services/router'

import i18n from 'translations'

import { FUNDING_LINE_ITEM_OPTIONS } from 'services/nurseryFunding/constants'
import { FUNDING_ADD_FORM, FundingAddFormValues } from './components/FundingAddForm/FundingAddForm'
import ManagementFundingAddV3View from './ManagementFundingAddV3View'

const mapDispatch = {
  changeField: (field, value) => change(FUNDING_ADD_FORM, field, value),
  injectValidation: (data) => stopSubmit(FUNDING_ADD_FORM, data),
}

const mapState = (state, {
  appSelectors,
  nurseryFundingV3Selectors,
  nurseryFundingV3SingleState,
}) => ({
  errorMessages: appSelectors.getErrorMessages(nurseryFundingV3SingleState),
  formValues: getFormValues(FUNDING_ADD_FORM)(state) as FundingAddFormValues,
  isFetching: appSelectors.getIsFetching(nurseryFundingV3SingleState),
  isSubmitting: appSelectors.getIsSubmitting(nurseryFundingV3SingleState),
  nurseryFunding: nurseryFundingV3Selectors.getNurseryFundingV3SingleDataState(state),
})

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type ManagementFundingAddV3ContainerProps = PropsFromRedux
  & withAppServiceProps
  & withRouterProps
  & withModalServiceProps
  & withNurseryFundingV3ServiceProps
  & {
    isInsideModal?: boolean
    onModalCancelClick?: () => void
    onModalCreateSuccess?: () => void
  }

const NURSERY_FUNDING_GROUPS = {
  read: [
    'productPrice',
    'productPriceChange',
    'productPriceChange.prices',
    'nurseryFundingProduct.priceChanges',
    'nurseryFundingProduct.settings',
    'nurseryFundingProduct.nursery',
    'nurseryFundingProductSettings',
    'nurseryFundingSettings',
  ],
}

const ManagementFundingAddV3Container: React.FC<ManagementFundingAddV3ContainerProps> = ({
  changeField,
  errorMessages,
  formValues,
  injectValidation,
  isFetching,
  isInsideModal,
  isSubmitting,
  location,
  modalActions,
  nurseryFunding,
  nurseryFundingV3Actions,
  nurseryFundingV3Selectors,
  onModalCancelClick,
  onModalCreateSuccess,
  params,
  router,
}) => {
  const { pathname } = location
  const isEdit = pathname === generateRoute('MANAGEMENT.FINANCE_SETTINGS.FUNDING.EDIT', {
    nurseryFundingId: params.nurseryFundingId,
  })

  useEffect(() => {
    if (isEdit) {
      nurseryFundingV3Actions.get({
        params: [+params.nurseryFundingId, {
          groups: NURSERY_FUNDING_GROUPS,
        }],
      })
    }
  }, [isEdit])

  const handleSubmitFailed = (response) => {
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    scrollToErrorFields()

    return injectValidation(errors)
  }

  const handleSubmitSuccess = () => {
    if (onModalCreateSuccess) {
      return onModalCreateSuccess()
    }

    return router.replace(generateRoute('MANAGEMENT.FINANCE_SETTINGS.FUNDING'))
  }

  const handleChangeStartDate = (value, index) => {
    changeField(`priceChanges[${index - 1}].endDate`, moment(value).subtract(1, 'days'))
  }

  const handleRemovePriceChangeRow = (i) => {
    const newPriceChanges = [...formValues?.priceChanges || []]
    newPriceChanges.splice(i, 1)

    if (1 === newPriceChanges?.length) {
      changeField('priceChanges[0].endDate', undefined)
    }

    changeField(`priceChanges[${newPriceChanges.length - 1}].endDate`, null)

    _.each(_.reverse(newPriceChanges), (priceChange, index) => {
      if (priceChange?.startDate) {
        changeField(
          `priceChanges[${newPriceChanges.length - 2 - index}].endDate`,
          moment(priceChange.startDate).subtract(1, 'days'),
        )
      }
    })
  }

  const handleSubmit = (values: FundingAddFormValues) => {
    const body = nurseryFundingV3Selectors.getBody(values)

    if (isEdit) {
      nurseryFundingV3Actions.update({
        body,
        onFailed: handleSubmitFailed,
        onSuccess: handleSubmitSuccess,
        params: [+params.nurseryFundingId],
      })

      return
    }

    nurseryFundingV3Actions.create({
      body,
      onFailed: handleSubmitFailed,
      onSuccess: handleSubmitSuccess,
    })
  }

  const handleArchiveFunding = (archived) => {
    nurseryFundingV3Actions.update({
      body: { archived: !archived },
      onFailed: handleSubmitFailed,
      onSuccess: handleSubmitSuccess,
      params: [+params.nurseryFundingId],
    })
  }

  const handleArchiveClick = (archived) => {
    const label = archived ? _.lowerCase(i18n.t('global:Unarchive')) : _.lowerCase(i18n.t('global:Archive'))

    modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
      confirmButtonLabel: label,
      icon: archived ? 'unarchive' : 'archive',
      onConfirm: () => handleArchiveFunding(archived),
      text: (
        <div>
          {i18n.t('module:Management:Finance:Funding:Add:archiveText1', { label })}
          <br />
          {i18n.t('module:Management:Finance:Funding:Add:archiveText2', { label })}
        </div>
      ),
    })
  }

  const handleCancelClick = () => {
    if (onModalCancelClick) {
      return onModalCancelClick()
    }

    return router.push(generateRoute('MANAGEMENT.FINANCE_SETTINGS.FUNDING'))
  }

  const handleConfirmChangeRedistributionRule = (fieldName) => () => {
    if (!isEdit) {
      return false
    }

    const oldValue = formValues.settings[fieldName]

    return modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
      onCancel: () => {
        changeField(`settings.${fieldName}`, oldValue)
      },
      text: i18n.t('module:Management:Finance:Funding:Add:changeRedistributionRule'),
    })
  }

  const handlePreviewClick = (type: ModalType.PREVIEW_INVOICE_DEDUCTION | ModalType.PREVIEW_LINE_ITEM) => {
    modalActions.show<any>(type, {
      fundingLineItemOptions: FUNDING_LINE_ITEM_OPTIONS,
      isDeductedFromInvoice: true,
    })
  }

  const handleChangeDeficitCharged = (value) => {
    if (!value) {
      changeField('settings.deficitLineItemName', undefined)
    }
  }

  const initialValues = useMemo(() => {
    if (isEdit) {
      return nurseryFundingV3Selectors.getInitialValues(nurseryFunding)
    }

    return {
      fundingAllocation: FundingAllocationValues.ALLOCATE_TO_SESSIONS,
      fundingType: FundingTypeValues.REGULAR,
      priceChanges: [{
        prices: [{ price: 0 }],
      }],
      settings: {
        bookingPlanEndsMidWeek: FundingRedistributionRulesValues.OTHER_DAYS,
        closureDaysSessionChargeable: FundingRedistributionRulesValues.APPLY,
        closureDaysSessionNotChargeable: FundingRedistributionRulesValues.OTHER_DAYS,
        fundingPeriod: FundingPeriodValues.TERM_TIME,
        hoursPerWeek: 0,
        invoiceLineItemDeduction: FUNDING_INVOICE_DEDUCTION_TYPES_V3.INVOICE_TOTAL,
        invoiceLineItemDisplay: FUNDING_LINE_ITEM_TYPES_V3.SHOW_FUNDED_HOURS,
        maxHours: 0,
        maxMinutes: 0,
        minutesPerWeek: 0,
        stretchedWeeks: 0,
        termTimeHolidays: FundingRedistributionRulesValues.NOT_APPLY,
        total: 0,
      },
    }
  }, [isEdit, nurseryFunding])

  const isArchived = useMemo<boolean>(() => nurseryFunding?.archived, [nurseryFunding])

  return (
    <ManagementFundingAddV3View
      errorMessages={errorMessages}
      formValues={formValues}
      initialValues={initialValues}
      isArchived={isArchived}
      isEdit={isEdit}
      isFetching={isFetching && isEdit}
      isInsideModal={isInsideModal}
      isSubmitting={isSubmitting}
      onArchiveClick={handleArchiveClick}
      onCancelClick={handleCancelClick}
      onChangeDeficitCharged={handleChangeDeficitCharged}
      onChangeStartDate={handleChangeStartDate}
      onConfirmChangeRedistributionRule={handleConfirmChangeRedistributionRule}
      onPreviewClick={handlePreviewClick}
      onRemovePriceChangeRow={handleRemovePriceChangeRow}
      onSubmit={handleSubmit}
    />
  )
}

const enhance = compose(
  withAppService,
  withNurseryFundingV3Service,
  withRouter,
  withModalService,
  connector,
)

export default enhance(ManagementFundingAddV3Container)
